import { ActionType } from "@/classes/PerformanceTracker";
import { RecordCategory } from "@/classes/PerformanceTracker";
import tracker from "@/utils/tracker";
import { PushNotifications } from "@capacitor/push-notifications";
import { Device, DeviceInfo } from "@capacitor/device";
import storage from "./storage";
import { UserService } from "@/services/user";
import { useFabriqStore } from "@/store/fabriq";
import { useTeamsStore } from "@/store/teams";
import { useTicketsStore } from "@/store/tickets";
import { ticketPageAnimation } from "@/utils/animations";
import { useNotificationsStore } from "@/store/notifications";
import { Notification, NotificationType, AuthType } from "@/types";
import { App } from "@capacitor/app";
import { Badge } from "@capawesome/capacitor-badge";
import loader from "./loader";
import { useClientStore } from "@/store/client";
import { useConfigStore } from "@/store/config";
import { localLogger } from "./localLogger";

function getStore(): ReturnType<typeof useFabriqStore>;
function getStore<T extends "config">(
  name?: T
): ReturnType<typeof useConfigStore>;
function getStore<T extends "client">(
  name?: T
): ReturnType<typeof useClientStore>;
function getStore<T extends "teams">(
  name?: T
): ReturnType<typeof useTeamsStore>;
function getStore<T extends "tickets">(
  name?: T
): ReturnType<typeof useTicketsStore>;
function getStore<T extends "notifications">(
  name?: T
): ReturnType<typeof useNotificationsStore>;
function getStore<
  T extends "config" | "client" | "teams" | "tickets" | "notifications"
>(name?: T) {
  switch (name) {
    case "config":
      return useConfigStore();
    case "client":
      return useClientStore();
    case "teams":
      return useTeamsStore();
    case "tickets":
      return useTicketsStore();
    case "notifications":
      return useNotificationsStore();
    default:
      return useFabriqStore();
  }
}

export const isTicketNotification = (type: any) => {
  return String(type || "").startsWith("ticket_");
};

export const isTicketCommentNotification = (type: any) => {
  return (
    String(type || "").startsWith("ticket_comment_") ||
    String(type || "").startsWith("ticket_reaction_") ||
    String(type || "").startsWith("ticket_reply_") ||
    String(type || "").startsWith("ticket_mention_") ||
    String(type || "").startsWith("task_mention_")
  );
};

const gotoNotifications = async (router: any) => {
  router.navigate("/notifications", "forward", "push", ticketPageAnimation, {
    duration: 0,
  });
};

async function removeDeliveredNotifications() {
  const fabriqStore = getStore();
  const removePushNotificationsOnAppFocus =
    fabriqStore.user?.config?.removePushNotificationsOnAppFocus;
  if (!removePushNotificationsOnAppFocus) return;
  try {
    const result = await PushNotifications.getDeliveredNotifications();
    if (result?.notifications?.length) {
      await localLogger.logPush({
        event: "getDeliveredNotifications",
        data: result.notifications,
      });
    }
  } catch (e) {
    await localLogger.log(`removeDeliveredNotifications error: ${e}`);
  }
  await PushNotifications.removeAllDeliveredNotifications();
}

export const addListeners = async (router: any) => {
  App.addListener("appStateChange", async ({ isActive }) => {
    const fabriqStore = getStore();
    const configStore = getStore("config");
    const ticketsStore = getStore("tickets");
    if (!isActive) {
      ticketsStore.setOpenComments(false);
      ticketsStore.setFromPush(false);
      configStore.saveUserConfig();
      return;
    }
    const notificationsStore = getStore("notifications");
    if (!fabriqStore.connected) return;
    await notificationsStore.getCount();
    Badge.set({ count: notificationsStore.count });
    await removeDeliveredNotifications();
  });

  await PushNotifications.addListener("registration", async (deviceId: any) => {
    await localLogger.log(`PUSH Registration: ${deviceId.value}`);
    const fabriqStore = getStore();
    const teamsStore = getStore("teams");
    const oldToken = await storage.get("push-token");

    const device: DeviceInfo = await Device.getInfo();
    const user = fabriqStore.user?.future_user_id;
    const clientId = fabriqStore.user?.future_organization_id;
    const teams = teamsStore.collection.map((t: any) => t.id);

    if (oldToken && oldToken !== deviceId.value) {
      await localLogger.log(`PUSH Registration: delete old token ${oldToken}`);
      await UserService.removePushNotificationToken(oldToken);
    }

    await localLogger.log(
      `PUSH Registration: setPushNotificationToken ${deviceId.value}`
    );
    await UserService.setPushNotificationToken(
      deviceId.value,
      device,
      user,
      teams,
      clientId,
      fabriqStore.token?.accessToken,
      fabriqStore.token?.type === AuthType.Future
    );
    await storage.set("push-token", deviceId.value);
    await localLogger.log(`PUSH Registration ok: ${deviceId.value}`);
  });

  await PushNotifications.addListener("registrationError", (err) => {
    const fabriqStore = getStore();
    fabriqStore.reportSentry(new Error(err.error));
    console.error("Registration error: ", err.error);
    localLogger.log(`PUSH Registration error: ${err.error}`);
  });

  await PushNotifications.addListener(
    "pushNotificationReceived",
    async (notification) => {
      await localLogger.logPush({
        event: "pushNotificationReceived",
        data: notification.data,
      });
      if (
        notification?.data?.count !== undefined &&
        notification?.data?.count !== null &&
        !isNaN(notification?.data?.count)
      ) {
        Badge.set({ count: notification?.data?.count });
      }
      if (isTicketNotification(notification.data?.notification_type)) {
        const ticketsStore = getStore("tickets");
        const id = +notification?.data?.model_id;
        await ticketsStore.load(id);
      }
      await removeDeliveredNotifications();
    }
  );

  await PushNotifications.addListener(
    "pushNotificationActionPerformed",
    async (action) => {
      await localLogger.logPush({
        event: "pushNotificationActionPerformed",
        data: action.notification.data,
      });
      const ticketsStore = getStore("tickets");
      if (isTicketNotification(action?.notification.data?.notification_type)) {
        await loader.show();
        const id = +action?.notification.data?.model_id;
        const ticket = await ticketsStore.load(id);
        if (ticket) {
          if (
            isTicketCommentNotification(
              action?.notification.data?.notification_type
            )
          ) {
            ticketsStore.setOpenComments(true);
          }
          ticketsStore.setFromPush(action.notification.data);
        }
      }
      tracker.tunnel(
        "notifications",
        "notifications page",
        RecordCategory.Notifications,
        ActionType.Process,
        "push"
      );
      tracker.begin(
        "push",
        "Push to notifications",
        RecordCategory.General,
        ActionType.Process
      );
      gotoNotifications(router);
      await removeDeliveredNotifications();
    }
  );
};

export const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();
  let badgePermStatus = await Badge.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }
  if (badgePermStatus.display === "prompt") {
    badgePermStatus = await Badge.requestPermissions();
  }

  await localLogger.log(
    `PUSH Registration: checkPermissions: ${permStatus.receive}`
  );
  try {
    if (permStatus.receive === "granted") {
      await PushNotifications.register();
    }
  } catch (e) {
    await localLogger.log(`PUSH Registration error: ${e}`);
  }
  await removeDeliveredNotifications();
  await localLogger.logPush({ permStatus, badgePermStatus });
};

export const getRelevantNotifications = (
  notifications: any,
  setBadge = false
) => {
  const teamsStore = getStore("teams");
  const clientStore = getStore("client");
  const notifs: Array<any> = [];
  notifications.forEach((n: Notification) => {
    if (!n.team_ids?.length) return;
    if (!NotificationType.includes(n.type)) return;
    const teamId = n.team_ids[0];
    const team = teamsStore.collection.find((t: any) => t.id === teamId);
    if (!team) return;
    const actorUser = clientStore.users.find((u: any) => u.id === n.actor);
    if (!actorUser) return;
    notifs.push({ ...n, team, actorUser });
  });
  if (setBadge) {
    const count = notifs.reduce((acc: number, n: Notification) => {
      if (!n.is_read) return acc + 1;
      return acc;
    }, 0);
    Badge.set({ count });
  }
  return notifs;
};

export const log = async (message: any) => {
  try {
    console.log("LOGGING response", message);
    await localLogger.log(`Notification LOGGER: ${message}`);
  } catch (e) {
    console.error("CANT LOG ERROR", e);
  }
};
