<script lang="ts" setup>
import { computed, ref, nextTick } from "vue";
import {
  IonList,
  IonModal,
  IonItem,
  IonLabel,
  onIonViewDidEnter,
  RefresherEventDetail,
} from "@ionic/vue";
import RoutePage from "@/components/ui/RoutePage.vue";
import { useIndicatorsStore } from "@/store/indicators";
import { useFabriqStore } from "@/store/fabriq";
import { useConfigStore } from "@/store/config";
import { useI18n } from "@/composables/useI18n";
import TeamIndicatorPickerModal from "@/components/modals/TeamIndicatorPickerModal.vue";
import IndicatorPreview from "@/components/business/indicators/IndicatorPreview.vue";

import { Indicator } from "@/types";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";

const fabriqStore = useFabriqStore();
const configStore = useConfigStore();
const indicatorsStore = useIndicatorsStore();

const tunnel = (action: string) => {
  tracker.tunnel(
    "indicators",
    "indicators page",
    RecordCategory.Kpis,
    ActionType.Process,
    action
  );
};

const loadAllData = async (refresher?: RefresherEventDetail) => {
  const userId = fabriqStore.user?.id;
  if (!userId) return;
  if (!favorites.value.length) return refresher?.complete();
  if (favorites.value.length === 1) {
    await indicatorsStore.load(favorites.value[0]);
  } else {
    await indicatorsStore.all({ indicator_ids: favorites.value });
  }
  if (refresher) {
    tunnel("reload all");
  }
  refresher?.complete();
};

const favorites = computed(() => configStore.config?.favoriteKpis || []);

const indicators = computed(() => {
  const userId = fabriqStore.user?.id;
  if (!userId) return;
  const indicators = indicatorsStore.collection.filter((i: Indicator) => {
    if (!i.id) return false;
    return favorites.value.includes(Number(i.id));
  });
  indicators.sort((a: Indicator, b: Indicator) => a.name.localeCompare(b.name));
  return indicators;
});

const addModal = ref(false);

const closeAddModal = () => {
  addModal.value = false;
};

const addIndicator = async (indicator: Indicator) => {
  if (!indicator.id) return;
  closeAddModal();
  nextTick(async () => {
    tunnel(`add ${indicator?.indicator_type}`);
    await configStore.addFavoriteKpi(Number(indicator.id));
    indicatorsStore.load(indicator.id);
  });
};

const removeIndicator = (id: number) => {
  tunnel("remove");
  configStore.removeFavoriteKpi(id);
};

onIonViewDidEnter(async () => {
  loadAllData(null);
});

const { t } = useI18n();
</script>

<template>
  <route-page
    :title="t('indicators.title')"
    @refresh="loadAllData"
    fabIcon="plus"
    :beta="true"
    @fab="addModal = true"
  >
    <ion-list class="ion-margin-top">
      <indicator-preview
        v-for="indicator of indicators"
        :key="indicator.id"
        :indicator="indicator"
        @remove="removeIndicator(indicator.id)"
      />
      <ion-item v-if="!indicators.length" lines="none">
        <ion-label>
          <div class="routines-empty">
            <div class="icon">📈</div>
            <div>
              {{ t("indicators.empty.title") }}
            </div>
            <div class="hint indicator-hint">
              <span>{{ t("indicators.empty.hintStart") }}</span>
              <font-icon
                name="add"
                material
                size="1"
                color="white"
                class="mini-plus"
              />
              <span>{{ t("indicators.empty.hintEnd") }}</span>
            </div>
          </div>
        </ion-label>
      </ion-item>
    </ion-list>
    <ion-modal
      :is-open="addModal"
      mode="ios"
      :handle="false"
      :breakpoints="[0, 1]"
      :initialBreakpoint="1"
      @didDismiss="addModal = false"
    >
      <team-indicator-picker-modal
        @done="addIndicator"
        @cancel="closeAddModal"
      />
    </ion-modal>
  </route-page>
</template>

<style scoped>
.mini-plus {
  background-color: var(--ion-color-secondary);
  border-radius: 3rem;
}
.indicator-hint {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
}
</style>
