import { fromUnixTime } from "date-fns";
import { AuthType, Token } from "@/types";
import { log } from "@/utils/notifications";
import { createFutureAuthClient } from "@/futureAuth/authClient";
import config from "@/config/index";
import type { Client as FutureAuthClient } from "@fabriq/auth-client";
import { buildAuthTokenRepo } from "@/futureAuth/storageRepositories";
import axios from "axios";
import { localLogger } from "@/utils/localLogger";

const autoCheckEmail: Record<string, string> = {
  "@albea-group.com": "https://albea.fabriq.tech",
  "@albea.fabriq.tech": "https://albea.fabriq.tech",
  "@albea-fabriq.com": "https://albea.fabriq.tech",

  "@matis-aero.com": "https://safran.fabriq.tech",
  "@safran.com": "https://safran.fabriq.tech",
  "@safran.fabriq.tech": "https://safran.fabriq.tech",
  "@safrangroup.com": "https://safran.fabriq.tech",
  "@airfoils-as.com": "https://airfoilsas.fabriq.tech",
  "@airfoilsas.fabriq.tech": "https://airfoilsas.fabriq.tech",
  "@safran-china.fabriq.tech": "https://safran-china.fabriq.tech",
  "@tarmacaerosave.aero": "https://tarmacaerosave.fabriq.tech",

  "@lonza.com": "https://lonza.fabriq.tech",
  "@lonza.fabriq.tech": "https://lonza.fabriq.tech",

  "@mars.com": "https://royalcanin.fabriq.tech",
  "@mars.fabriq.tech": "https://royalcanin.fabriq.tech",
  "@royalcanin.com": "https://royalcanin.fabriq.tech",
  "@marspetnutrition.fabriq.tech": "https://marspetnutrition.fabriq.tech",
  "@marspetnutrition.com": "https://marspetnutrition.fabriq.tech",
};

function extractParamFromUrl(
  url: string,
  param: string,
  reportSentry: (error: Error, data?: Record<string, any>) => void | null
) {
  try {
    const urlParams = new URL(url).searchParams;
    return urlParams.get(param) || undefined;
  } catch (e) {
    console.error(e);
    if (!reportSentry) {
      return;
    }
    if (e instanceof Error) {
      reportSentry(e, { url, param });
    }
  }
}

interface UserAttribute {
  Name: string;
  Value: string;
}
interface AuthUser {
  Attributes: Array<UserAttribute>;
  Enabled: boolean;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  UserStatus: string;
  Username: string;
}
interface CheckIfEmailExistsData {
  type: AuthType;
  user?: AuthUser;
  futureIdpStatus?: "external" | "internal";
  providerName?: string;
  futureSubdomain?: string;
}

export { CheckIfEmailExistsData };

export class FabriqAuth {
  #apiUrl: string;
  #reportSentry: ((error: Error, data?: Record<string, any>) => void) | null =
    null;

  #subdomainUrl: string | null = null;
  #futureAuthClient: FutureAuthClient | null = null;

  #email: string | null = null;
  #type: string | null = null;
  #password: string | null = null;

  constructor(apiUrl: string) {
    this.#apiUrl = apiUrl;

    const subdomain = localStorage.getItem("subdomainUrl");
    if (subdomain) {
      this.applySubDomainUrl(subdomain);
    }
  }

  setReportSentry(
    reportSentry: (error: Error, data?: Record<string, any>) => void
  ) {
    this.#reportSentry = reportSentry;
  }

  applySubDomainUrl(subdomainUrl: string) {
    this.#subdomainUrl = subdomainUrl;
    localStorage.setItem("subdomainUrl", subdomainUrl);
    this.#futureAuthClient = createFutureAuthClient(this.#subdomainUrl);
  }

  #getFutureAuthTokens() {
    const tokenRepository = buildAuthTokenRepo();
    return tokenRepository.getAuthenticationTokens();
  }

  async checkCurrentSession() {
    if (this.#futureAuthClient && this.#subdomainUrl) {
      const user = await this.#futureAuthClient.getLoggedInUser();
      const token = await this.#getFutureAuthTokens();
      await localLogger.log(`FabriqAuth checkCurrentSession already connected`);
      return { token, user, future: true };
    } else {
      await localLogger.log(
        `FabriqAuth checkCurrentSession error : not future AUTH`
      );
    }
  }

  setCredentials(email: string, password: string, type: string) {
    this.#password = password;
    this.#email = email;
    this.#type = type;
  }

  async login(email: string, password: string, type: string): Promise<any> {
    await localLogger.log("FabriqAuth login setCredentials");
    this.setCredentials(email, password, type);
    await localLogger.log("FabriqAuth login getToken");
    const token = await this.#getToken(type, email, password);
    await localLogger.log("FabriqAuth login getToken OK");
    return token;
  }

  async checkEmail(email: string) {
    const exists: CheckIfEmailExistsData = await this.#checkIfEmailExists(
      email
    );
    return exists;
  }

  async organizationAuthenticationConfig() {
    if (!this.#subdomainUrl || !this.#futureAuthClient) return;
    const organizationId = "";
    const response =
      await this.#futureAuthClient.getOrganizationAuthenticationConfig(
        organizationId
      );
    if (response.outcome === "success") {
      return response.authConfig;
    } else {
      throw new Error("Wrong credentials");
    }
  }

  async userIdpProfile(email: string) {
    if (!this.#subdomainUrl || !this.#futureAuthClient) return;
    const organizationId = "";
    const response = await this.#futureAuthClient.checkUserIdp(
      organizationId,
      email
    );
    if (response.outcome === "success") {
      return response.status;
    } else {
      throw new Error("Wrong credentials");
    }
  }

  async ssoAuthenticationUri() {
    if (!this.#subdomainUrl || !this.#futureAuthClient) return;
    const organizationId = "";
    const startAuthenticationResponse =
      await this.#futureAuthClient.startAuthentication(
        organizationId,
        config.ssoRedirectUri
      );
    if (startAuthenticationResponse.outcome === "authenticationAborted") {
      throw new Error("Wrong credentials");
    }
    const authId = this.#extractUrlParameter(
      startAuthenticationResponse.authenticationUrl,
      "auth_id"
    );
    if (!authId?.length) {
      throw new Error("Wrong credentials");
    }
    const samlAuthenticationUriResponse =
      await this.#futureAuthClient?.getSAMLAuthenticationUri({
        organizationId,
        authId,
        redirectUri: config.ssoRedirectUri,
      });
    if (samlAuthenticationUriResponse.outcome === "notProvided") {
      throw new Error("Wrong credentials");
    }
    return samlAuthenticationUriResponse.callbackUri;
  }

  async finalizeFutureAuth(
    authorizationCode: string
  ): Promise<Token | undefined> {
    if (!this.#subdomainUrl || !this.#futureAuthClient) return;
    const organizationId = "";
    const authenticationResponse =
      await this.#futureAuthClient.finalizeAuthentication(
        organizationId,
        authorizationCode,
        config.ssoRedirectUri
      );
    if (authenticationResponse.outcome === "authenticationFinalized") {
      const authorizationTokens = authenticationResponse.authenticationTokens;
      return {
        type: AuthType.Future,
        accessToken: authorizationTokens.access.token,
        refreshToken: authorizationTokens.refresh.token,
        expiresAt: fromUnixTime(authorizationTokens.access.payload.exp),
      };
    }
  }

  #extractUrlParameter(url: string, param: string) {
    const urlObject = new URL(url);
    return urlObject.searchParams.get(param);
  }

  reLogin(): Promise<any> {
    if (!this.#email || !this.#password || !this.#type)
      throw new Error("Wrong credentials");
    return this.#getToken(this.#type, this.#email, this.#password);
  }

  async #checkIfEmailExists(email: string): Promise<CheckIfEmailExistsData> {
    if (config.env === "qa") {
      return {
        type: AuthType.Future,
        futureSubdomain: config.apiUrl,
      };
    }
    const domain = email.replace(/[^@]*(@.*)$/, "$1");
    if (autoCheckEmail[domain]) {
      return {
        futureSubdomain: autoCheckEmail[domain],
        type: AuthType.Future,
      };
    }
    const response = await axios.get(
      `${this.#apiUrl}/api/v1/future_user_mapping`,
      {
        params: { email },
      }
    );
    if (response.status !== 200) return Promise.reject(response.data);
    if (response.status === 200 && response.data) {
      const data = response.data;
      if (!data?.future_subdomain) return Promise.reject(data?.data);
      return {
        type: AuthType.Future,
        futureSubdomain: data?.future_subdomain,
      };
    }
    return Promise.reject("unknown error");
  }

  #getToken(type: string, email: string, password: string) {
    switch (type) {
      case AuthType.Future:
        return this.#loginFuture(email, password);
      case AuthType.Legacy:
        log("Please login on webapp first");
        return Promise.reject("");
      case AuthType.Native:
      case AuthType.External:
        return localLogger.log(
          `FabriqAuth #getToken wrong type error: ${type}`
        );
      default:
        return this.#login(email, password);
    }
  }

  async futureAuthRefreshToken() {
    if (!this.#subdomainUrl || !this.#futureAuthClient) return;
    const organizationId = "";
    const response = await this.#futureAuthClient.refreshAuthorizationTokens(
      organizationId
    );
    if (response.outcome === "refreshed") {
      const authorizationTokens = response.authenticationTokens;
      return {
        type: AuthType.Future,
        accessToken: authorizationTokens.access.token,
        refreshToken: authorizationTokens.refresh.token,
        expiresAt: fromUnixTime(authorizationTokens.access.payload.exp),
      };
    } else {
      throw new Error("Refresh token error");
    }
  }

  async #loginFuture(
    email: string,
    password: string
  ): Promise<Token | undefined> {
    if (!this.#subdomainUrl || !this.#futureAuthClient) return;
    const organizationId = "";
    const startAuthResult = await this.#futureAuthClient.startAuthentication(
      organizationId,
      config.ssoRedirectUri
    );

    if (startAuthResult.outcome !== "authenticationStarted")
      throw new Error("Authentication error");

    const authId = extractParamFromUrl(
      startAuthResult.authenticationUrl,
      "auth_id",
      this.#reportSentry
    );

    if (!authId) throw new Error("Authentication error");

    const idpAuthResult =
      await this.#futureAuthClient.authenticateWithIdentityProvider(
        authId,
        organizationId,
        {
          username: email,
          password,
        }
      );
    if (idpAuthResult.outcome !== "authenticationSucceeded") {
      throw new Error("Invalid credentials");
    }

    const idpToken = extractParamFromUrl(
      idpAuthResult.callbackUrl,
      "token",
      this.#reportSentry
    );

    if (!idpToken) throw new Error("Authentication error");

    const authCodeResult =
      await this.#futureAuthClient.getAuthorizationCodeFromServer(
        organizationId,
        idpToken
      );

    if (authCodeResult.outcome !== "provided") {
      throw new Error("Authentication error");
    }

    const code = extractParamFromUrl(
      authCodeResult.callbackUrl,
      "code",
      this.#reportSentry
    );

    if (!code) throw new Error("Authentication error");

    return this.finalizeFutureAuth(code);
  }

  async logout(token: Token | null) {
    if (!token) return;
    if (token.type === AuthType.Future) {
      await this.#futureAuthClient?.logoutUser();
    }
  }

  async #login(email: string, password: string): Promise<any> {
    const data = {
      email,
      password,
      grant_type: "password",
      client_secret: "oauth2csecret",
      client_id: "oauth2cid",
    };
    const response = await axios.post(`${this.#apiUrl}/auth/login/`, data);
    if (response.status !== 200) throw new Error(response.data);
    if (response.status === 200 && response.data) {
      return response.data;
    }
    throw new Error("unknown error");
  }

  async logoutFuture() {
    await this.#futureAuthClient?.logoutUser();
  }
}
