import type { I18n } from "@/composables/useI18n.js";
import type { CalendarType } from "./formatter.js";

export interface DateTimeYearFormatter {
  /**
   * @example
   * Gregorian: "Year 2022"
   * Medtronic: "FY 2022"
   * Mars: "Y 2022"
   */
  longFullYear(year: number): string;
  /**
   * @example
   * Gregorian: "2022"
   * Medtronic: "FY 2022"
   * Mars: "Y 2022"
   */
  fullYear(year: number): string;
  /**
   * @example
   * Gregorian: "22" for 2022
   * Medtronic: "FY 22" for 2022
   * Mars: "Y 22" for 2022
   */
  shortYear(year: number): string;
}
export function buildDateTimeYearFormatter(
  calendarType: CalendarType,
  i18n: Pick<I18n, "t">
): DateTimeYearFormatter {
  return {
    longFullYear: {
      medtronic: medtronicLongFullYear,
      gregorian: gregorianLongFullYear,
      mars: marsLongFullYear,
    }[calendarType],

    fullYear: {
      medtronic: medtronicLongFullYear,
      gregorian: gregorianFullYear,
      mars: marsLongFullYear,
    }[calendarType],

    shortYear: {
      medtronic: medtronicShortYear,
      gregorian: gregorianShortYear,
      mars: marsShortYear,
    }[calendarType],
  };
  function medtronicLongFullYear(year: number) {
    return `FY ${year}`;
  }

  function medtronicShortYear(year: number) {
    return `FY ${year.toString().slice(2, 4)}`;
  }

  function gregorianLongFullYear(year: number) {
    return `${i18n.t("dates.year", 1)} ${year}`;
  }
  function gregorianFullYear(year: number) {
    return year.toString();
  }
  function gregorianShortYear(year: number) {
    return year.toString().slice(2, 4);
  }

  function marsLongFullYear(year: number) {
    return `Y ${year}`;
  }

  function marsShortYear(year: number) {
    return `Y ${year.toString().slice(2, 4)}`;
  }
}
