import { DetailedValue, ListEntity } from ".";
import { Dependency, FabriqFile } from "./ticket";

export enum RoutineExecutionView {
  List = "list",
  Step = "step",
}

export interface RoutineConfig {
  defaultExecutionView: RoutineExecutionView;
  promptScopeSelectionModal: boolean;
}

export interface Scheduler {
  id: number;
  timezone: string;
  deleted: null | boolean;
  all_day: boolean;
  is_done: boolean;
  has_started: boolean;
  is_dst: boolean;
  is_active: boolean;
  instructions: string;
  timezone_offset: number;
  team: number;
  auditee: number;
  properties: Record<string, number[]>;
}

export interface Instance extends ListEntity {
  created_by: number;
  deleted?: null;
  dependencies: Array<Dependency>;
  scheduler?: Scheduler;
  all_day: boolean;
  instructions: string | null;
  start_date: string | null;
  end_date: string | null;
  execution_end_date?: string | null;
  hashed_id: string | null;
  routine_id: number;
  schedule: number;
  schedule_type: number;
  score: Score | null;
  is_generated: boolean;
  is_deleted: boolean;
  is_scorable?: boolean;
  is_dst: boolean;
  created_at: string;
  template_id: number;
  team_id: number;
  timezone: string;
  timezone_offset: number;
  sending?: boolean;
  users: Array<number>;
}

export interface Score {
  realized?: number;
  potential?: number;
}

export enum PinnedAnswer {
  Up = "👍",
  Down = "👎",
}

export interface ExecutionInfo {
  executorId: number;
  executionDate: number;
}

export interface RoutineScope {
  auditee: number | null;
  zone: number[];
  product: number[];
  equipment: number[];
}

export interface RoutineInstance {
  scheduler: Scheduler;
}

export interface RoutineSchedule {
  id: string | number;
  scheduler: Scheduler;
  title: string;
  rule: string; // "DTSTART:20240109T144500Z\nRRULE:FREQ=DAILY",
  deleted: boolean;
  paused: boolean;
  duration: string;
  allDay: boolean;
  userIds: number[];
  scheduleTypeId: number;
  startDate: string;
  endDate: string;
  routineId: string;
}

export interface ExecutionAnswer extends ListEntity {
  files?: Array<FabriqFile>;
  fileIds?: Array<number>;
  choices: Array<number> | null;
  step: TemplateStep;
  num_mentions?: number;
  deleted?: null;
  hashed_id?: string | null;
  created_at: string;
  value?: string | null;
  score?: string;
  comment?: string;
  execution_info?: ExecutionInfo;
  pinned: PinnedAnswer | null;
  created_by?: number;
  instance_execution: number | string;
  detailed_value?: DetailedValue;
}

export interface Execution extends ListEntity {
  instance: number | string;
  is_scorable?: boolean;
  last_modified_by?: number;
  current_step_index?: number;
  ongoing?: boolean;
  routine_id?: number;
  score?: Score;
  start_date: string;
  is_done?: boolean;
  end_date?: string;
  team_id?: number;
  template?: number;
  user_ids?: Array<number>;
  choices?: Array<number>;
  created_at?: string;
  answers?: Array<ExecutionAnswer>;
}

export interface Schedule extends ListEntity {
  created_at: string;
}

export interface SetpConfig {
  visible?: boolean;
}

export interface StepChoice {
  id?: number;
  files: Array<FabriqFile>;
  fileIds: Array<number>;
  deleted: null;
  hashed_id: string;
  text: string;
  score: number;
  invalidates: boolean;
  order: number;
  value: string;
  config: SetpConfig;
}

export enum StepType {
  YesNo = "yes_no",
  Audit = "audit",
  Number = "number",
  Text = "text",
  LongText = "long_text",
  Multiple = "multiple_choice",
  Picture = "picture_choice",
  Date = "date",
  Attachment = "attachment",
  Divider = "divider",
  Checklist = "checklist",
  Indicator = "indicator",
}

export interface StepStats {
  score: number;
  potential: number;
  lastExecutionDate: string;
  numberOfExecutions: number;
}

export enum DateOption {
  DayOfExecution = "day_of_execution",
  DayBeforeExecution = "day_before_execution",
  WeekOfExecution = "week_of_execution",
  MonthOfExecution = "month_of_execution",
  YearOfExecution = "year_of_execution",
  WeekBeforeExecution = "week_before_execution",
  MonthBeforeExecution = "month_before_execution",
  YearBeforeExecution = "year_before_execution",
  BusinessDayBeforeExecution = "business_day_before_execution",
}

export interface TemplateStepConfig {
  visible?: boolean;
  i?: number;
  j?: number;
  header?: string;
  subtype?: StepType;
  indicatorId?: number;
  dateOption: DateOption;
  prevent_override: boolean;
  nudge_create_ticket?: boolean;
}

export interface TemplateStep {
  id?: number;
  files: Array<FabriqFile>;
  fileIds: Array<number>;
  choices: Array<StepChoice>;
  deleted: null;
  hashed_id: string;
  created_at: string;
  is_optional: boolean;
  is_scorable: boolean;
  step_type: StepType;
  stats: StepStats;
  parent?: number;
  question: string;
  instruction?: string;
  order: number;
  potencial_score: string;
  multiple_choice: boolean;
  config: TemplateStepConfig;
  created_by: number;
  category: null;
}

export interface Template extends ListEntity {
  routine: number;
  steps: Array<TemplateStep>;
  deleted: null;
  hashed_id?: string | null;
  version: number;
  created_at: string;
  logic?: any;
  created_by: number;
  team: number;
}

export interface FilledInstance extends Instance {
  routine: Routine;
  template?: Template;
  execution?: Execution;
  disabled: boolean;
  is_done: boolean;
  has_started: boolean;
  title?: string;
}

export interface Routine extends ListEntity {
  client: number;
  created_at: string;
  config: RoutineConfig;
  created_by: number;
  deleted: string | null;
  description: string;
  followers: Array<number>;
  hashed_id: string | null;
  is_archived: boolean;
  schedule: RoutineSchedule[];
  teams: Array<number>;
  template_id: number;
  title: string;
  disabled?: boolean;
}
