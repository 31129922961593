import { DependenciesClient } from "./clients";
import {
  DiamondDependency,
  EventTicketDiamondDependencyPayload,
  GetEventDiamondDependenciesPayload,
  GetTicketDiamondDependenciesPayload,
  ListService,
} from "@/types";

export const DiamondDependenciesService: ListService<DiamondDependency> = {
  async add(entity: DiamondDependency, request): Promise<DiamondDependency> {
    const eventId: string = (
      entity.dependentType === "event" ? entity.dependentId : entity.relatedId
    ) as string;
    const ticketId: number = (
      entity.dependentType === "ticket" ? entity.dependentId : entity.relatedId
    ) as number;

    try {
      const payload: EventTicketDiamondDependencyPayload = {
        dependentId: ticketId,
        relatedId: eventId,
        dependentType: "ticket",
        relatedType: "event",
        dependencyType: "relatedTo",
      };
      return DependenciesClient.post("", payload, false, request);
    } catch (error) {
      console.error(error);
      return Promise.reject(undefined);
    }
  },
  async remove(entity: DiamondDependency, request): Promise<void> {
    return DependenciesClient.delete(entity.id, request);
  },
  async all(
    payload:
      | GetTicketDiamondDependenciesPayload
      | GetEventDiamondDependenciesPayload
  ): Promise<DiamondDependency[]> {
    const dependencies = await DependenciesClient.get(
      `/${payload.entityType}/${payload.entityId}`
    );
    return dependencies;
  },
};
