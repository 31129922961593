import {
  type DateTime,
  gregorianCalendar,
  medtronicCalendar,
} from "@fabriq/date_time";
import type { CalendarType } from "./formatter.js";
import { buildDateTimeYearFormatter } from "./year_formatter.js";
import type { I18n } from "@/composables/useI18n.js";
import { format as formatDate } from "date-fns";
import { getDateFnsLocales } from "@/i18n/index.js";

export interface DateTimeWeekFormatter {
  /**
   * @example
   * Gregorian: "Week 1"
   * Medtronic: "FW 01"
   * Mars: "W 01"
   */
  longWeek(week: DateTime["week"]): string;
  /**
   * @example
   * Gregorian: "W1"
   * Medtronic: "FW 01"
   * Mars: "W 01"
   */
  shortWeek(week: DateTime["week"]): string;
  /**
   * @example
   * Gregorian: "W2 2025 (12 jan)"
   * Medtronic: "FY 25 FW 01 (27 apr)"
   * Mars: "Y 25 W 01"
   */
  shortWeekWithYearAndShortStartDay(dateTime: DateTime): string;

  /**
   * @example
   * Gregorian: "W2 2025"
   * Medtronic: "FY 25 FW 01"
   * Mars: "Y 25 W 01"
   */
  shortWeekLongYear(dateTime: DateTime): string;
}

export function buildDateTimeWeekFormatter(
  calendarType: CalendarType,
  i18n: Pick<I18n, "t">,
  locale: string
): DateTimeWeekFormatter {
  const formatYear = buildDateTimeYearFormatter(calendarType, i18n);
  return {
    longWeek: {
      medtronic: medtronicNumberWithLongPrefix,
      gregorian: gregorianNumberWithLongPrefix,
      mars: marsNumberWithLongPrefix,
    }[calendarType],

    shortWeek: {
      medtronic: medtronicShortWeek,
      gregorian: gregorianShortWeek,
      mars: marsNumberWithLongPrefix,
    }[calendarType],

    shortWeekWithYearAndShortStartDay: {
      medtronic: medtronicShortWeekWithYearAndShortStartDay,
      gregorian: gregorianShortWeekWithYearAndShortStartDay,
      mars: marsShortWeekWithYearAndShortStartDay,
    }[calendarType],

    shortWeekLongYear: {
      medtronic: medtronicShortWeekLongYear,
      gregorian: gregorianShortWeekLongYear,
      mars: marsShortWeekWithYearAndShortStartDay,
    }[calendarType],
  };
  function medtronicNumberWithLongPrefix(week: DateTime["week"]) {
    return `FW ${week!.toString().padStart(2, "0")}`;
  }

  function gregorianNumberWithLongPrefix(week: DateTime["week"]) {
    return `${i18n.t("dates.week", 1)} ${week}`;
  }

  function marsNumberWithLongPrefix(week: DateTime["week"]) {
    return `W ${week!.toString().padStart(2, "0")}`;
  }

  function medtronicShortWeek(week: DateTime["week"]) {
    return `FW ${week!.toString().padStart(2, "0")}`;
  }

  function gregorianShortWeek(week: DateTime["week"]) {
    return `${i18n.t("dates.week", 1).slice(0, 1)}${week}`;
  }

  function medtronicShortWeekWithYearAndShortStartDay(dateTime: DateTime) {
    const startOfWeekDate = medtronicCalendar.toDate(
      medtronicCalendar.startOfInterval(dateTime, "day", "week")
    );
    const year = formatYear.shortYear(dateTime.year);
    const weekNumber = medtronicShortWeek(dateTime.week);
    const startDay = formatDate(startOfWeekDate, i18n.t("formats.dayOfMonth"), {
      locale: getDateFnsLocales(locale),
    });
    return `${year} ${weekNumber} (${startDay})`;
  }

  function marsShortWeekWithYearAndShortStartDay(dateTime: DateTime) {
    const year = formatYear.shortYear(dateTime.year);
    const weekNumber = marsNumberWithLongPrefix(dateTime.week);
    return `${year} ${weekNumber}`;
  }

  function gregorianShortWeekWithYearAndShortStartDay(dateTime: DateTime) {
    const date = gregorianCalendar.toDate(dateTime);
    const prefix = i18n.t("dates.week", 1).slice(0, 1);
    const firstDayOfWeek = formatDate(date, "d MMM", {
      locale: getDateFnsLocales(locale),
    });
    return `${prefix}${dateTime.week} ${dateTime.year} (${firstDayOfWeek})`;
  }

  function gregorianShortWeekLongYear(dateTime: DateTime) {
    return `${i18n.t("dates.week", 1).slice(0, 1)}${dateTime.week} ${
      dateTime.year
    }`;
  }

  function medtronicShortWeekLongYear(dateTime: DateTime) {
    return `FY ${dateTime.year.toString().slice(2, 4)} ${medtronicShortWeek(
      dateTime.week
    )}`;
  }
}
