import { Capacitor } from "@capacitor/core";
import { Dexie as DexieType } from "dexie";
import { Network, ConnectionStatus } from "@capacitor/network";
import config from "../config";
import { Device, DeviceInfo } from "@capacitor/device";
import axios from "axios";
import { AuthType } from "@/types";
import { version } from "../../package.json";
import { getCurrentBuild } from "@/utils/live-updates";
import { localLogger } from "@/utils/localLogger";

type DeviceInfoWithAppVersion = DeviceInfo & {
  appVersion: string;
  buildVersion: string;
};

interface Record {
  key: string;
  label: string;
  type: "time" | "tunnel" | "count" | "request";
  category: RecordCategory;
  startTime: number;
  clientId?: number;
  userId?: number;
  device?: DeviceInfoWithAppVersion;
  endTime?: number;
  totalTime?: number;
  actions?: Action[];
  createdAt: Date;
}

export enum RecordCategory {
  General = "general",
  Tickets = "tickets",
  Events = "events",
  Routines = "routines",
  Notifications = "notifications",
  Kpis = "indicators",
  Search = "search",
  Network = "network",
}

interface PerformanceTrackerError {
  error: string;
  status?: number;
  message?: string;
  stack?: string;
}

export enum ActionType {
  Ui = "ui",
  Store = "store",
  Request = "request",
  Process = "process",
  Error = "error",
}
interface Action {
  type?: ActionType;
  label?: string;
  startTime: number;
  endTime?: number;
  totalTime?: number;
  networkStart?: string;
  networkEnd?: string;
}

export class PerformanceTracker {
  #debug: boolean;
  #fabriqStore: any;
  #clientId: number | undefined;
  #userId: number | undefined;
  #device: DeviceInfoWithAppVersion | undefined;
  #network: ConnectionStatus | undefined;
  #serviceUrl: string;

  #httpClient: any;

  #idb: DexieType | undefined;

  #timers: Map<string, NodeJS.Timeout> = new Map();
  #records: Map<string, Record> = new Map();

  static get #SEND_LIMIT(): number {
    return 100;
  }

  static get #TRACKING_TIMEOUT(): number {
    return process.env.NODE_ENV === "staging" ? 10000 : 60000;
  }

  constructor(debug = false, client: any) {
    const platform = Capacitor.getPlatform();
    this.#serviceUrl = config.performancesUrl;
    this.#debug =
      import.meta.env.VITE_FABRIQ_ENV === "test"
        ? false
        : platform === "web" || debug;
    this.#httpClient = client || axios;
  }

  applySubDomainUrl(serviceUrl: string) {
    this.#serviceUrl = serviceUrl;
  }

  setIndexedDB(idb: DexieType) {
    this.#idb = idb;
  }

  setFabriqStore(fabriqStore: any) {
    this.#fabriqStore = fabriqStore;
  }

  setClientId(id: number) {
    this.#clientId = id;
  }

  setUserId(id: number) {
    this.#userId = id;
  }

  #exists(key: string): boolean {
    return this.#records.has(key);
  }

  async #getConnectionType(actionType: ActionType | undefined) {
    if (!actionType) return Promise.resolve();
    if (actionType !== ActionType.Request) return Promise.resolve(undefined);
    if (this.#network) return Promise.resolve(this.#network.connectionType);
    try {
      const status = await Network.getStatus();
      this.#network = status;
      return status.connectionType;
    } catch (e) {
      console.warn(e);
    }
    return Promise.resolve("unknown");
  }

  async #getDevice() {
    if (this.#device) return Promise.resolve(this.#device);
    try {
      const device = await Device.getInfo();
      const buildVersion = await getCurrentBuild();
      this.#device = { ...device, appVersion: version, buildVersion };
      return this.#device;
    } catch (e) {}
    return undefined;
  }

  async setNetwork(network: ConnectionStatus) {
    this.#network = network;
  }

  #startTimeout = (key: string) => {
    this.#timers.set(
      key,
      setTimeout(() => {
        this.end(key, { error: "timeout" });
      }, PerformanceTracker.#TRACKING_TIMEOUT)
    );
  };

  #clearTimeout = (key: string) => {
    if (!this.#timers.has(key)) return;
    clearTimeout(this.#timers.get(key));
    this.#timers.delete(key);
  };

  #log(...arg: any) {
    if (this.#debug) console.log("[PerformanceTracker]", ...arg);
    const msg = JSON.stringify(arg);
    localLogger.log(msg);
  }

  #endLastAction(key: string, now: number) {
    const record = this.#records.get(key);
    if (!record?.actions?.length) return;
    const index = record.actions.length - 1;
    if (index < 0) return;
    const action = record.actions[index];
    const endTime = now;
    const totalTime = now - action.startTime;
    const updatedAction =
      action.type === ActionType.Request
        ? {
            ...action,
            endTime: endTime,
            totalTime: totalTime,
            networkEnd: this.#network?.connectionType,
          }
        : {
            ...action,
            endTime: endTime,
            totalTime: totalTime,
          };
    this.#updateAction(key, updatedAction, index);
  }

  #updateAction(key: string, data: any, index: number) {
    const record = this.#records.get(key);
    if (!record?.actions?.length) return;
    const action = record.actions[index];
    if (!action) return;
    this.#updateRecord(key, {
      actions: [
        ...record.actions.slice(0, index),
        { ...action, ...data },
        ...record.actions.slice(index + 1),
      ],
    });
  }

  #updateRecord(key: string, data: any) {
    const record = this.#records.get(key);
    if (!record) return;
    this.#records.set(key, { ...record, ...data });
  }

  #addAction(key: string, action: Action) {
    const record = this.#records.get(key);
    if (!record) return;
    const actions = record.actions || [];
    const index = actions.length;
    this.#records.set(key, { ...record, actions: [...actions, action] });
    return index;
  }

  async tunnel(
    key: string,
    label: string,
    category: RecordCategory,
    type: ActionType,
    actionLabel?: string
  ): Promise<string | void> {
    if (!this.#idb) return console.warn(`Tracker idb not set`);
    const startTime = Date.now();
    const records = await this.#idb
      .table("performances")
      .where("key")
      .equals(key)
      .toArray();
    if (!records?.length) {
      this.#log("BEGIN TUNNEL", key, label, actionLabel);
      const record: Record = {
        key,
        type: "tunnel",
        label,
        category,
        clientId: this.#clientId,
        userId: this.#userId,
        startTime,
        createdAt: new Date(),
        actions: [
          {
            type,
            label: actionLabel,
            startTime,
          },
        ],
      };
      this.#idb.table("performances").add(record);
    } else {
      this.#log("UPDATE TUNNEL", key, label, actionLabel);
      const actions = [
        ...records[0].actions,
        {
          type,
          label: actionLabel,
          startTime,
        },
      ];
      this.#idb.table("performances").update(records[0].id, { actions });
    }
  }

  async track(key: string, label: string, category: RecordCategory) {
    if (!this.#idb) return console.warn(`Tracker idb not set`);
    const startTime = Date.now();
    const device = await this.#getDevice();
    const record: Record = {
      key,
      type: "count",
      label,
      category,
      clientId: this.#clientId,
      userId: this.#userId,
      startTime,
      endTime: startTime,
      totalTime: 0,
      device,
      createdAt: new Date(),
    };
    await this.#idb.table("performances").add(record);
    this.#log("TRACK", key, record);
    return record;
  }

  beginRequest(key: string, label: string, actionLabel?: string) {
    if (!this.#idb) return console.warn(`Tracker idb not set`);
    if (this.#exists(key))
      return console.warn(`Tracker "${key}" already started`);
    this.#log("BEGIN REQUEST", key, label, actionLabel);
    this.#startTimeout(key);
    const startTime = Date.now();
    const action = {
      type: ActionType.Request,
      label: actionLabel,
      startTime,
      networkStart: this.#network?.connectionType,
    };
    const record: Record = {
      key,
      type: "request",
      label,
      category: RecordCategory.General,
      clientId: this.#clientId,
      userId: this.#userId,
      startTime,
      createdAt: new Date(),
      actions: [action],
    };
    this.#records.set(key, record);
  }

  async nextRequest(key: string, label?: string) {
    if (!this.#idb) return console.warn(`Tracker idb not set`);
    if (!this.#exists(key)) return console.warn(`Tracker "${key}" not started`);
    this.#log("NEXT REQUEST", key, label);
    const now = Date.now();
    this.#endLastAction(key, now);
    const action = {
      type: ActionType.Request,
      label,
      startTime: now,
      networkStart: this.#network?.connectionType,
    };
    this.#addAction(key, action);
  }

  begin(
    key: string,
    label: string,
    category: RecordCategory,
    type: ActionType,
    actionLabel?: string
  ) {
    if (!this.#idb) return console.warn(`Tracker idb not set`);
    if (this.#exists(key))
      return console.warn(`Tracker "${key}" already started`);
    this.#log("BEGIN", key, label, category, type, actionLabel);
    this.#startTimeout(key);
    const startTime = Date.now();
    const action =
      type === ActionType.Request
        ? {
            type,
            label: actionLabel,
            startTime,
            networkStart: this.#network?.connectionType,
          }
        : {
            type,
            label: actionLabel,
            startTime,
          };
    const record: Record = {
      key,
      type: "time",
      label,
      category,
      clientId: this.#clientId,
      userId: this.#userId,
      startTime,
      createdAt: new Date(),
      actions: [action],
    };
    this.#records.set(key, record);
  }

  async next(key: string, type: ActionType, label?: string) {
    if (!this.#idb) return console.warn(`Tracker idb not set`);
    if (!this.#exists(key)) return console.warn(`Tracker "${key}" not started`);
    this.#log("NEXT", key, type, label);
    const now = Date.now();
    this.#endLastAction(key, now);
    const action =
      type === ActionType.Request
        ? {
            type,
            label,
            startTime: now,
            networkStart: this.#network?.connectionType,
          }
        : {
            type,
            label,
            startTime: now,
          };
    this.#addAction(key, action);
  }

  async end(key: string, error?: PerformanceTrackerError) {
    if (!this.#idb) return console.warn(`Tracker idb not set`);
    if (!this.#exists(key)) return console.warn(`Tracker "${key}" not started`);
    this.#clearTimeout(key);
    const now = Date.now();
    const [device] = await Promise.all([
      this.#getDevice(),
      this.#endLastAction(key, now),
    ]);
    const record = this.#records.get(key);
    if (!record) return;
    const endTime = now;
    const totalTime = now - record.startTime;
    this.#records.delete(key);
    const performance = {
      ...record,
      device,
      clientId: this.#clientId,
      userId: this.#userId,
      endTime,
      totalTime,
      error,
    };
    await this.#idb.table("performances").add(performance);
    this.#log("END", key, error, performance);
    return performance;
  }

  async save() {
    if (!this.#idb) return console.warn(`Tracker idb not set`);
    if (!this.#fabriqStore) return console.warn("No Fabriq Store Set");
    const user = this.#fabriqStore.user;
    const token =
      this.#fabriqStore.token?.type === AuthType.Future
        ? `BearerFuture ${this.#fabriqStore.token?.accessToken}`
        : `Bearer ${this.#fabriqStore.token?.accessToken}`;
    if (!token) return console.warn("No accessToken found");
    if (!user) return console.warn("No user found");
    try {
      const performances = await this.#idb
        .table("performances")
        .orderBy("createdAt")
        .limit(PerformanceTracker.#SEND_LIMIT);
      const performancesArray = await performances.toArray();
      this.#log("SAVE", performancesArray.length, "Performances");

      if (!this.#debug) {
        await this.#httpClient.post(
          `${this.#serviceUrl}/performances`,
          {
            token,
            user: user.id,
            performances: performancesArray,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        this.#log(performancesArray);
      }
      await performances.delete();
    } catch (e) {
      console.warn(e);
    }
  }

  __toString() {
    return JSON.stringify(this.#records);
  }
}
