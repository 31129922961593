<script lang="ts" setup>
import { IonList } from "@ionic/vue";
import EventItem from "@/components/business/events/EventItem.vue";
import { FabriqEventWithReadableProperties } from "@/types";
import { useDiamondDependenciesStore } from "@/store/diamondDependencies";

interface Props {
  events?: FabriqEventWithReadableProperties[];
}

withDefaults(defineProps<Props>(), {
  events: () => [],
});

const emit = defineEmits(["select"]);

const dependenciesStore = useDiamondDependenciesStore();
dependenciesStore.merge([], true);
</script>

<template>
  <ion-list class="event-list">
    <EventItem
      v-for="event of events"
      :key="event.id"
      :fEvent="event"
      @select="emit('select', $event)"
    />
  </ion-list>
</template>

<style scoped>
.event-list {
  /* padding: 0 calc(var(--ion-padding) / 2) var(--ion-padding); */
  padding: 0;
  --ion-item-background: transparent;
}
</style>
