import { ApiClient } from "./clients";
import { Notification, ListService } from "@/types";
import { Badge } from "@capawesome/capacitor-badge";

export interface NotificationListService extends ListService<Notification> {
  readAll?: (ids: number[]) => Promise<void>;
  getCount?: () => Promise<number>;
}

const notificationMapper = (n: any) => ({
  ...n,
  created_at: n.event_datetime,
  updated_at: n.event_datetime,
});
export const NotificationService: NotificationListService = {
  async all(options: any): Promise<Array<Notification>> {
    const params = options.all
      ? { client: "mobile" }
      : { client: "mobile", is_read: "0" };
    const notifications = await ApiClient.get(`/api/v3/notifications/`, params);
    return notifications.map(notificationMapper);
  },
  async update(
    entity: Notification,
    columns: any,
    request
  ): Promise<Notification> {
    const updatedNotification = await ApiClient.put(
      `/api/v2/notification/${entity.id}/toggleIsRead/`,
      columns,
      request
    );
    return { ...updatedNotification, ...columns };
  },
  async load(id: number): Promise<Notification> {
    const notification = await ApiClient.get(`/api/v2/notification/${id}/`);
    if (!notification.notification_text?.length)
      throw new Error("Empty notification");
    return notification;
  },
  async readAll(notifications: number[]): Promise<void> {
    await ApiClient.put(`/api/v2/notifications/toggleIsRead/`, {
      notifications,
      is_read: "1",
    });
    return Promise.resolve();
  },
  async getCount(): Promise<number> {
    const { count } = await ApiClient.get(`/api/v3/notifications/count/`, {
      is_read: "0",
      client: "mobile",
    });
    Badge.set({ count });
    return count || 0;
  },
};
