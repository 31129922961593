<script setup lang="ts">
import { useFormatDateTime } from "@/composables/useFormatDateTime";
import { useIndicatorCalendar } from "@/composables/useIndicatorCalendar";
import { type DateTime, calendarCommon } from "@fabriq/date_time";
import { IonButton } from "@ionic/vue";
import { computed, ref, watchEffect } from "vue";

export type PickerPrecision = "year" | "month" | "weeknumber";

interface Props {
  class?: string;
  dateTime?: DateTime;
  precision?: PickerPrecision;
}

const props = withDefaults(defineProps<Props>(), {
  precision: "weeknumber",
});

const emit = defineEmits<{
  (e: "select", dateTime: DateTime): void;
}>();

const indicatorCalendar = useIndicatorCalendar();
const formatDateTime = useFormatDateTime();

const selectedDate = ref(props.dateTime ?? indicatorCalendar.today());
const displayedDate = ref(formatDisplayedDate(selectedDate.value));

function formatDisplayedDate(dateTime: DateTime) {
  switch (props.precision) {
    case "year":
      return formatDateTime.year.fullYear(dateTime.year);
    case "month":
      return formatDateTime.month.shortMonthShortYear(dateTime);
    case "weeknumber":
      return formatDateTime.week.shortWeekWithYearAndShortStartDay(dateTime);
  }
}

watchEffect(() => {
  if (props.dateTime) {
    selectedDate.value = props.dateTime;
    displayedDate.value = formatDisplayedDate(props.dateTime);
  }
});

const step = ref<PickerPrecision>(props.precision);
const isOpen = ref(false);

const weeks = computed(() => {
  const days = indicatorCalendar.daysOfMonth(selectedDate.value);
  const daysInWeeks = calendarCommon.groupByInterval(days, "week");
  return [...daysInWeeks.values()].map(({ interval }) => interval);
});

const months = computed(() => {
  const months = indicatorCalendar.monthOfYear(selectedDate.value);
  return months;
});

const years = computed(() => {
  const { year } = indicatorCalendar.today();
  return [year + 1, year, year - 1];
});

const selectDate = (date: DateTime) => {
  selectedDate.value = date;

  if (step.value === "year" && props.precision !== "year") {
    selectedDate.value = indicatorCalendar.startOfInterval(
      date,
      "week",
      "year"
    );
    return (step.value = "month");
  }
  if (step.value === "month" && props.precision !== "month") {
    selectedDate.value = indicatorCalendar.startOfInterval(
      date,
      "week",
      "month"
    );
    return (step.value = "weeknumber");
  }

  displayedDate.value = formatDisplayedDate(selectedDate.value);
  emit("select", date);
  isOpen.value = false;
};

const goTo = (nextStep: PickerPrecision) => {
  step.value = nextStep;
};

const isSameMonth = (dateTime: DateTime, selectedDate: DateTime) => {
  return (
    calendarCommon.compare(
      calendarCommon.changePrecision(dateTime, "month"),
      calendarCommon.changePrecision(selectedDate, "month")
    ) === 0
  );
};
const isSameWeek = (dateTime: DateTime, selectedDate: DateTime) => {
  return (
    calendarCommon.compare(
      calendarCommon.changePrecision(dateTime, "week"),
      calendarCommon.changePrecision(selectedDate, "week")
    ) === 0
  );
};

const hasReachedPreviousYearLimit = computed(() => {
  return selectedDate.value.year === indicatorCalendar.today().year - 1;
});

const hasReachedNextYearLimit = computed(() => {
  return selectedDate.value.year === indicatorCalendar.today().year + 1;
});

function handlePreviousYear() {
  selectedDate.value = indicatorCalendar.add(selectedDate.value, "year", -1);
}

function handleNextYear() {
  selectedDate.value = indicatorCalendar.add(selectedDate.value, "year", 1);
}

const hasReachedPreviousMonthLimit = computed(() => {
  const previousYearFromToday = indicatorCalendar.add(
    indicatorCalendar.startOfInterval(
      indicatorCalendar.today(),
      "month",
      "year"
    ),
    "year",
    -1
  );
  return (
    calendarCommon.compare(
      previousYearFromToday,
      calendarCommon.changePrecision(selectedDate.value, "month")
    ) === 0
  );
});

const hasReachedNextMonthLimit = computed(() => {
  const nextYearFromToday = indicatorCalendar.add(
    indicatorCalendar.endOfInterval(indicatorCalendar.today(), "month", "year"),
    "year",
    1
  );
  return (
    calendarCommon.compare(
      nextYearFromToday,
      calendarCommon.changePrecision(selectedDate.value, "month")
    ) === 0
  );
});

function handlePreviousMonth() {
  const firstDayOfMonth = indicatorCalendar.startOfInterval(
    selectedDate.value,
    "day",
    "month"
  );
  selectedDate.value = indicatorCalendar.add(firstDayOfMonth, "day", -1);
}
function handleNextMonth() {
  const lastDayOfMonth = indicatorCalendar.endOfInterval(
    selectedDate.value,
    "day",
    "month"
  );
  selectedDate.value = indicatorCalendar.add(lastDayOfMonth, "day", 1);
}
function toggleCalendar() {
  isOpen.value = !isOpen.value;
}
</script>

<template>
  <ion-button
    fill="clear"
    expand="block"
    data-qa="datetime-picker-trigger"
    class="selected-date-button"
    :class="props.class"
    @click="toggleCalendar"
  >
    <span style="width: 100%">{{ displayedDate }}</span>
    <font-icon
      slot="end"
      name="date_range"
      material
      color="var(--ion-color-primary-shade)"
    />
  </ion-button>

  <div v-if="isOpen" class="wrapper">
    <ul v-if="step === 'year'" class="stack-column">
      <li
        v-for="year in years"
        :key="year"
        :class="{
          active: year === selectedDate.year,
        }"
      >
        <ion-button
          expand="block"
          fill="clear"
          @click="() => selectDate({ year })"
          :data-qa="`datetime-picker-year-${year}`"
        >
          {{ formatDateTime.year.fullYear(year) }}
        </ion-button>
      </li>
    </ul>

    <div v-else-if="step === 'month'" class="stack-column">
      <div class="step-header">
        <ion-button
          fill="clear"
          data-qa="datetime-picker-previous-year"
          :disabled="hasReachedPreviousYearLimit"
          @click="handlePreviousYear()"
        >
          <font-icon slot="start" name="navigate_before" material />
        </ion-button>
        <ion-button
          fill="clear"
          data-qa="datetime-picker-navigation-year"
          @click="() => goTo('year')"
        >
          {{ formatDateTime.year.fullYear(selectedDate.year) }}
        </ion-button>
        <ion-button
          fill="clear"
          data-qa="datetime-picker-next-year"
          :disabled="hasReachedNextYearLimit"
          @click="handleNextYear()"
        >
          <font-icon slot="start" name="navigate_next" material />
        </ion-button>
      </div>

      <ul class="month-list">
        <li
          v-for="monthDateTime in months"
          :key="monthDateTime.month"
          :class="{ active: isSameMonth(monthDateTime, selectedDate) }"
        >
          <ion-button
            fill="clear"
            expand="full"
            :data-qa="`datetime-picker-month-${monthDateTime.month}`"
            @click="() => selectDate(monthDateTime)"
          >
            {{ formatDateTime.month.shortMonth(monthDateTime) }}
          </ion-button>
        </li>
      </ul>
    </div>

    <div v-else-if="step === 'weeknumber'" class="stack-column">
      <div class="step-header">
        <ion-button
          fill="clear"
          data-qa="datetime-picker-previous-month"
          @click="handlePreviousMonth()"
          :disabled="hasReachedPreviousMonthLimit"
        >
          <font-icon slot="start" name="navigate_before" material />
        </ion-button>

        <ion-button
          v-if="selectedDate"
          fill="clear"
          data-qa="datetime-picker-navigation-month"
          @click="goTo('month')"
        >
          {{ formatDateTime.month.shortMonth(selectedDate) }}
        </ion-button>

        <ion-button
          fill="clear"
          data-qa="datetime-picker-next-month"
          :disabled="hasReachedNextMonthLimit"
          @click="handleNextMonth()"
        >
          <font-icon slot="start" name="navigate_next" material />
        </ion-button>
      </div>
      <ul class="week-list">
        <li
          v-for="weekDateTime in weeks"
          :key="`${weekDateTime.year} ${weekDateTime.week}`"
          :class="{ active: isSameWeek(weekDateTime, selectedDate) }"
        >
          <ion-button
            fill="clear"
            expand="block"
            :data-qa="`datetime-picker-week-${weekDateTime.week}`"
            @click="() => selectDate(weekDateTime)"
          >
            <div class="stack-column" style="gap: 0">
              <span>
                {{ formatDateTime.week.shortWeek(weekDateTime.week) }}
              </span>
              <small>
                {{
                  indicatorCalendar
                    .toDate(
                      indicatorCalendar.startOfInterval(
                        weekDateTime,
                        "day",
                        "week"
                      )
                    )
                    .getDate()
                }}
                -
                {{
                  indicatorCalendar
                    .toDate(
                      indicatorCalendar.endOfInterval(
                        weekDateTime,
                        "day",
                        "week"
                      )
                    )
                    .getDate()
                }}
              </small>
            </div>
          </ion-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.selected-date-button {
  text-align: left;
  display: flex;
  align-items: center;
  gap: var(--f-spacer-8, 8px);
  justify-content: space-between;
  border: 1px solid var(--ion-border-color);
  border-radius: var(--f-border-radius);
  font-size: var(--font-size-m);
  text-transform: none;
  font-weight: normal;
  background: var(--ion-background-color-shade);
  height: 40px;
  margin-inline: 0;

  &::part(native) {
    padding: calc(var(--ion-padding) / 2) var(--ion-padding);
  }
}

.active {
  border-radius: var(--f-border-radius);
  background: var(--f-default-background-color-hover);
}

.wrapper {
  width: 100%;
  padding: var(--f-spacer-8);
}

.month-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  white-space: pre-wrap;
  gap: 8px;

  > li ion-button {
    white-space: pre-wrap;
  }
}

.week-list {
  --gap: 8px;
  display: grid;
  grid-template-columns: repeat(4, calc(25% - var(--gap)));
  gap: var(--gap);
}

ul {
  padding: 0;
  margin: 0;
  width: 100%;
}
li {
  list-style: none;
}

.step-header {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}
.stack-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: stretch;
}
.stack-row {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: stretch;
}
</style>
