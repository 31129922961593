import { ApiClient, KpiV3Client } from "./clients";
import { Datapoint, IndicatorFrequency, ListService } from "@/types";
import {
  DateTimePrecision,
  dateTimeToDateString,
  generateDateTimeFromDate,
} from "@/utils/dates";

interface indicatorParams {
  id: number;
  from_date: string;
}

export const DatapointService: ListService<Datapoint> = {
  async all(options: indicatorParams): Promise<Datapoint> {
    const params: { id?: number; from_date?: string } = {};
    if (options.id) params.id = options.id;
    if (options.from_date) params.from_date = options.from_date;
    const { from_date, id } = params;

    if (from_date) {
      return KpiV3Client.get(`/v1/indicator/${id}/datapoints`, {
        from_date,
      });
    }
    return KpiV3Client.get(`/v2/indicator/${id}/datapoints`);
  },
  async load(
    indicatorId: number,
    params: {
      selected_date?: string;
      frequency?: IndicatorFrequency;
    }
  ): Promise<Datapoint> {
    const { selected_date } = params;
    if (selected_date) {
      let precision: DateTimePrecision = "day";
      switch (params.frequency) {
        case "weekly":
          precision = "week";
          break;
        case "monthly":
          precision = "month";
          break;
        default:
          break;
      }
      const dateTimeAsString = dateTimeToDateString(
        generateDateTimeFromDate(new Date(selected_date), precision)
      );
      const datapointId = `${indicatorId}-${dateTimeAsString}`;
      const data = await KpiV3Client.get(
        `/v2/datapoints?datapoint_ids=['${datapointId}']`
      );
      return data !== undefined && data.length > 0 ? data[0] : undefined;
    } else if (selected_date) {
      const data = await ApiClient.get(
        `/api/v1/indicator/${indicatorId}/datapoints?selected_date=${selected_date}/`
      );
      return data !== undefined && data.length > 0 ? data[0] : undefined;
    } else {
      return await ApiClient.get(
        `/api/v2/indicator/${indicatorId}/datapoints/`
      );
    }
  },
};
