import { Instance } from "@/types";
import { computed, onMounted, onUnmounted, ref, Ref } from "vue";
import {
  parseISO,
  isWithinInterval,
  format,
  isSameDay,
  isBefore,
  isAfter,
} from "date-fns";
import { useExecutionsStore } from "@/store/executions";
import { useAnswersStore } from "@/store/answers";

export const useCanStartRoutine = (
  instance: Ref<Instance>,
  loading: Ref<boolean>
) => {
  let intervalId: NodeJS.Timer;
  const executionsStore = useExecutionsStore();
  const answersStore = useAnswersStore();
  const now = ref(new Date());

  onMounted(() => {
    intervalId = setInterval(() => {
      now.value = new Date();
    }, 10000);
  });

  onUnmounted(() => {
    clearInterval(intervalId);
  });

  const instanceIsDone = computed(() => {
    if (instance.value?.sending) return false;
    return instance.value?.scheduler?.is_done;
  });

  const canBeResent = computed(() => {
    if (!canStartRoutine.value && !instanceIsDone.value) {
      if (answers.value.length > 0) {
        return true;
      }
    }
    if (instance.value?.sending) return false;
    if (instanceIsDone.value) return false;
    if (!execution.value?.is_done) return false;
    return true;
  });

  const execution = computed(() => {
    if (!instance.value) return null;
    return executionsStore.collection.find(
      (ex: any) => ex.instance === instance.value.id
    );
  });
  const answers = computed(() => {
    if (!execution.value) return [];
    return answersStore.collection.filter(
      (a: any) =>
        a.instance_execution === execution.value.id &&
        a.execution_info.executionDate
    );
  });

  const isSending = computed(() => {
    return instance.value?.sending === true || loading.value;
  });

  const canStartRoutine = computed(() => {
    if (!instance.value?.start_date && !instance.value?.end_date) return true;
    const start = parseISO(instance.value.start_date || "");
    const end = parseISO(instance.value.end_date || "");
    if (
      format(start, "HH:mm") === "00:00" &&
      format(end, "HH:mm") === "23:59"
    ) {
      // All day (24 hours routine)
      // automatic or manual
      return (
        isSameDay(now.value, start) ||
        isSameDay(now.value, end) ||
        (isBefore(start, now.value) && isAfter(end, now.value))
      );
    } else {
      // Not 24 hours routine
      // check if now is within start and end dates
      return isWithinInterval(now.value, {
        start,
        end,
      });
    }
  });

  return {
    canStartRoutine,
    canBeResent,
    isSending,
    instanceIsDone,
  };
};
