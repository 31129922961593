<script lang="ts" setup>
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { getTextToDisplay } from "@/components/business/tickets/services/getTextToDisplay";
import TicketList from "@/components/business/tickets/TicketList.vue";
import TicketCreateModal from "@/components/modals/TicketCreateModal.vue";
import AccordionItem from "@/components/ui/AccordionItem.vue";
import FiltersBar from "@/components/ui/FiltersBar.vue";
import LoadingBlock from "@/components/ui/LoadingBlock.vue";
import RoutePage from "@/components/ui/RoutePage.vue";
import {
  useCallRefreshToastError,
  useCallRefreshToastSuccess,
} from "@/composables/useCallRefreshToast";
import { useI18n } from "@/composables/useI18n";
import { useFabriqStore } from "@/store/fabriq";
import { useFilesStore } from "@/store/files";
import { useTasksStore } from "@/store/tasks";
import { useTeamsStore } from "@/store/teams";
import { useTicketsStore } from "@/store/tickets";
import { useTicketTemplatesStore } from "@/store/ticketTemplates";
import { MediaType, Task, Ticket, TicketTemplate } from "@/types";
import { addTicketTemplatePresetValues } from "@/utils/addTicketTemplatePresetValues";
import { ticketPageAnimation } from "@/utils/animations";
import { createEmptyTicket } from "@/utils/createEmptyTicket";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { calcModalPercents } from "@/utils/modals";
import tracker from "@/utils/tracker";
import { Camera, CameraResultType } from "@capacitor/camera";
import {
  IonFabButton,
  IonInput,
  IonToolbar,
  modalController,
  onIonViewDidEnter,
  RefresherEventDetail,
  useIonRouter,
} from "@ionic/vue";
import { addDays, format, formatISO } from "date-fns";
import { storeToRefs } from "pinia";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import type { Team } from "@/types/team";
import TeamInstancePickerModal from "@/components/modals/TeamInstancePickerModal.vue";
import { useClientStore } from "@/store/client";

const fabriqStore = useFabriqStore();
const clientStore = useClientStore();
const ticketsStore = useTicketsStore();
const tasksStore = useTasksStore();
const teamsStore = useTeamsStore();
const filesStore = useFilesStore();
const ticketTemplatesStore = useTicketTemplatesStore();
const router = useIonRouter();
const callRefreshToastSuccess = useCallRefreshToastSuccess(fabriqStore.theme);
const callRefreshToastError = useCallRefreshToastError();
const { showClosedTasks } = storeToRefs(fabriqStore);
const { t } = useI18n();
const search = ref("");
const filter = ref("mine");

const tunnel = (action: string) => {
  tracker.tunnel(
    "tickets",
    "tickets page",
    RecordCategory.Tickets,
    ActionType.Process,
    action
  );
};

tracker.end("init");
tracker.end("connexion");

const loading = ref(ticketsStore.collection.length === 0);

const filters = ["mine", "all"];

const today = format(new Date(), "yyyy-MM-dd");

const getMinDueDate = (t: any) => {
  let date = t.due_date;
  t.tasks.forEach((ta: any) => {
    if (ta.done && !showClosedTasks.value.includes(ta.uuid)) return;
    if (!date || ta.due_date < date) {
      date = ta.due_date;
    }
  });
  return date || "Z";
};

const tasksByTicket = computed(() => {
  const map: Map<string, Array<Task>> = new Map();
  tasksStore.collection.forEach((t: Task) => {
    const array = map.get(`${t.ticket}`) || [];
    map.set(`${t.ticket}`, [...array, t]);
  });
  return map;
});

const ticketsWithTasks = computed(() => {
  const userId = fabriqStore.user?.id;
  if (!userId) return [];
  const ticketsWithTasks: any = [];
  (ticketsStore.collection || []).forEach((t: any) => {
    if (t._type !== "issue") return;
    if (!t.is_open) return;
    const ticketTasksId = tasksByTicket.value.get(`${t.id}`) || [];
    const ticketTasksUuid = tasksByTicket.value.get(`${t.uuid}`) || [];
    const ticketTasks = [...ticketTasksId, ...ticketTasksUuid].filter(
      (ta: any) => {
        return (
          (!ta.done || showClosedTasks.value.includes(ta.uuid)) &&
          ta.user === userId
        );
      }
    );
    ticketsWithTasks.push({ ...t, tasks: ticketTasks });
  });
  return ticketsWithTasks;
});

const myTickets = computed(() => {
  const userId = fabriqStore.user?.id;
  if (!userId) return [];
  const searchValue = String(search.value || "").toLocaleLowerCase();
  const myTickets: any = [];
  const all = filter.value === "all";
  (ticketsWithTasks.value || []).forEach((t: any) => {
    const isMine = all
      ? t.owner === userId || t.created_by === userId
      : t.owner === userId;
    const ticketTasks = t.tasks.filter((ta: any) =>
      String(ta.description || "")
        .toLocaleLowerCase()
        .includes(searchValue)
    );
    if (!isMine && !ticketTasks.length) return;
    if (
      !String(t.title || "")
        .toLocaleLowerCase()
        .includes(searchValue) &&
      !ticketTasks.length
    )
      return;
    myTickets.push(t);
  });
  myTickets.sort((a: Ticket, b: Ticket) => {
    const r = getMinDueDate(a).localeCompare(getMinDueDate(b));
    if (r === 0) return a.created_at.localeCompare(b.created_at);
    return r;
  });
  return myTickets;
});

const hasTickets = computed(() => myTickets.value.length > 0);

const overdueTickets = computed(() => {
  if (!myTickets.value?.length) return [];
  return myTickets.value.filter((t: any) => {
    const overdueTask = t.tasks.some(
      (ta: any) => !ta.done && ta.due_date < today
    );
    return overdueTask || t.due_date < today;
  });
});

const todayTickets = computed(() => {
  if (!myTickets.value?.length) return [];
  return myTickets.value.filter((t: any) => {
    const idx = overdueTickets.value.findIndex((tt: any) => tt.id === t.id);
    if (idx >= 0) return;
    const todayTask = t.tasks.some(
      (ta: any) => !ta.done && ta.due_date === today
    );
    return todayTask || t.due_date === today;
  });
});

const upcomingTickets = computed(() => {
  if (!myTickets.value?.length) return [];
  return myTickets.value.filter((t: any) => {
    const idx = overdueTickets.value.findIndex((tt: any) => tt.id === t.id);
    if (idx >= 0) return;
    const idx2 = todayTickets.value.findIndex((tt: any) => tt.id === t.id);
    if (idx2 >= 0) return;
    const upcomingTask = t.tasks.some(
      (ta: any) => !ta.done && (!ta.due_date || ta.due_date > today)
    );
    return upcomingTask || !t.due_date || t.due_date > today;
  });
});

const loadAllData = async (refresher?: RefresherEventDetail) => {
  try {
    if (!refresher) {
      if (ticketsStore.fromPush) return;
      loading.value = true;
      ticketsStore.all({ flush: true });
    } else if (refresher) {
      await Promise.all([
        fabriqStore.loadUserData(teamsStore),
        ticketsStore.all({ flush: true }),
      ]);
      await callRefreshToastSuccess(t("common.refreshedContent"));
      loading.value = false;
    }
    fabriqStore.setTeams(teamsStore.collection.map((t: any) => t.id));
    refresher?.complete();
  } catch (error) {
    console.error(error);
    await callRefreshToastError(t("common.refreshedContentError"));
    loading.value = false;
  }
};

onMounted(() => {
  fabriqStore.setInitializing(false);
});

async function getUserSelectedTeam(
  userTeams: Team[]
): Promise<Team | undefined> {
  const breakpoints = calcModalPercents(44, userTeams.length, 210);
  return new Promise(async (resolve, reject) => {
    const modal = await modalController.create({
      component: TeamInstancePickerModal,
      canDismiss: true,
      mode: "ios",
      breakpoints,
      initialBreakpoint: breakpoints[1],
      componentProps: {
        teams: userTeams.map((t: any) => t.id),
        entityName: "tickets",
        onDone: async (teamId: number | null) => {
          if (teamId === null) return resolve(undefined);
          resolve(userTeams.find((t: any) => t.id === teamId));
          await modal.dismiss();
        },
      },
    });

    // We reject the promise if the modal is dismissed by the user, because we don't want to create a ticket in this
    // case. However, this event is also triggered when the modal is programmatically dismissed, which means it is
    // triggered when the promise is resolved with a valid team. But we don't care because the promise is already
    // resolved and can no longer be rejected.
    modal.onDidDismiss().then(() => reject());

    modal.present();
  });
}

async function getDefaultTeamForTicket(): Promise<Team | undefined> {
  if (!clientStore.hasTmpMobileAssignTeamAtTicketCreation) return;

  const userTeams: Team[] = teamsStore.teamsForCurrentUser;
  if (userTeams.length === 0) return;
  if (userTeams.length === 1) return userTeams[0];
  return getUserSelectedTeam(userTeams);
}

const addTicket = async (ticket_template?: number) => {
  try {
    const team = await getDefaultTeamForTicket();
    const ticket = await addTicketToStore(ticket_template, team);
    gotoTicket(ticket.uuid);
  } catch (error) {
    // when user dismisses the team picker modal, we reject the promise for getDefaultTeamForTicket.
    // this behaviour is expected and normal.
  }
};

const addTicketToStore = async (
  ticket_template?: number,
  userSelectedTeam?: Team
) => {
  const date = formatISO(new Date());
  const userId = fabriqStore.user?.id;
  let ticketToAdd: Ticket = createEmptyTicket(date, userId, ticket_template);
  const template: TicketTemplate | undefined =
    ticketTemplatesStore.collection.find((t: any) => t.id === ticket_template);

  if (template !== undefined) {
    ticketToAdd = addTicketTemplatePresetValues(
      ticketToAdd,
      template,
      fabriqStore.locale || "en"
    );
  }

  if (userSelectedTeam) {
    const mergedTeams = new Set([...ticketToAdd.teams, userSelectedTeam.id]);
    ticketToAdd = { ...ticketToAdd, teams: [...mergedTeams] };
  }

  const ticket = await ticketsStore.add(ticketToAdd);
  if (template?.tasks?.length) {
    const now = new Date();
    let time = 0;
    const tasksDates = new Set<Date>();
    template.tasks.forEach((t: any) => {
      time += t.resolution_time || 0;
      const taskDate = addDays(now, time);
      tasksDates.add(taskDate);
      tasksStore.add({
        ticket: ticket.uuid,
        description: getTextToDisplay(t, fabriqStore.locale),
        user: t.owner_id,
        due_date: t.resolution_time ? format(taskDate, "yyyy-MM-dd") : null,
        order: t.order,
        created_from_template: true,
      });
    });

    if (ticket.teams.length) {
      const ticketTeams: Team[] = teamsStore.collection.filter((team: Team) =>
        ticket.teams.includes(team.id)
      );
      const hasSyncedTasks = ticketTeams.some(
        (team) => team.config?.setAutosyncByDefault
      );
      if (hasSyncedTasks) {
        const minDate = Array.from(tasksDates).sort(
          (a, b) => a.getTime() - b.getTime()
        )[0];
        ticket.due_date = format(minDate, "yyyy-MM-dd");
      }
    }
  }
  return ticket;
};

const takePicture = async () => {
  const file = await Camera.getPhoto({
    quality: 100,
    allowEditing: false,
    resultType: CameraResultType.Uri,
  });
  const ticket = await addTicketToStore();
  const now = formatISO(new Date());
  await filesStore.add({
    media_type: MediaType.File,
    _file: { url: file.webPath, path: file.path },
    ticket: ticket.uuid,
    created_at: now,
    updated_at: now,
  });
  ticketsStore.save();
  gotoTicket(ticket.uuid);
};

const openAddMenu = async () => {
  if (!ticketTemplatesStore.collection.length) {
    return addTicket();
  }
  const breakpoints = calcModalPercents(
    44,
    ticketTemplatesStore.collection.length,
    210
  );
  const modal = await modalController.create({
    component: TicketCreateModal,
    canDismiss: true,
    mode: "ios",
    breakpoints,
    initialBreakpoint: breakpoints[1],
    componentProps: {
      onDone: async (response: { type: string; ticket_template?: number }) => {
        await modal.dismiss();
        switch (response.type) {
          case "ticket":
            tunnel("create from title");
            mixpanelTracker.track(
              "create from title | ticket | tickets screen"
            );
            addTicket(response.ticket_template);
            break;
          case "picture":
            tunnel("create from picture");
            mixpanelTracker.track(
              "create from attachment | ticket | tickets screen"
            );
            takePicture();
            break;
        }
      },
      onCancel: () => {
        modal.dismiss();
      },
    },
  });
  return modal.present();
};

const gotoTicket = (uuid: string) => {
  tracker.begin(
    "ticket",
    "Open ticket from tickets",
    RecordCategory.Tickets,
    ActionType.Process,
    "click"
  );
  mixpanelTracker.track("open | ticket | tickets screen", {
    search: search.value,
  });
  router.navigate(`/tickets/${uuid}`, "forward", "push", ticketPageAnimation);
};

const hideSearch = async (smooth = false) => {
  const content: HTMLIonContentElement | null = document.querySelector(
    ".tickets-page ion-content"
  );
  const searchInput: HTMLIonInputElement | null = document.querySelector(
    ".tickets-page .search-input input"
  );
  if (document.activeElement === searchInput) return;
  if (search.value?.length) return;
  if (!myTickets.value.length) return;
  const threshold = 70;
  setTimeout(() => {
    // @ts-expect-error - scrollEl exists
    if (content?.scrollEl?.scrollTop < threshold) {
      searchInput?.blur();
      content?.scrollToPoint(0, threshold, smooth ? 200 : 0);
    }
  }, 10);
};

watch(filter, (value) => {
  const tab = value === "all" ? "all view tab" : "for me tab";
  tunnel(tab);
  mixpanelTracker.track(`open | ${tab} | tickets screen`);
});

watch(myTickets, () => {
  loading.value = false;
  nextTick(() => {
    hideSearch();
  });
});

onIonViewDidEnter(() => {
  loadAllData();
  hideSearch();
});
</script>

<template>
  <route-page
    class="tickets-page"
    :title="t('ticket', 2)"
    :scrollY="70"
    :transparent="true"
    showPageRefreshButton
    @refresh="loadAllData"
  >
    <template v-slot:toolbar>
      <ion-toolbar>
        <filters-bar
          :filters="filters"
          v-model="filter"
          @click="hideSearch(true)"
        />
      </ion-toolbar>
    </template>
    <div class="search-input" v-if="hasTickets || search.length">
      <font-icon
        name="search"
        material
        size="1"
        color="var(--ion-color-primary-shade)"
      />
      <ion-input
        :placeholder="t(`common.search`)"
        enterkeyhint="search"
        v-model="search"
        @keypress.enter="$event.target.blur()"
      />
    </div>
    <div id="tickets-accordions">
      <loading-block
        v-if="loading && !hasTickets"
        class="tickets-loading-spinner"
      />
      <accordion-item
        :label="t('datetime.overdue')"
        v-if="overdueTickets.length"
      >
        <ticket-list :tickets="overdueTickets" @select="gotoTicket($event)" />
      </accordion-item>
      <accordion-item :label="t('datetime.today')" v-if="todayTickets.length">
        <ticket-list :tickets="todayTickets" @select="gotoTicket($event)" />
      </accordion-item>
      <accordion-item
        :label="t('datetime.upcoming')"
        v-if="upcomingTickets.length"
      >
        <ticket-list :tickets="upcomingTickets" @select="gotoTicket($event)" />
      </accordion-item>
      <div
        v-if="!loading && !hasTickets"
        class="routines-empty ion-margin-horizontal"
      >
        <div class="icon">😌</div>
        <div>{{ t("tickets.noTicket") }}</div>
        <div class="hint">{{ t("tickets.placeholders.noTicket") }}</div>
      </div>
      <br />
    </div>
    <template #fab>
      <div class="fabriq-fab-actions">
        <ion-fab-button @click="openAddMenu" class="fabriq-add-button">
          <font-icon color="#ffffff" name="add" size="1.375" />
        </ion-fab-button>
      </div>
    </template>
  </route-page>
</template>

<style scoped>
.search-input {
  margin: var(--f-padding-m) var(--ion-padding);
}

.tickets-loading-spinner {
  margin-top: calc(var(--ion-padding) * 2 + 70px);
}
.fabriq-fab-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
