import { numberFormats } from "./i18n/numbers";
import { dateTimeFormats } from "./i18n/dates";
import { createI18n } from "vue-i18n";
import fi from "./fi.json";
import fr from "./fr.json";
import el from "./el.json";
import en from "./en.json";
import it from "./it.json";
import pl from "./pl.json";
import de from "./de.json";
import sk from "./sk.json";
import hu from "./hu.json";
import nl from "./nl.json";
import tr from "./tr.json";
import zh from "./zh.json";
import zhHk from "./zh-hk.json";
import sv from "./sv.json";
import ar from "./ar.json";
import cs from "./cs.json";
import hr from "./hr.json";
import ko from "./ko.json";
import ja from "./ja.json";
import pt from "./pt.json";
import es from "./es.json";
import ro from "./ro.json";
import sl from "./sl.json";
import no from "./no.json";
import frontFi from "./i18n/fi.json";
import frontFr from "./i18n/fr.json";
import frontEn from "./i18n/en.json";
import frontIt from "./i18n/it.json";
import frontEl from "./i18n/el.json";
import frontPl from "./i18n/pl.json";
import frontDe from "./i18n/de.json";
import frontSk from "./i18n/sk.json";
import frontHu from "./i18n/hu.json";
import frontNl from "./i18n/nl.json";
import frontTr from "./i18n/tr.json";
import frontZh from "./i18n/zh.json";
import frontAr from "./i18n/ar.json";
import frontSv from "./i18n/sv.json";
import frontZhHk from "./i18n/zh-hk.json";
import frontCs from "./i18n/cs.json";
import frontKo from "./i18n/ko.json";
import frontHr from "./i18n/hr.json";
import frontJa from "./i18n/ja.json";
import frontPt from "./i18n/pt.json";
import frontNo from "./i18n/no.json";
import frontRo from "./i18n/ro.json";
import frontSl from "./i18n/sl.json";
import frontEs from "./i18n/es.json";
import * as dateLocale from "date-fns/locale";
import { useFabriqStore } from "@/store/fabriq";
import { Locale } from "date-fns";
import { FabriqState } from "@/types";
import { SubscriptionCallbackMutation } from "pinia";

export const FALLBACK_LOCALE = "en";
export const SUPPORT_LOCALES = [
  "ar",
  "cs",
  "de",
  "en",
  "el",
  "es",
  "fi",
  "fr",
  "hr",
  "hu",
  "it",
  "ja",
  "ko",
  "no",
  "nl",
  "pl",
  "pt",
  "ro",
  "sk",
  "sl",
  "tr",
  "zh",
  "zh-hk",
  "sv",
];
export const LOCALES_OPTIONS = [
  { value: "en", label: "English" },
  { value: "fi", label: "Finnish" },
  { value: "fr", label: "Français" },
  { value: "it", label: "Italiano" },
  { value: "pl", label: "Polski" },
  { value: "el", label: "Ελληνικά" },
  { value: "de", label: "Deutsch" },
  { value: "sk", label: "Slovenčina" },
  { value: "hu", label: "Magyar" },
  { value: "nl", label: "Nederlands" },
  { value: "tr", label: "Türkçe" },
  { value: "zh", label: "国语" },
  { value: "zh-hk", label: "國語" },
  { value: "ar", label: "العربية" },
  { value: "sv", label: "Svenska" },
  { value: "cs", label: "Čeština" },
  { value: "es", label: "Español" },
  { value: "ja", label: "日本語" },
  { value: "ko", label: "한국어" },
  { value: "no", label: "Norsk" },
  { value: "hr", label: "Hrvatski" },
  { value: "sl", label: "Slovenščina" },
  { value: "ro", label: "Română" },
  { value: "pt", label: "Português" },
];

export function setI18nLanguage(i18n: any, locale: string) {
  if (i18n.mode === "legacy") {
    if (i18n.global.locale === locale) return;
    i18n.global.locale = locale;
  } else {
    if (i18n.global.locale.value === locale) return;
    i18n.global.locale.value = locale;
  }
  document?.querySelector("html")?.setAttribute("lang", locale);
}

export const getDateFnsLocales = (locale: string): Locale => {
  switch (locale) {
    case "fr":
      return dateLocale.fr;
    case "fi":
      return dateLocale.fi;
    case "it":
      return dateLocale.it;
    case "pl":
      return dateLocale.pl;
    case "de":
      return dateLocale.de;
    case "sk":
      return dateLocale.sk;
    case "hu":
      return dateLocale.hu;
    case "nl":
      return dateLocale.nl;
    case "tr":
      return dateLocale.tr;
    case "zh":
      return dateLocale.zhCN;
    case "zh-hk":
      return dateLocale.zhHK;
    case "ar":
      return dateLocale.ar;
    case "sv":
      return dateLocale.sv;
    case "el":
      return dateLocale.el;
    case "es":
      return dateLocale.es;
    case "hr":
      return dateLocale.hr;
    case "sl":
      return dateLocale.sl;
    case "ro":
      return dateLocale.ro;
    case "pt":
      return dateLocale.pt;
    case "ko":
      return dateLocale.ko;
    case "ja":
      return dateLocale.ja;
    case "cs":
      return dateLocale.cs;
    default:
      return dateLocale.enGB;
  }
};

export default (): any => {
  const fabriqStore = useFabriqStore();
  const navLocale = window?.navigator?.language || FALLBACK_LOCALE;
  const twoCharsLocale = navLocale.substring(0, 2);
  const locale = SUPPORT_LOCALES.includes(twoCharsLocale)
    ? twoCharsLocale
    : FALLBACK_LOCALE;
  const i18n = createI18n({
    legacy: false,
    locale,
    fallbackLocale: FALLBACK_LOCALE,
    dateTimeFormats,
    numberFormats,
    messages: {
      en: frontEn,
      fi: frontFi,
      fr: frontFr,
      it: frontIt,
      pl: frontPl,
      de: frontDe,
      el: frontEl,
      sk: frontSk,
      hu: frontHu,
      nl: frontNl,
      tr: frontTr,
      zh: frontZh,
      "zh-hk": frontZhHk,
      ar: frontAr,
      sv: frontSv,
      cs: frontCs,
      hr: frontHr,
      ko: frontKo,
      ja: frontJa,
      pt: frontPt,
      es: frontEs,
      no: frontNo,
      ro: frontRo,
      sl: frontSl,
    },
  });
  i18n.global.mergeLocaleMessage("en", en);
  i18n.global.mergeLocaleMessage("fi", fi);
  i18n.global.mergeLocaleMessage("fr", fr);
  i18n.global.mergeLocaleMessage("it", it);
  i18n.global.mergeLocaleMessage("pl", pl);
  i18n.global.mergeLocaleMessage("de", de);
  i18n.global.mergeLocaleMessage("sk", sk);
  i18n.global.mergeLocaleMessage("hu", hu);
  i18n.global.mergeLocaleMessage("nl", nl);
  i18n.global.mergeLocaleMessage("tr", tr);
  i18n.global.mergeLocaleMessage("zh", zh);
  i18n.global.mergeLocaleMessage("zh-hk", zhHk);
  i18n.global.mergeLocaleMessage("ar", ar);
  i18n.global.mergeLocaleMessage("sv", sv);
  i18n.global.mergeLocaleMessage("el", el);
  i18n.global.mergeLocaleMessage("cs", cs);
  i18n.global.mergeLocaleMessage("hr", hr);
  i18n.global.mergeLocaleMessage("ko", ko);
  i18n.global.mergeLocaleMessage("ja", ja);
  i18n.global.mergeLocaleMessage("pt", pt);
  i18n.global.mergeLocaleMessage("es", es);
  i18n.global.mergeLocaleMessage("ro", ro);
  i18n.global.mergeLocaleMessage("sl", sl);
  i18n.global.mergeLocaleMessage("no", no);
  fabriqStore.$subscribe(
    (
      mutation: SubscriptionCallbackMutation<FabriqState>,
      state: FabriqState
    ) => {
      setI18nLanguage(i18n, state.locale);
    }
  );
  return i18n;
};
