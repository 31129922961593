import mixpanelSdk from "mixpanel-browser";

import config from "@/config";
import { useFabriqStore } from "@/store/fabriq";

class MixpanelTracker {
  #sdk: any;
  #subdomain: string | null = null;

  checkInitialisation() {
    if (this.#sdk) return true;

    const fabriqStore = useFabriqStore();
    const subdomain = fabriqStore.subdomain;
    if (!subdomain) return false;

    this.#subdomain = extractSubdomainFromUrl(subdomain);
    this.#sdk = mixpanelSdk;
    this.#sdk.init(config.mixpanelToken, {
      cross_site_cookie: true,
      api_host: `${subdomain}${config.mixpanelPath}`,
    });

    return true;
  }

  track(...args: any) {
    if (!this.checkInitialisation()) return;
    if (!config.mixpanelToken?.length)
      return console.warn("No track on non production", ...args);
    if (this.#sdk && this.#sdk.track) {
      this.#sdk.track(...args);
      const [message, ...rest] = args;
      if (!message.startsWith("mb |"))
        this.#sdk.track(`mb | ${message}`, ...rest);
    }
  }

  setUser(user: any) {
    if (!this.checkInitialisation()) return;
    if (!config.mixpanelToken?.length)
      return console.warn("No mixpanel on non production");
    if (this.#sdk && this.#sdk.register) {
      this.#sdk.register({
        email: user.email,
        organizationUuid: user.future_organization_id,
        uuid: user.future_user_id,
      });
      this.#sdk.identify(`${user.id}`);
      this.#sdk.people.set({
        application: "mobile",
        subdomain: this.#subdomain,
        djangoId: user.id,
        organizationUuid: user.future_organization_id,
        uuid: user.future_user_id,
        "First name": user.first_name,
        "Last name": user.last_name,
        Email: user.email,
      });
    }
  }

  setClient(client: any) {
    if (!this.checkInitialisation()) return;
    if (!config.mixpanelToken?.length)
      return console.warn("No mixpanel on non production");
    if (this.#sdk && this.#sdk.register) {
      this.#sdk.register({
        clientId: client.id,
        clientName: client.name,
      });
      this.#sdk.people.set({
        "Client ID": client.id,
        "Client Name": client.name,
      });
    }
  }
}

export function extractSubdomainFromUrl(url: string): string | null {
  const parts = new URL(url).hostname.split(".");
  return parts.length > 2 ? parts.slice(0, -2).join(".") : null;
}

const mixpanelTracker = new MixpanelTracker();

export default mixpanelTracker;
