import { toRaw as vueToRaw } from "vue";
import { fabriqDeepClone } from "@/services/fabriqDeepClone";

const toRaw = (obj: any) => fabriqDeepClone(vueToRaw(obj));

export const saveCreated = async (
  api: any,
  hooks: any,
  entity: any,
  request: any
) => {
  if (!api.add) console.warn("NO ADD Method in service");
  if (!api.add) return Promise.resolve(toRaw(entity));
  const added = await api.add(entity, request).then(async (added: any) => {
    if (!hooks?.add) return added;
    const hooked = await hooks.add({ ...added, uuid: entity.uuid });
    return hooked || added;
  });
  return toRaw(added);
};

export const updateEntityInStore = (uuid: string, entity: any, store: any) => {
  const index = store.collection.findIndex((e: any) => e.uuid === uuid);
  if (index < 0) return false;
  const oldEntity = toRaw(store.collection[index]);
  const updatedEntity = { ...oldEntity, ...entity };
  store.collection = [
    ...store.collection.slice(0, index),
    updatedEntity,
    ...store.collection.slice(index + 1),
  ];
  return toRaw(updatedEntity);
};

export const updateIdInStore = (uuid: string, id: number, store: any) => {
  const index = store.collection.findIndex((e: any) => e.uuid === uuid);
  if (index < 0) return false;
  const oldEntity = toRaw(store.collection[index]);
  const updatedEntity = { ...oldEntity, id };
  store.collection = [
    ...store.collection.slice(0, index),
    updatedEntity,
    ...store.collection.slice(index + 1),
  ];
  return toRaw(updatedEntity);
};

export const saveUpdated = async (
  fields: any,
  api: any,
  hooks: any,
  entity: any,
  store: any,
  request: any
) => {
  if (!api.update) console.warn("NO UPDATE Method in service");
  if (!api.update) return Promise.resolve(toRaw(entity));
  const updated = await api
    .update(entity, fields, request)
    .then(async (updated: any) => {
      if (!hooks?.update) return updated;
      const hooked = await hooks.update({ ...updated, uuid: entity.uuid });
      return hooked || updated;
    });
  const updatedEntity = updateEntityInStore(entity.uuid, toRaw(updated), store);
  if (updatedEntity !== false) return toRaw(updatedEntity);
  return toRaw(updated);
};

export const saveDeleted = async (
  api: any,
  hooks: any,
  entity: any,
  request: any
) => {
  if (!api.remove) return;
  await api.remove(entity, request);
  if (!hooks?.remove) return entity;
  const removed = await hooks.remove(entity);
  return removed || entity;
};
