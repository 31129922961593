import { NotSavedYetError } from "@/classes/errors";
import { ApiClient } from "./clients";
import { TicketField, ListService } from "@/types";

export const FieldsService: ListService<TicketField> = {
  async add(entity: TicketField, request): Promise<TicketField> {
    if (typeof entity.ticketId !== "number")
      return Promise.reject(new NotSavedYetError("Ticket not saved yet"));
    await ApiClient.put(
      `/api/v1/ticket/${entity.ticketId}/fields/`,
      {
        type: entity.type,
        value: entity.fieldId,
      },
      request
    );
    return { id: `${entity.ticketId}-${entity.fieldId}`, ...entity };
  },
  async remove(entity: TicketField, request): Promise<void> {
    if (typeof entity.ticketId !== "number")
      return Promise.reject(new NotSavedYetError("Ticket not saved yet"));
    await ApiClient.delete(
      `/api/v1/ticket/${entity.ticketId}/fields/`,
      undefined,
      {
        type: entity.type,
        value: entity.fieldId,
      },
      request
    );
    return;
  },
};
