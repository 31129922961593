<script lang="ts" setup>
import { useI18n } from "@/composables/useI18n";
import { localLogger } from "@/utils/localLogger";

defineProps<{ big?: boolean }>();

const { t } = useI18n();

async function downloadLogFile() {
  await localLogger.downloadLogs();
}
</script>

<template>
  <div class="big-button clickable" @click="downloadLogFile" v-if="big">
    <font-icon
      name="download"
      material
      size="1"
      color="var(--ion-color-warning)"
    />
    <div>{{ t("common.logShort") }}</div>
  </div>
  <button
    type="button"
    class="download-logs-button"
    @click="downloadLogFile"
    v-else
  >
    {{ t("common.logShort") }}
  </button>
</template>

<style scoped></style>
