import { Capacitor } from "@capacitor/core";
import { Dexie as DexieType } from "dexie";
import { ConnectionStatus } from "@capacitor/network";
import { Device } from "@capacitor/device";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";

export class LocalLogger {
  #network: ConnectionStatus | undefined;
  #platform: string;

  #idb: DexieType | undefined;

  constructor() {
    this.#platform = Capacitor.getPlatform();
    this.log(`LocalLogger Initialized on ${this.#platform}`);
  }

  setIndexedDB(idb: DexieType) {
    this.#idb = idb;

    this.#idb
      .table("push")
      .where("createdAt")
      .below(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000))
      .delete();

    this.#idb
      .table("logs")
      .where("createdAt")
      .below(new Date(Date.now() - 24 * 60 * 60 * 1000))
      .delete();
  }

  async logDevice(appVersion: string): Promise<void> {
    const device = await Device.getInfo();
    this.log(`Device Info: ${JSON.stringify(device)}`);
    this.log(`Version Info: ${appVersion}`);
  }

  async log(message: string) {
    const now = new Date();
    const logMessage = `${now.toISOString()} - ${message} ${
      this.#network
        ? `(${
            this.#network.connected
              ? this.#network.connectionType
              : "not connected"
          })`
        : ""
    }\n`;
    console.log("LOCALLOGGER: " + logMessage);
    if (this.#idb) {
      await this.#idb.table("logs").add({
        message,
        createdAt: now,
      });
    }
  }

  async setNetwork(network: ConnectionStatus) {
    this.#network = network;
  }

  async logPush(data: any) {
    const now = new Date();
    if (this.#idb) {
      await this.#idb.table("push").add({
        data: JSON.stringify(data),
        createdAt: now,
      });
    }
  }

  async #getLogs(): Promise<string> {
    if (!this.#idb) {
      return "";
    }
    const performances = await this.#idb.table("logs").orderBy("createdAt");
    const performancesArray = await performances.toArray();
    let str = "";
    performancesArray.forEach((performance) => {
      str += `${performance.createdAt.toISOString()} - ${
        performance.message
      }\n`;
    });
    return str;
  }

  async downloadLogs() {
    if (this.#platform === "web") {
      await this.#downloadForWeb();
    } else {
      await this.#downloadForMobile();
    }
  }

  async #downloadForMobile() {
    const logFileContent = await this.#getLogs();
    await Filesystem.writeFile({
      path: "fabriq.log",
      data: logFileContent,
      directory: Directory.Cache,
      encoding: Encoding.UTF8,
    });
    const { uri } = await Filesystem.getUri({
      path: "fabriq.log",
      directory: Directory.Cache,
    });
    await Share.share({
      title: "Fabriq Log File",
      text: "Here is the log file",
      url: uri,
      dialogTitle: "Share log file",
    });
  }

  async #downloadForWeb() {
    const logFileContent = await this.#getLogs();
    console.log(logFileContent);
    const blob = new Blob([logFileContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "fabriq.log";
    a.click();
    URL.revokeObjectURL(url);
  }
}
