import {
  type DateTime,
  gregorianCalendar,
  medtronicCalendar,
} from "@fabriq/date_time";
import type { CalendarType } from "./formatter";
import { buildDateTimeYearFormatter } from "./year_formatter";
import type { I18n } from "@/composables/useI18n";
import { format as formatDate } from "date-fns";
import { getDateFnsLocales } from "@/i18n";

export interface DateTimeMonthFormatter {
  /**
   * @example
   * Gregorian: "jan"
   * Medtronic: "FM 09 (jan)"
   * Mars: "P 01"
   */
  shortMonth(dateTime: DateTime): string;

  /**
   * @example
   * Gregorian: "jan 2025"
   * Medtronic: "FY 2025 FM 09 (jan)"
   * Mars: "Y 2025 P 01"
   */
  shortMonthLongYear(dateTime: DateTime): string;
  /**
   * @example
   * Gregorian: "january"
   * Medtronic: "FM 09 (january)"
   * Mars: "P 01"
   */
  longMonth(dateTime: DateTime): string;
  /**
   * @example
   * Gregorian: "january 2025"
   * Medtronic: "FY 2025 FM 09 (january)"
   * Mars: "Y 2025 P 01"
   */
  longMonthLongYear(dateTime: DateTime): string;
  /**
   * @example
   * Gregorian: "jan 25" for 2025-01
   * Medtronic: "FY 25 FM 09 (jan)" for 2025-01
   * Mars: "Y 25 P 01" for 2025-01
   */
  shortMonthShortYear(dateTime: DateTime): string;
}
export function buildDateTimeMonthFormatter(
  calendarType: CalendarType,
  i18n: Pick<I18n, "t">,
  locale: string
): DateTimeMonthFormatter {
  const formatYear = buildDateTimeYearFormatter(calendarType, i18n);
  return {
    shortMonth: {
      medtronic: medtronicShortMonth,
      gregorian: gregorianShortMonthOnly,
      mars: marsShortMonth,
    }[calendarType],

    shortMonthLongYear: {
      medtronic: medtronicShortMonthLongYear,
      gregorian: gregorianShortMonthLongYear,
      mars: marsShortMonthLongYear,
    }[calendarType],

    longMonth: {
      medtronic: medtronicLongMonth,
      gregorian: gregorianLongMonth,
      mars: marsShortMonth,
    }[calendarType],

    longMonthLongYear: {
      medtronic: medtronicLongMonthLongYear,
      gregorian: gregorianLongMonthLongYear,
      mars: marsShortMonthLongYear,
    }[calendarType],

    shortMonthShortYear: {
      medtronic: medtronicShortMonthShortYear,
      gregorian: gregorianShortMonthShortYear,
      mars: marsShortMonthShortYear,
    }[calendarType],
  };

  function medtronicShortMonth(dateTime: DateTime) {
    // end of month always matches the gregorian month we are looking for.
    const endOfMonth = medtronicCalendar.endOfInterval(
      dateTime,
      "day",
      "month"
    );
    const date = medtronicCalendar.toDate(endOfMonth);
    const monthNumber = dateTime.month!.toString().padStart(2, "0");
    return `FM ${monthNumber} (${formatDate(date, "MMM", {
      locale: getDateFnsLocales(locale),
    })})`;
  }

  function gregorianShortMonthOnly(dateTime: DateTime) {
    const date = gregorianCalendar.toDate(dateTime);
    return formatDate(date, "MMM", {
      locale: getDateFnsLocales(locale),
    });
  }

  function medtronicShortMonthLongYear(dateTime: DateTime) {
    return `FY ${dateTime.year} ${medtronicShortMonth(dateTime)}`;
  }

  function gregorianShortMonthLongYear(dateTime: DateTime) {
    const date = gregorianCalendar.toDate(dateTime);
    return formatDate(date, "MMM yyyy", {
      locale: getDateFnsLocales(locale),
    });
  }

  function medtronicLongMonth(dateTime: DateTime) {
    // end of month always matches the gregorian month we are looking for.
    const endOfMonth = medtronicCalendar.endOfInterval(
      dateTime,
      "day",
      "month"
    );
    const date = medtronicCalendar.toDate(endOfMonth);
    const monthNumber = dateTime.month?.toString().padStart(2, "0");
    return `FM ${monthNumber} (${formatDate(date, "MMMM", {
      locale: getDateFnsLocales(locale),
    })})`;
  }

  function gregorianLongMonth(dateTime: DateTime) {
    const date = gregorianCalendar.toDate(dateTime);
    return formatDate(date, "MMMM", {
      locale: getDateFnsLocales(locale),
    });
  }

  function medtronicLongMonthLongYear(dateTime: DateTime) {
    return `FY ${dateTime.year} ${medtronicLongMonth(dateTime)}`;
  }

  function gregorianLongMonthLongYear(dateTime: DateTime) {
    const date = gregorianCalendar.toDate(dateTime);
    return formatDate(date, "MMMM yyyy", {
      locale: getDateFnsLocales(locale),
    });
  }

  function medtronicShortMonthShortYear(dateTime: DateTime) {
    return `${formatYear.shortYear(dateTime.year)} ${medtronicShortMonth(
      dateTime
    )}`;
  }

  function gregorianShortMonthShortYear(dateTime: DateTime) {
    const date = gregorianCalendar.toDate(dateTime);
    return formatDate(date, "MMM yy", {
      locale: getDateFnsLocales(locale),
    });
  }

  function marsShortMonth(dateTime: DateTime) {
    return `P ${dateTime.month?.toString().padStart(2, "0")}`;
  }

  function marsShortMonthLongYear(dateTime: DateTime) {
    return `${formatYear.longFullYear(dateTime.year)} ${marsShortMonth(
      dateTime
    )}`;
  }

  function marsShortMonthShortYear(dateTime: DateTime) {
    return `${formatYear.shortYear(dateTime.year)} ${marsShortMonth(dateTime)}`;
  }
}
