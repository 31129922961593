<script lang="ts" setup>
import { Keyboard } from "@capacitor/keyboard";
import { computed, ref } from "vue";
import {
  IonTabs,
  IonPage,
  IonRouterOutlet,
  IonContent,
  IonRow,
  useIonRouter,
} from "@ionic/vue";
import { useFabriqStore } from "@/store/fabriq";
import { storeToRefs } from "pinia";
import { resizeListContainer } from "@/utils/resize-list-container";
import { useNotificationsStore } from "@/store/notifications";
import { useEventTypesStore } from "@/store/eventTypes";
import { addListeners } from "@/utils/notifications";
import { ticketPageAnimation, ticketsPageAnimation } from "@/utils/animations";
import { useClientStore } from "@/store/client";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
type RouteDirection = "back" | "forward" | "none" | "root";

const tab = ref("tickets");
const router = useIonRouter();
const fabriqStore = useFabriqStore();
const clientStore = useClientStore();
const notificationsStore = useNotificationsStore();
const eventTypesStore = useEventTypesStore();
const { user, platform, hideTabs } = storeToRefs(fabriqStore);
const { collection: eventTypes } = storeToRefs(eventTypesStore);
const { hasKpi, config: clientConfig } = storeToRefs(clientStore);
fabriqStore.setLoading(false);

const setNotifications = async () => {
  try {
    addListeners(router);
  } catch (e) {
    console.error(e);
  }
};

const hasKnowledge = computed(() => clientConfig.value.hasKnowledge ?? false);

if (platform.value !== "web") {
  let timer: any = null;
  Keyboard.removeAllListeners();
  Keyboard.addListener("keyboardDidShow", () => {
    if (timer) clearTimeout(timer);
    document.body.classList.add("keyboard");
    timer = setTimeout(() => {
      const el = document.activeElement;
      if (!el) return;
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 200);
  });
  Keyboard.addListener("keyboardDidHide", () => {
    resizeListContainer(".tickets-page");
    resizeListContainer(".routines-page");
    document.body.classList.remove("keyboard");
  });
  setNotifications();
}

const navigateTo = (to: string, direction: RouteDirection) => {
  tab.value = to;
  router.navigate(to, direction || "back", "push", ticketsPageAnimation);
};

const openSearch = async () => {
  router.navigate("/search", "forward", "push", ticketPageAnimation);
};

const openNotifications = async () => {
  tracker.begin(
    "openNotifications",
    "Notifications opening",
    RecordCategory.Notifications,
    ActionType.Ui,
    "click"
  );
  router.navigate("/notifications", "forward", "push", ticketPageAnimation);
};

const useEvents = computed(() => {
  return eventTypes.value.length > 0 && clientConfig.value.useEvents;
});

const hasNotifications = computed(() => notificationsStore.count > 0);

const iconSize = "1.5";
</script>

<template>
  <ion-page>
    <ion-tabs v-if="user">
      <ion-router-outlet
        :swipe-gesture="false"
        class="main-page"
        :tab="tab"
      ></ion-router-outlet>
      <div v-if="!hideTabs" slot="bottom" class="fabriq-nav-tabs">
        <font-icon
          name="assignment"
          class="clickable"
          material
          outlined
          :size="iconSize"
          :color="
            tab === 'tickets'
              ? 'var(--ion-color-secondary)'
              : 'var(--ion-color-primary-shade)'
          "
          data-qa="fabriq-nav-tab"
          data-qa-value="tickets"
          @click="navigateTo('tickets', 'none')"
        />
        <font-icon
          v-if="useEvents"
          name="bullseye"
          class="clickable"
          mdi
          :size="iconSize"
          :color="
            tab === 'events'
              ? 'var(--ion-color-secondary)'
              : 'var(--ion-color-primary-shade)'
          "
          data-qa="fabriq-nav-tab"
          data-qa-value="events"
          @click="navigateTo('events', 'forward')"
        />

        <font-icon
          name="alarm"
          class="clickable"
          material
          :size="iconSize"
          :color="
            tab === 'routines'
              ? 'var(--ion-color-secondary)'
              : 'var(--ion-color-primary-shade)'
          "
          data-qa="fabriq-nav-tab"
          data-qa-value="routines"
          @click="navigateTo('routines', 'forward')"
        />
        <font-icon
          v-if="hasKpi"
          name="bar_chart"
          class="clickable"
          material
          :size="iconSize"
          :color="
            tab === 'indicators'
              ? 'var(--ion-color-secondary)'
              : 'var(--ion-color-primary-shade)'
          "
          data-qa="fabriq-nav-tab"
          data-qa-value="indicators"
          @click="navigateTo('indicators', 'forward')"
        />
        <font-icon
          v-if="hasKnowledge"
          name="menu_book"
          class="clickable"
          material
          :size="iconSize"
          :color="
            tab === 'knowledge'
              ? 'var(--ion-color-secondary)'
              : 'var(--ion-color-primary-shade)'
          "
          data-qa="fabriq-nav-tab"
          data-qa-value="knowledge"
          @click="navigateTo('knowledge', 'forward')"
        />

        <font-icon
          name="search"
          class="clickable"
          material
          :size="iconSize"
          :color="
            tab === 'search'
              ? 'var(--ion-color-secondary)'
              : 'var(--ion-color-primary-shade)'
          "
          data-qa="fabriq-nav-tab"
          data-qa-value="search"
          @click="openSearch"
        />

        <div class="notification-button">
          <font-icon
            name="notifications"
            class="clickable"
            material
            outlined
            :size="iconSize"
            :color="
              tab === 'notifications'
                ? 'var(--ion-color-secondary)'
                : 'var(--ion-color-primary-shade)'
            "
            data-qa="fabriq-nav-tab"
            data-qa-value="notifications"
            @click="openNotifications"
          />
          <div class="badge" v-if="hasNotifications" />
        </div>
      </div>
    </ion-tabs>
    <ion-content v-else>
      <ion-row class="main-loader-wrapper">
        <font-icon
          class="main-loader loading-color"
          spin
          name="spinner"
          color="var(--ion-color-primary-tint)"
          size="0.4"
        />
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<style scoped>
.clickable {
  padding: 1rem 1.5rem;
  margin-inline: -1.5rem;
}
.fabriq-nav-tabs {
  background-color: var(--ion-color-primary-contrast);
  border-top: 1px solid var(--ion-border-color);

  height: 68px;

  padding-bottom: var(--ion-safe-area-bottom);

  box-shadow: 0px -25px 25px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: calc(var(--ion-padding) * 2);
}

.badge {
  --badge-size: 0.5rem;
  width: var(--badge-size);
  height: var(--badge-size);
  background-color: var(--ion-color-warning);
  border-radius: calc(var(--badge-size));
  position: absolute;
  top: calc(50% - 0.9rem);
  right: calc(50% - 0.9rem);
}

.notification-button {
  position: relative;
}
</style>

<style>
.fabriq-nav-tabs {
  --background: var(--ion-color-primary-contrast);

  --padding-top: calc(var(--ion-padding) / 2);
  --padding-bottom: calc(var(--ion-padding) / 2);

  padding-inline: var(--ion-padding);
}

.fabriq-nav-tabs .font-icon {
  margin-bottom: 2px;
}

.fabriq-nav-tabs .tab-selected .font-icon {
  color: var(--ion-color-primary-shade);
}

.tab-label {
  font-size: var(--font-size-s);
}
</style>
