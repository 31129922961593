<script lang="ts" setup>
import { computed } from "vue";

interface Props {
  name: string;
  color?: string;
  material?: boolean;
  mdi?: boolean;
  outlined?: boolean;
  regular?: boolean;
  solid?: boolean;
  brand?: boolean;
  slash?: boolean;
  size?: string;
  spin?: boolean;
  flip?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  color: "var(--ion-color-primary)",
  material: false,
  outlined: false,
  regular: false,
  brand: false,
  slash: false,
  size: "1",
  spin: false,
  flip: false,
});

const iconStyle = computed(() => {
  if (props.mdi)
    return `color: ${props.color}!important; font-size: ${props.size}rem`;
  return `color: ${props.color}!important`;
});

const iconClass = computed(() => {
  if (props.mdi) return `mdi mdi-${props.name} ${props.spin ? " fa-spin" : ""}`;
  if (props.material)
    return props.outlined ? "material-icons-outlined" : "material-icons";
  return `fa${
    props.regular ? "r" : props.solid ? "s" : props.brand ? "b" : ""
  } fa-${props.size}x fa-${props.name}${props.spin ? " fa-spin" : ""}${
    props.flip ? " fa-flip" : ""
  }`;
});
</script>

<template>
  <div
    class="font-icon"
    :class="`font-icon-${size}`"
    :style="`--fa-font-size: ${size}rem; --icon-size: ${size}rem;`"
  >
    <template v-if="name === 'spinner'">
      <span class="loader"></span>
    </template>
    <template v-else>
      <i :class="iconClass" :style="iconStyle">
        {{ material ? name : null }}
      </i>
      <i v-if="slash" :class="`fa fa-slash fa-${size}x`" />
      <i v-if="slash" :class="`fa fa-slash fa-${size}x under`" />
    </template>
  </div>
</template>

<style scoped>
.font-icon {
  position: relative;
}
.material-icons,
.material-icons-outlined {
  font-size: var(--icon-size);
}
.loader {
  --spinner--border-size: calc(v-bind(size) * 2px);
  --spinner--element-size: calc(v-bind(size) * 48px);
  width: var(--spinner--element-size);
  height: var(--spinner--element-size);
  border: var(--spinner--border-size) solid v-bind(color);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
