import * as LiveUpdates from "@capacitor/live-updates";
import { App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { localLogger } from "./localLogger";
import { useFabriqStore } from "@/store/fabriq";

export const getCurrentBuild = async () => {
  const info = await LiveUpdates.sync();
  console.log("getCurrentBuild -> info", info);
  if (info.activeApplicationPathChanged) {
    localStorage.setItem("updateRequired", "true");
  }
  const channel =
    info?.liveUpdate?.channel && info.liveUpdate.channel !== "Production"
      ? `(${info.liveUpdate.channel})`
      : "";
  const build = info?.snapshot?.buildId ?? "local";
  const versionNumber = `${build} ${channel} ${
    localStorage.getItem("updateRequired") === "true" ? "r" : ""
  }`;
  await localLogger.logDevice(versionNumber);
  return versionNumber;
};

export async function needStoreUpdate(version: string) {
  const platform = Capacitor.getPlatform();
  if (platform === "web") {
    return {
      needUpdate: false,
    };
  }
  const appInfo = await CapacitorApp.getInfo();
  await localLogger.log(
    `NeedStoreUpdate: version: ${version}, getInfo(): ${JSON.stringify(
      appInfo,
      null,
      2
    )}`
  );

  if (platform === "ios") {
    const needUpdate = !version.startsWith(appInfo?.version ?? false);
    await localLogger.log(
      `NeedStoreUpdate: platform: ${platform}, needUpdate: ${needUpdate}`
    );
    if (needUpdate) {
      return {
        needUpdate,
        store:
          "https://apps.apple.com/fr/app/fabriq-digital-shop-floor/id1617251199",
      };
    }
  } else {
    const needUpdate = !version
      .replaceAll(".", "")
      .endsWith(appInfo?.build ?? false);
    await localLogger.log(
      `NeedStoreUpdate: platform: ${platform}, needUpdate: ${needUpdate}`
    );
    if (needUpdate) {
      return {
        needUpdate,
        store:
          "https://play.google.com/store/apps/details?id=tech.fabriq.mobile",
      };
    }
  }
  return {
    needUpdate: false,
  };
}

export const reload = async () => {
  await LiveUpdates.reload();
};

export const performManualUpdate = async () => {
  CapacitorApp.addListener("resume", async () => {
    const fabriqStore = useFabriqStore();
    const pageDetailsSuffix = "d";
    if (localStorage.getItem("updateRequired") === "true") {
      if (fabriqStore.page.endsWith(pageDetailsSuffix)) return;
      localStorage.removeItem("updateRequired");
      console.log("Reloading app after manual update");
      await reload();
      return;
    } else {
      const result = await LiveUpdates.sync();

      console.log("performManualUpdate:resume -> info", result);
      if (result.activeApplicationPathChanged) {
        localStorage.setItem("updateRequired", "true");
        return;
      }
    }
  });
  const result = await LiveUpdates.sync();

  console.log("performManualUpdate:firstCall -> info", result);
  if (result.activeApplicationPathChanged)
    localStorage.setItem("updateRequired", "true");
};
