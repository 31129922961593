import { ApiClient } from "./clients";
import {
  DetailedValue,
  Execution,
  ExecutionAnswer,
  ExecutionInfo,
  ListService,
  PinnedAnswer,
} from "@/types";
import { useAnswersStore } from "@/store/answers";
import { useFabriqStore } from "@/store/fabriq";
import { useInstancesStore } from "@/store/instances";
import { NotSavedYetError } from "@/classes/errors";
import { useTicketsStore } from "@/store/tickets";

type Answer = {
  id?: string | number | null;
  value?: string | null;
  choices: number[] | null;
  execution_info?: ExecutionInfo;
  detailed_value?: DetailedValue;
  pinned: PinnedAnswer;
  score: string | undefined;
};

export interface ExecutionListService extends ListService<Execution> {
  create?: (payload: { id: number; start_date: string }) => Promise<Execution>;
}
export const ExecutionService: ExecutionListService = {
  async update(entity: Execution, columns: any): Promise<Execution> {
    const answersStore = useAnswersStore();
    const ticketsStore = useTicketsStore();
    const executionAnswers = answersStore.collection.filter(
      (a: ExecutionAnswer) => a.instance_execution === entity.id
    );
    const answers: Answer[] = executionAnswers.map((a: ExecutionAnswer) => {
      return {
        id: a.id,
        value: a.value,
        choices: a.choices,
        execution_info: a.execution_info,
        ...(a.detailed_value
          ? {
              detailed_value: {
                ...a.detailed_value,
                ...(a.detailed_value?.created_tickets
                  ? {
                      created_tickets: a.detailed_value?.created_tickets?.map(
                        (t: any) => {
                          if (typeof t === "string") {
                            const ticket = ticketsStore.collection.find(
                              (ticket: any) => ticket.uuid === t
                            );
                            return ticket ? ticket.id : t;
                          }
                          return t;
                        }
                      ),
                    }
                  : {}),
              },
            }
          : {}),
        pinned: a.pinned,
        score: a.score,
      };
    });

    if (
      answers.some((a) =>
        (a.detailed_value?.created_tickets ?? ([] as (number | string)[])).some(
          (c) => typeof c === "string"
        )
      )
    ) {
      return Promise.reject(new NotSavedYetError("Ticket not saved yet"));
    }
    const instancesStore = useInstancesStore();

    async function callOrThrowExecutionError(
      callback: () => Promise<any>,
      stepDescription: string
    ) {
      try {
        return await callback();
      } catch (error) {
        const instance = instancesStore.collection.find(
          (i: any) => i.id === entity.instance
        );
        if (instance) {
          if (instance.scheduler) {
            instance.scheduler.is_done = false;
            instance.scheduler.has_started = true;
          }
          instance.sending = false;
          instancesStore.merge([instance]);
        }
        const fabriqStore = useFabriqStore();

        const message = (error as Error)?.message ?? error ?? "Unknown error";
        const executionError = new Error(
          `Error updating execution ${entity.id}. step : ${stepDescription}. error: ${message}`
        );
        fabriqStore.reportSentry(executionError);
        return Promise.reject(executionError);
      }
    }
    await callOrThrowExecutionError(
      async () =>
        await ApiClient.put(`/api/v1/routine/instance/${entity.instance}/`, {
          has_started: true,
        }),
      "setting instance as started"
    );

    await callOrThrowExecutionError(
      async () => await ApiClient.put(`/api/v1/routine/answers/`, answers),
      "updating answers"
    );

    return await callOrThrowExecutionError(async () => {
      let executionUrl = `/api/v1/instance/execution/${entity.id}/`;
      if (columns.ongoing === false) {
        executionUrl = `${executionUrl}?notify_success=true`;
      }
      const execution = await ApiClient.put(executionUrl, { ...columns });
      return { ...entity, ...columns, ...execution };
    }, "updating execution");
  },
  async load(id: number): Promise<Execution | null> {
    const result = await ApiClient.get(`/api/v1/instance/${id}/`, {
      fetch_execution: "1",
    });
    if (!result || !result.execution) return Promise.reject(null);
    return { ...result.execution, updated_at: result.execution.start_date };
  },
  async add(
    payload: {
      instance: number | string;
      start_date: string;
    },
    request
  ): Promise<Execution> {
    const execution = await ApiClient.post(
      `/api/v1/instance/${payload.instance}/execution/`,
      {
        ongoing: true,
        start_date: payload.start_date,
      },
      false,
      request
    );
    return execution;
  },
};
