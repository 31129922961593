<script lang="ts" setup>
import { useFabriqStore } from "@/store/fabriq";
import {
  EventType,
  EventTypeCardinalProperty,
  EventTypeEquipmentProperty,
  EventTypeOrdinalProperty,
  EventTypeProductProperty,
  EventTypePropertyWithMandatory,
  EventTypeZoneProperty,
  FabriqEventPayload,
} from "@/types";
import { localize } from "@/utils/localize";

import { computed, nextTick, ref, toRaw } from "vue";
import FieldPickerModalForEventsProperties from "@/components/modals/FieldPickerModalForEventsProperties.vue";
import EventDateTime from "./EventDateTime.vue";
import TextInput from "@/components/ui/TextInput.vue";
import { useEventFiltersStore } from "@/store/eventFilters";
import { format } from "date-fns";
import { IonInput, IonModal } from "@ionic/vue";
import { fabriqDeepClone } from "@/services/fabriqDeepClone";
import { useFields } from "@/composables/useFields";

interface Props {
  eventTypeProperty: EventTypePropertyWithMandatory;
  eventType: EventType;
  event: FabriqEventPayload & { uuid: string };
  canEdit: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(["updateEvent"]);
const fabriqStore = useFabriqStore();
const eventFiltersStore = useEventFiltersStore();

const { locale } = fabriqStore;
const clickedProperty = ref<boolean>(false);
const isBeingChanged = ref<boolean>(false);

const longTextInputRef = ref();
const textInputRef = ref();

const isLongText = computed<boolean>(() => {
  return props.eventTypeProperty.type === "longText";
});

const isText = computed<boolean>(() => {
  return props.eventTypeProperty.type === "text";
});

const isNumber = computed<boolean>(() => {
  return props.eventTypeProperty.type === "number";
});

const isDateTimeField = computed<boolean>(() => {
  return props.eventTypeProperty.type === "datetime";
});

const canBeFuture = computed(() => {
  if (props.eventTypeProperty.type !== "datetime") {
    return false;
  }
  return props.eventTypeProperty.canBeFuture || false;
});

const propertyName = computed<string>(() => {
  return localize(props.eventTypeProperty.label, locale, 0) as string;
});

const eventPropertyRawValue = computed<string | number | undefined>(() => {
  return (
    props.event.properties.find(
      (ep: { eventPropertyTypeId: string }) =>
        ep.eventPropertyTypeId === props.eventTypeProperty.id
    )?.value ?? undefined
  );
});

const { organizationFields } = useFields();

const eventPropertyValue = computed<{
  label: string | number | undefined;
  color?: string | undefined;
  emoji?: string | undefined;
}>(() => {
  const value = eventPropertyRawValue.value;
  switch (props.eventTypeProperty.type) {
    case "datetime": {
      if (!value) {
        const now = new Date();
        const startDate = format(now, "yyyy-MM-dd") as string;
        const startTime = format(now, "HH:mm") as string;
        updateEvent(`${startDate}T${startTime}`);
        return { label: now.toISOString() };
      }

      return { label: value };
    }
    case "text":
    case "longText":
    case "number":
      return { label: value ?? undefined };
    case "cardinal": {
      const property = props.eventTypeProperty.values.find(
        (v) => v.id === value
      );
      const label = property?.label;
      if (!label) {
        return { label: "" };
      }
      return {
        label: localize(label, locale, 0),
        emoji: property.emoji,
        color: property.color,
      };
    }
    case "ordinal": {
      const property = props.eventTypeProperty.values.find(
        (v) => v.id === value
      );
      const label = property?.label;
      if (!label) {
        return { label: undefined };
      }
      return {
        label: localize(label, locale, 0) as string,
        color: property.color,
      };
    }
    case "zone":
      const zones = eventFiltersStore.filteredZoneFromEventType(
        props.eventType
      );
      if (!value) {
        if (zones.length === 1) {
          updateEvent(zones[0].id);
          return { label: zones[0].name, color: zones[0].color };
        }
      }
      const zone = zones.find((z) => {
        return z.id === Number(value);
      });
      if (!zone) {
        return { label: undefined };
      }
      return { label: zone.name, color: zone.color };
    case "equipment":
    case "product":
      const readableValue = organizationFields.value.get(value as number);
      const label = readableValue?.name
        ? localize(readableValue.name, locale, 0) ?? ""
        : "";
      return { label };
    default:
      return { label: undefined };
  }
});

const truncatedEventPropertyValue = computed<string>(() => {
  if (!eventPropertyValue.value?.label) {
    return "";
  }
  const value = String(eventPropertyValue.value.label);

  return value.length > 30 ? `${value.slice(0, 30)}...` : value;
});
const clickField = () => {
  if (!props.canEdit) return;
  isBeingChanged.value = true;
  nextTick(() => {
    if (isLongText.value) {
      longTextInputRef.value?.editor?.focus();
    } else if (isText.value || isNumber.value) {
      setTimeout(() => {
        textInputRef.value?.$el?.querySelector("input")?.focus();
      }, 10);
    }
  });

  if (
    isDateTimeField.value ||
    isLongText.value ||
    isText.value ||
    isNumber.value
  ) {
    return;
  }
  clickedProperty.value = true;
};

const resetField = () => {
  isBeingChanged.value = false;
  clickedProperty.value = false;
};

const updateEvent = (value: any) => {
  resetField();
  const newProperties = props.event.properties
    .filter((p) => p.eventPropertyTypeId !== props.eventTypeProperty.id)
    .map((p) => fabriqDeepClone(toRaw(p)));

  newProperties.push({
    eventPropertyTypeId: props.eventTypeProperty.id,
    value,
  });
  const filteredNewProperties = newProperties.filter(
    (p) => p.value !== undefined && p.value !== null && p.value !== ""
  );
  emit("updateEvent", filteredNewProperties);
};
</script>

<template>
  <div class="field">
    <div class="field-label">
      <div v-if="eventTypeProperty.required">
        {{ propertyName }} <span class="mandatory-event-type-property">*</span>
      </div>
      <div v-else>{{ propertyName }}</div>
    </div>
    <div
      class="field-values"
      :class="{ clickable: canEdit }"
      @click.stop="clickField"
    >
      <div
        v-if="
          (!eventPropertyValue || !eventPropertyValue.label) && !isBeingChanged
        "
        class="field-empty"
      >
        {{ $t("empty") }}
      </div>

      <div class="description-editor" v-else-if="isText || isNumber">
        <ion-input
          ref="textInputRef"
          class="text-editor text-number-input"
          autofocus
          :inputmode="isText ? 'text' : 'number'"
          :type="isText ? 'text' : 'number'"
          :modelValue="eventPropertyValue.label?.toString() ?? ''"
          :disable="!canEdit"
          @update:modelValue="updateEvent"
          @ion-blur="resetField"
        />
      </div>

      <text-input
        ref="longTextInputRef"
        v-else-if="isLongText"
        class="description-editor"
        :modelValue="eventPropertyValue.label?.toString() ?? ''"
        :readonly="!canEdit"
        @update:modelValue="updateEvent"
      />

      <EventDateTime
        v-else-if="isDateTimeField"
        :date="(eventPropertyValue.label as string)"
        :canBeFuture="canBeFuture"
        :end="undefined"
        :readonly="!canEdit"
        @date="updateEvent"
      />
      <colored-chip
        v-else
        :class="{ 'no-click-events': !canEdit }"
        :color="eventPropertyValue.color"
        :emoji="eventPropertyValue.emoji"
        :label="truncatedEventPropertyValue"
      />
    </div>
  </div>

  <ion-modal
    :is-open="!!clickedProperty"
    mode="ios"
    :breakpoints="[0, 1]"
    :initialBreakpoint="1"
    @ionModalDidDismiss="resetField"
  >
    <FieldPickerModalForEventsProperties
      :eventTypeProperty="(eventTypeProperty as EventTypeCardinalProperty | EventTypeOrdinalProperty | EventTypeZoneProperty | EventTypeProductProperty | EventTypeEquipmentProperty)"
      :eventType="eventType"
      :selectedValue="eventPropertyRawValue ?? ''"
      :label="propertyName"
      @done="resetField"
      @update="updateEvent"
      @remove="updateEvent"
    />
  </ion-modal>
</template>

<style scoped>
.mandatory-event-type-property {
  color: var(--ion-color-danger);
}
.description-editor :deep(.text-input) {
  flex: 1;
  width: 100%;
}
.description-editor :deep(.text-editor) {
  margin: 0;
  padding-block: 6px;
  margin-top: calc((var(--ion-padding) / 2) - 4px);
}
.field-values {
  max-width: calc(100% - 130px - var(--ion-padding));
}
.description-editor {
  width: 100%;
}

.text-editor {
  padding-inline: var(--ion-padding) !important;
  font-size: var(--font-size-m);
}

.text-number-input :deep(.native-input) {
  padding-block: 8px !important;
  padding-inline: 0px !important;
}
.no-click-events {
  pointer-events: none;
}
</style>
