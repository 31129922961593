import { getDateFnsLocales } from "@/i18n";
import { useFabriqStore } from "@/store/fabriq";
import {
  gregorianCalendar,
  medtronicCalendar,
  type DateTime,
} from "@fabriq/date_time";
import { format as formatDate } from "date-fns";
import { useI18n } from "@/composables/useI18n";
import { useIndicatorCalendar } from "./useIndicatorCalendar";
import { useClientIndicatorCalendarType } from "./useClientIndicatorCalendarType";
type DateFormattingOptions = "detailed" | "default";
type FormatOptions = "weeknumber" | "month" | "year" | "date";

export function useFormatDatapointTimeFrame() {
  const calendarType = useClientIndicatorCalendarType();
  const fabriqStore = useFabriqStore();
  const { t } = useI18n();
  const indicatorCalendar = useIndicatorCalendar();

  function formatMedtronicDate(
    date: Date,
    dateTime: DateTime,
    format: FormatOptions,
    dateFormatting: DateFormattingOptions
  ) {
    const { month, year, week } = dateTime;

    switch (format) {
      case "date": {
        return formatDate(date, t("formats.dayOfMonth"), {
          locale: getDateFnsLocales(fabriqStore.locale),
        });
      }

      case "weeknumber": {
        if (dateFormatting === "detailed") {
          const startOfWeekDate = medtronicCalendar.toDate(
            medtronicCalendar.startOfInterval(dateTime, "day", "week")
          );
          return `FY ${year.toString().slice(2, 4)} FW ${String(week).padStart(
            2,
            "0"
          )} (${formatDate(startOfWeekDate, t("formats.dayOfMonth"), {
            locale: getDateFnsLocales(fabriqStore.locale),
          })})`;
        }
        return `FW ${String(week).padStart(2, "0")}`;
      }
      case "month": {
        const endOfMonth = medtronicCalendar.endOfInterval(
          dateTime,
          "day",
          "month"
        );
        const gregorianMonthDate = medtronicCalendar.toDate(endOfMonth);
        switch (dateFormatting) {
          case "detailed": {
            return `FY ${year.toString().slice(2, 4)} FM ${String(
              month
            ).padStart(2, "0")} (${formatDate(gregorianMonthDate, "MMM", {
              locale: getDateFnsLocales(fabriqStore.locale),
            })})`;
          }
          default: {
            return `FM ${String(month).padStart(2, "0")}`;
          }
        }
      }
      case "year": {
        return `FY ${year.toString().slice(2, 4)}`;
      }
      default: {
        throw new Error("format not supported");
      }
    }
  }

  function formatGregorianDate(date: Date, format: FormatOptions) {
    let dateFormat;
    switch (format) {
      case "weeknumber": {
        const startOfWeekDate = gregorianCalendar.toDate(
          gregorianCalendar.startOfInterval(
            gregorianCalendar.fromDate(date),
            "day",
            "week"
          )
        );
        return formatDate(
          startOfWeekDate,
          `'${t("dates.week")}' I yyyy (dd MMM)`,
          {
            locale: getDateFnsLocales(fabriqStore.locale),
          }
        );
      }
      case "month": {
        dateFormat = "MMMM yyyy";
        break;
      }
      case "year": {
        dateFormat = "yyyy";
        break;
      }
      default:
        dateFormat = t("formats.dayOfMonth");
        break;
    }
    return formatDate(date, dateFormat, {
      locale: getDateFnsLocales(fabriqStore.locale),
    });
  }

  function formatMarsDate(
    date: Date,
    dateTime: DateTime,
    format: FormatOptions,
    dateFormatting: DateFormattingOptions
  ) {
    const { month, year, week } = dateTime;

    switch (format) {
      case "date": {
        return formatDate(date, t("formats.dayOfMonth"), {
          locale: getDateFnsLocales(fabriqStore.locale),
        });
      }
      case "weeknumber": {
        if (dateFormatting === "detailed") {
          return `Y ${year.toString().slice(2, 4)} W ${String(week).padStart(
            2,
            "0"
          )}`;
        }
        return `W ${String(week).padStart(2, "0")}`;
      }

      case "month":
        if (dateFormatting === "detailed") {
          return `Y ${year.toString().slice(2, 4)} P ${String(month).padStart(
            2,
            "0"
          )}`;
        }
        return `P ${String(month).padStart(2, "0")}`;

      case "year":
        return `Y ${year.toString().slice(2, 4)}`;

      default: {
        throw new Error("format not supported");
      }
    }
  }

  function formatDatapointTimeFrame(
    dateTime: DateTime | undefined,
    format: FormatOptions,
    dateFormatting: DateFormattingOptions = "default"
  ) {
    if (!dateTime) return "";
    const date = indicatorCalendar.toDate(dateTime);
    switch (calendarType) {
      case "medtronic":
        return formatMedtronicDate(date, dateTime, format, dateFormatting);
      case "mars":
        return formatMarsDate(date, dateTime, format, dateFormatting);
      default:
        return formatGregorianDate(date, format);
    }
  }

  return formatDatapointTimeFrame;
}
