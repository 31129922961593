import { ApiClient } from "./clients";
import { Instance, ListService } from "@/types";

export interface InstanceListService extends ListService<Instance> {
  create?: (entity: Instance) => Promise<Instance>;
}

export const InstanceService: InstanceListService = {
  async all(options?: any): Promise<Array<any>> {
    const params = {
      start_date: options?.start_date,
      end_date: options?.end_date,
      existing_routine_ids: "[]",
    };
    const response = await ApiClient.get(
      `/api/v1/routine/instances/user/${options?.id}/`,
      params
    );
    const result = {
      routines: response.routines,
      schedules: response.schedules,
      instances: response.instances.map((i: any) => ({
        ...i,
        sending: false,
      })),
    };
    return result as any;
  },
  async add(entity: Instance, request): Promise<Instance> {
    const instance = await ApiClient.post(
      `/api/v1/routine/template/${entity.template_id}/instance/`,
      entity,
      false,
      request
    );
    return {
      ...entity,
      ...instance,
      sending: false,
      template_id: +instance.template,
      template: undefined,
    };
  },
  async load(id: number): Promise<Instance> {
    const result = await ApiClient.get(`/api/v1/instance/${id}/`, {
      fetch_execution: "1",
    });
    if (!result || !result.instance) return Promise.reject(null);
    const instance = result.instance;
    return {
      ...instance,
      template_id: instance.template,
      template: undefined,
      sending: false,
    };
  },
  async create(entity: Instance): Promise<Instance> {
    const instance = await ApiClient.post(
      `/api/v1/routine/template/${entity.template_id}/instance/`,
      {
        all_day: entity.all_day,
        end_date: entity.end_date,
        has_started: true,
        instructions: entity.instructions,
        is_done: false,
        is_dst: entity.is_dst,
        schedule: entity.schedule,
        start_date: entity.start_date,
        properties: entity.scheduler?.properties,
        team_id: entity.team_id,
        timezone: entity.timezone,
        timezone_offset: entity.timezone_offset,
        users: entity.users,
      }
    );
    return { ...instance, uuid: entity.uuid };
  },
};
