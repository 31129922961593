import { useClientIndicatorCalendarType } from "@/composables/useClientIndicatorCalendarType";
import { buildDateTimeFormatter } from "@/lib/date_time/formatter";
import { useI18n } from "./useI18n";
import { useFabriqStore } from "@/store/fabriq";

export function useFormatDateTime() {
  const calendarType = useClientIndicatorCalendarType();
  const i18n = useI18n();
  const { locale } = useFabriqStore();
  return buildDateTimeFormatter(calendarType, i18n, locale);
}
