<script lang="ts" setup>
import { computed, onMounted } from "vue";
import { needStoreUpdate } from "@/utils/live-updates";
import { toastController } from "@ionic/vue";
import { Browser } from "@capacitor/browser";
import { useI18n } from "@/composables/useI18n";
import { useFabriqStore } from "@/store/fabriq";
import { storeToRefs } from "pinia";

const props = defineProps<{
  version: string;
  position: string;
}>();

const { t } = useI18n();
const fabriqStore = useFabriqStore();

const appVersionOrStaging = computed(() => {
  return props.version;
});
const { buildVersion } = storeToRefs(fabriqStore);

onMounted(async () => {
  const { needUpdate, store } = await needStoreUpdate(props.version);
  if (needUpdate && store) {
    const toast = await toastController.create({
      color: "warning",
      message: t("common.storeUpdate"),
      duration: 10000,
      buttons: [
        {
          text: "Update",
          handler: () => {
            Browser.open({ url: store });
          },
        },
      ],
    });
    toast.present();
  }
});
</script>
<template>
  <div class="version">
    <span v-if="version">Version {{ appVersionOrStaging }}</span>
    <span v-if="version && buildVersion">-{{ buildVersion }}</span>
  </div>
</template>
<style>
.version {
  --_margin: calc(var(--ion-padding) * 2);
  position: v-bind(position);
  bottom: var(--_margin);
  left: 0;
  right: 0;
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--ion-color-medium);
  padding: var(--ion-padding);
}

.keyboard .version {
  display: none;
}
</style>
