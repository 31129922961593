<script lang="ts" setup>
import { onMounted, Ref, ref, watch } from "vue";
import { IonPage, IonContent, IonInput, IonButton } from "@ionic/vue";
import { useFabriqStore } from "@/store/fabriq";
import { useI18n } from "@/composables/useI18n";
import storage from "@/utils/storage";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { AuthType } from "@/types";
import validator from "validator";
import { log } from "@/utils/notifications";
import LoadingDiv from "@/components/ui/LoadingDiv.vue";
import AppVersion from "@/components/ui/AppVersion.vue";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { getFutureAuthError } from "@/futureAuth/futureAuthErrors";
import { autoLogin, initializeApp } from "@/utils/app";
import { useQueryParameters } from "@/composables/useQueryParameters";
import { AuthClient } from "@/services/auth";
import { urlOpener } from "@/utils/aws-url-opener";
import { localLogger } from "@/utils/localLogger";
import DownloadLogsButton from "@/components/tools/DownloadLogsButton.vue";

const fabriqStore = useFabriqStore();
const emailChecked = ref(false);
const futureAuthError: Ref<null | string> = ref(null);
const email = ref("");
const password = ref("");
const type = ref("");
const organizationAuthenticationConfig: Ref<any> = ref(null);
const page = ref();
const checking = ref(false);
const router = useRouter();
const ssoEmail: Ref<string | null> = ref(null);
const ssoUrl: Ref<string | null> = ref(localStorage.getItem("sso-url"));
const wrongEmail = ref(true);
const wrongCredentials = ref(false);

const { initializing, loading, connected, version } = storeToRefs(fabriqStore);

const login = async () => {
  try {
    if (wrongEmail.value) return (wrongCredentials.value = true);
    performLogin();
  } catch (e) {
    log(e);
    console.error(e);
    fabriqStore.reportSentry(e as Error);
  }
};

const setSSOEmail = async (email: string) => {
  const typeCheck = await fabriqStore.checkEmail(email);
  organizationAuthenticationConfig.value =
    typeCheck?.organizationAuthenticationConfig;
  ssoEmail.value = email;
  checking.value = false;
  await AuthClient.logoutFuture();
  ssoUrl.value = localStorage.getItem("sso-url");
};

const openSSOUrl = () => {
  localLogger.log(`openSSOUrl ${ssoUrl.value}`);
  urlOpener(ssoUrl.value || "");
};

onMounted(async () => {
  const result = await autoLogin(router);
  if (result?.error) {
    try {
      await setSSOEmail(result.error);
    } catch (e) {
      fabriqStore.reportSentry(e as Error);
    }
    await storage.clear();
  } else {
    email.value = result?.email;
    password.value = result?.password;
    type.value = result?.type;
    emailChecked.value = result?.emailChecked || false;
  }

  const params = useQueryParameters();
  // @ts-expect-error
  futureAuthError.value = params.error ? "common.wrongCredentials" : null;
});

const checkEmail = async () => {
  await localLogger.log(`checkEmail ${email.value}`);
  if (!email.value || !email.value.length || !validator.isEmail(email.value)) {
    wrongEmail.value = true;
    return;
  }
  const vEmail = await storage.get("email");
  if (vEmail !== email.value) {
    await storage.clear();
  }
  tracker.track("signin", "User sign in", RecordCategory.General);
  checking.value = true;
  try {
    const typeCheck = await fabriqStore.checkEmail(email.value);
    await localLogger.log(`checkEmail typeCheck ${JSON.stringify(typeCheck)}`);
    organizationAuthenticationConfig.value =
      typeCheck?.organizationAuthenticationConfig;
    if (typeCheck.error) {
      await setSSOEmail(typeCheck.error);
      return;
    }
    if (!typeCheck) return;
    checking.value = false;
    switch (typeCheck.type) {
      case AuthType.Future:
        futureAuthError.value = getFutureAuthError(
          typeCheck.futureIdpStatus,
          email.value,
          organizationAuthenticationConfig.value
        );
        if (futureAuthError.value) return;
        type.value = typeCheck.type;
        await localLogger.log(`checkEmail futureAuth OK`);
        if (typeCheck.futureIdpStatus === "external") {
          password.value = "futureAuth";
          storage.set("email", email.value);
          storage.set("password", password.value);
          storage.set("type", type.value);
          checking.value = true;
          emailChecked.value = false;
        } else {
          emailChecked.value = true;
        }
        break;
      case AuthType.Native:
      case AuthType.Legacy:
        type.value = typeCheck.type;
        emailChecked.value = true;
        break;
      case AuthType.External:
      case AuthType.Migrated:
        type.value = typeCheck.type;
        password.value = typeCheck.providerName || "";
        storage.set("password", password.value);
        performLogin();
        break;
      default:
        wrongEmail.value = true;
        emailChecked.value = true;
    }
  } catch (e) {
    log(e);
    console.error(e);
    await localLogger.log(`checkEmail error ${e}`);
    checking.value = false;
    fabriqStore.endRequest();
  }
};

const performLogin = async () => {
  if (
    !email.value ||
    !email.value.length ||
    !type.value ||
    !type.value.length
  ) {
    fabriqStore.setInitializing(false);
    fabriqStore.setLoading(false);
    tracker.end("init");
    return;
  }
  try {
    tracker.begin(
      "connexion",
      "User connexion",
      RecordCategory.General,
      ActionType.Process
    );
    await storage.set("email", email.value);
    fabriqStore.setInitializing(true);
    await fabriqStore.login(email.value, password.value, type.value);
    if (type.value === AuthType.Native || type.value === AuthType.Future)
      await initializeApp(true);
    storage.set("password", password.value);
    storage.set("type", type.value);
  } catch (e) {
    log(e);
    fabriqStore.setInitializing(false);
    fabriqStore.setLoading(false);
    wrongCredentials.value = true;
    console.error(e);
    fabriqStore.reportSentry(e as Error);
  }
};

watch(email, () => {
  emailChecked.value = false;
  wrongEmail.value = false;
  checking.value = false;
  wrongCredentials.value = false;
});

watch(password, (val, old) => {
  if (val === old) return;
  wrongCredentials.value = false;
});

const { t } = useI18n();
</script>
<template>
  <ion-page :ref="page">
    <ion-content :fullscreen="true" class="normal">
      <div class="login-block" v-if="!initializing && !loading && !connected">
        <div class="fabriq-logo"></div>
        <div class="fabriq-input-wrapper">
          <ion-input
            autofocus
            class="fabriq-input"
            inputmode="email"
            pattern="email"
            enterkeyhint="next"
            v-model="email"
            @keypress.enter="checkEmail"
            type="email"
            name="email"
            required
            :placeholder="t('common.email')"
          />
        </div>
        <div v-if="emailChecked" class="fabriq-input-wrapper">
          <ion-input
            v-model="password"
            class="fabriq-input"
            enterkeyhint="go"
            @keypress.enter="login"
            autofocus
            type="password"
            pattern="password"
            name="password"
            required
            :placeholder="t('common.password')"
          />
        </div>
        <div class="hint danger" v-if="futureAuthError">
          {{ t(futureAuthError) }}
        </div>
        <div class="hint danger" v-if="wrongCredentials">
          {{ t("common.wrongCredentials") }}
        </div>
        <div class="hint danger" v-if="ssoEmail">
          {{
            t("common.ssoEmail", {
              email: ssoEmail,
              idp: organizationAuthenticationConfig.ssoLabel,
            })
          }}
        </div>
        <ion-button
          class="ion-margin-top"
          color="secondary"
          expand="block"
          v-if="emailChecked && !ssoEmail"
          @click="login"
        >
          {{ t("login.signIn") }}
        </ion-button>
        <ion-button
          class="ion-margin-top"
          expand="block"
          color="secondary"
          :disabled="wrongEmail || checking"
          v-else-if="!ssoEmail"
          @click="checkEmail"
        >
          <font-icon
            v-if="checking"
            spin
            name="spinner"
            color="white"
            size="0.4"
          />
          <span v-else>{{ t("login.checkEmail") }}</span>
        </ion-button>
        <ion-button
          class="ion-margin-top"
          color="secondary"
          expand="block"
          v-if="ssoEmail && ssoUrl"
          @click="openSSOUrl"
        >
          {{
            t("common.ssoLogout", {
              idp: organizationAuthenticationConfig.ssoLabel,
            })
          }}
        </ion-button>
      </div>
      <loading-div v-else />
      <app-version :version="version" position="absolute" />
    </ion-content>
    <download-logs-button />
  </ion-page>
</template>

<style scoped>
.login-block {
  position: relative;
  margin: auto;
  margin-top: 10rem;
  width: calc(100% - 84px);
  text-align: center;
}

.loading-block {
  position: relative;
  margin: auto;
  width: calc(100% - 84px);
  height: calc(100% - var(--offset-top) - var(--offset-bottom));
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-block .fabriq-logo {
  margin-bottom: calc(var(--ion-padding) * 4);
}

.fabriq-input-wrapper {
  text-align: left;
  font-size: var(--font-size-m);
  padding: calc(var(--ion-padding) / 3 - 2px) var(--ion-padding);
  background-color: var(--ion-color-primary-contrast);
  border: 1px solid var(--ion-border-color);
  border-radius: var(--f-border-radius);
  color: var(--ion-color-primary);
  margin-bottom: var(--ion-padding);
  margin-inline: 2px;
}

.fabriq-input-wrapper::part(native) {
  --inner-border-width: 0;
}

ion-button {
  font-size: var(--font-size-m);
  height: 2.25rem;
}

ion-button::part(native) {
  border-radius: 5px;
}

.fabriq-input {
  border: 0;
}
</style>
