<script setup lang="ts">
import { Field, RoutineScope } from "@/types";
import { computed } from "vue";
import { useUsers } from "@/composables/useUsers";
import { useClientStore } from "@/store/client";

interface Props {
  scope: RoutineScope;
}

const props = defineProps<Props>();

const { users: allUsers } = useUsers();

const clientStore = useClientStore();

function getUser(userId: number | null) {
  if (!userId) return undefined;
  return allUsers.value.find((user) => user.id == userId);
}

const auditeeName = computed(() => {
  const auditee = getUser(props.scope.auditee);
  if (!auditee) return undefined;
  return `${auditee.first_name} ${auditee.last_name}`;
});

function getFields(ids: number[]) {
  return clientStore.allFields
    .filter((field: Field) => ids.includes(field.id as number))
    .map((field) => field.name)
    .join(", ");
}
</script>

<template>
  <div class="instance-scope">
    <div v-if="auditeeName" class="instance-property">
      <font-icon
        name="person_search"
        material
        color="var(--ion-color-primary-shade)"
        size="0.875"
      />
      <span class="instance-property__label">{{ auditeeName }}</span>
    </div>
    <div v-if="scope.zone.length > 0" class="instance-property">
      <font-icon
        name="location_on"
        material
        color="var(--ion-color-primary-shade)"
        size="0.875"
      />
      <span class="instance-property__label">{{ getFields(scope.zone) }}</span>
    </div>
    <div v-if="scope.product.length > 0" class="instance-property">
      <font-icon
        name="inventory"
        material
        color="var(--ion-color-primary-shade)"
        size="0.875"
      />
      <span class="instance-property__label">{{
        getFields(scope.product)
      }}</span>
    </div>
    <div v-if="scope.equipment.length > 0" class="instance-property">
      <font-icon
        name="precision_manufacturing"
        material
        color="var(--ion-color-primary-shade)"
        size="0.875"
      />
      <span class="instance-property__label">{{
        getFields(scope.equipment)
      }}</span>
    </div>
  </div>
</template>

<style scoped>
.instance-scope {
  display: flex;
  align-items: center;
  gap: 4px 8px;
  flex-wrap: wrap;
  margin-right: var(--ion-padding);
}

.instance-property {
  display: flex;
  align-items: center;
  gap: 4px;
}

.instance-property__label {
  word-break: break-word;
  white-space: break-spaces;
  color: var(--ion-color-primary-shade);
}
</style>
