import { ListState, ListService, ListEntity } from "@/types";
import { defineStore, StoreDefinition } from "pinia";
import storage from "@/utils/storage";
import { v4 as uuidv4 } from "uuid";
import { useFabriqStore } from "./fabriq";
import { Network, ConnectionStatus, ConnectionType } from "@capacitor/network";
import { toRaw } from "vue";
import { App } from "@capacitor/app";
import { fabriqDeepClone } from "@/services/fabriqDeepClone";

export function createListStore<Type extends ListEntity>(
  name: string,
  api: ListService<Type>,
  hooks?: any,
  state?: any,
  actions?: any,
  forceUpdate?: boolean,
  getters?: any,
  errorHandlers?: {
    onCreatedError?: (
      e: Error,
      options: {
        entity: Type;
        store: {
          remove(entity: Type, noRequest?: boolean): Promise<void>;
        };
      }
    ) => Promise<void>;
    onDeletedError?: (
      e: Error,
      options: {
        entity: Type;
        store: {
          merge(entities: Type[], flush?: boolean): Promise<void>;
        };
      }
    ) => Promise<void>;
  }
): StoreDefinition {
  storage.createTable(name);
  return defineStore(name, {
    state: (): ListState<Type> => {
      return {
        collection: [],
        deleted: [],
        last: null,
        selectedUuid: null,
        selectedIndex: null,
        selected: null,
        selectedDetail: null,
        initialized: false,
        saving: false,
        loaded: false,
        loading: false,
        ...(state || {}),
      };
    },
    getters: {
      ...(getters || {}),
    },
    actions: {
      async initialize() {
        const entities = await storage.getAll(name, null);
        this.collection = entities;
        this.setLast();
        this.initialized = true;
        const connections: Array<ConnectionType> = ["wifi", "cellular"];
        Network.addListener(
          "networkStatusChange",
          (status: ConnectionStatus) => {
            if (
              status.connected &&
              connections.includes(status.connectionType)
            ) {
              this.save();
            }
          }
        );
        App.addListener("appStateChange", () => {
          this.save();
        });
        storage.addMessageListener(async (ev: any) => {
          const data = ev.data;
          if (data.name !== name) return;
          this.loading = false;
          const updated = ev.data.updated || [];
          if (this.collection.length) {
            let collection = [...this.collection];
            updated.forEach((u: Type) => {
              const idx = this.collection.findIndex(
                (e: Type) => e.uuid === u.uuid || e.id === u.id
              );
              if (idx < 0) {
                collection.push(u);
              } else {
                collection = [
                  ...collection.slice(0, idx),
                  u,
                  ...collection.slice(idx + 1),
                ];
              }
            });
            const removed = ev.data.removed;
            removed.forEach((u: Type) => {
              const idx = this.collection.findIndex(
                (e: Type) => e.uuid === u.uuid || e.id === u.id
              );
              if (idx < 0) return;
              collection = [
                ...collection.slice(0, idx),
                ...collection.slice(idx + 1),
              ];
            });
            this.collection = collection;
          } else {
            this.collection = updated;
          }
          this.last = data.last || null;
        });
        this.save();
      },
      merge(entities: any, flush = false) {
        storage.postMessage({
          name,
          entities: fabriqDeepClone(toRaw(entities)),
          flush,
          forceUpdate,
          last: !forceUpdate ? this.last : null,
        });
      },
      setLast() {
        let last = "1970-01-01";
        this.collection.forEach((c: any) => {
          if (c.updated_at > last) last = c.updated_at;
        });
        this.last = last === "1970-01-01" ? null : last;
      },
      async all(options?: any) {
        if (!api.all) return;
        this.loading = true;
        try {
          const params = this.last
            ? { ...(options || {}), last: this.last || undefined }
            : options;
          const entities = await api.all(params);
          if (hooks && hooks.all) {
            const hookedEntities = await hooks.all(params, entities);
            this.merge(hookedEntities, params?.flush);
          } else {
            this.merge(entities, params?.flush);
          }
        } catch (e) {
          this.loading = false;
          return Promise.reject(e);
        }
      },
      async load(id: number, exitIfExists = false, otherParams: any) {
        const inCollectionEntity = this.collection.find(
          (e: any) => e.id === id
        );
        if (exitIfExists && inCollectionEntity)
          return Promise.resolve(inCollectionEntity);
        if (!api.load) return Promise.resolve(inCollectionEntity);
        this.loading = true;
        const entity: any = await api.load(id, otherParams);
        if (!entity) {
          this.loading = false;
          return;
        }
        if (hooks && hooks.load) {
          const hookedEntity = await hooks.load({
            ...entity,
            uuid: inCollectionEntity ? inCollectionEntity.uuid : uuidv4(),
          });
          if (!hookedEntity) return entity;
          if (!inCollectionEntity) this.collection.push(hookedEntity);
          this.merge([hookedEntity]);
          this.loading = false;
          return hookedEntity;
        } else {
          if (!inCollectionEntity) this.collection.push(entity);
          this.merge([entity]);
          this.loading = false;
          return entity;
        }
      },
      async add(entity: any, noRequest = false) {
        const uuid = uuidv4();
        const toAdd = !entity.uuid ? { ...entity, uuid } : entity;
        await storage.insertEntity(
          name,
          fabriqDeepClone(toRaw(toAdd)),
          noRequest
        );
        this.collection = [...this.collection, toAdd];
        return toAdd;
      },
      async update(uuid: string, fields: any, noRequest = false) {
        const index = this.collection.findIndex((e: any) => e.uuid === uuid);
        if (index < 0) return;
        const oldEntity = this.collection[index];
        const updatedEntity = fabriqDeepClone(
          toRaw({ ...oldEntity, ...fields })
        );
        this.collection = [
          ...this.collection.slice(0, index),
          updatedEntity,
          ...this.collection.slice(index + 1),
        ];
        if (this.selectedUuid === uuid) this.select(uuid);
        storage.updateEntity(
          name,
          updatedEntity,
          Object.keys(fields),
          noRequest
        );
      },
      async remove(entity: any, noRequest = false) {
        const index = this.collection.findIndex(
          (e: any) => e.uuid === entity.uuid
        );
        if (index < 0) return console.error("No item found");
        storage.removeEntity(name, fabriqDeepClone(toRaw(entity)), noRequest);
        const originalEntity = fabriqDeepClone(toRaw(this.collection[index]));
        this.collection = [
          ...this.collection.slice(0, index),
          ...this.collection.slice(index + 1),
        ];
        this.deleted.push(originalEntity);
        if (this.selectedUuid === entity.uuid) {
          this.select(null);
        }
      },
      select(uuid: string | string | null) {
        if (uuid === null) {
          this.selectedIndex = null;
          this.selectedUuid = null;
          this.selectedDetail = null;
        }
        const index = this.collection.findIndex(
          (entity: any) => entity.uuid === uuid
        );
        if (index < 0) return;
        this.selected = { ...this.collection[index] };
        this.selectedIndex = index;
        this.selectedUuid = this.selected.id;
        return uuid;
      },
      async save() {
        const fabriqStore = useFabriqStore();
        if (!fabriqStore.online || this.saving) return;
        this.saving = true;
        try {
          await storage.performRequests(
            name,
            this,
            api,
            hooks,
            errorHandlers ?? {}
          );
        } catch (e) {
          fabriqStore.reportSentry(e as Error);
        }
        this.saving = false;
      },
      ...(actions || {}),
    },
  });
}
