import { HttpOptions, HttpHeaders } from "@capacitor/core";
import { RequestMethod, Token, AuthType } from "@/types";
import { isBefore, subMinutes } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import { AuthClient } from "@/services/auth";

import axios from "axios";
import { localLogger } from "@/utils/localLogger";

export class OfflineException extends Error {
  constructor() {
    super();
    this.name = "OfflineException";
  }
}

export class ApiFactory {
  #baseUrl: string;
  #subdomain: string | null = null;
  #token: Token | null;
  #fabriqStore: any;
  #online = false;
  #attempt = 0;
  static #refreshPromise: Promise<void> | null = null;
  #reconnectPromise: Promise<any> | null = null;
  #processingRequests: Map<string, AbortController> = new Map<
    string,
    AbortController
  >();

  #offlineRequests: Array<() => void> = [];

  static get #POST_CONTENT_TYPE(): string {
    return "application/json";
  }

  static get #FORCE_REFRESH_LIMIT(): number {
    return 3;
  }

  static get #MAX_ATTEMPT(): number {
    return 1;
  }

  constructor(baseUrl: string) {
    this.#baseUrl = baseUrl;
    this.#token = null;
  }

  getFabriqStore() {
    return this.#fabriqStore;
  }

  async setOnline(online: boolean) {
    if (online === this.#online) return;
    this.#online = online;
    if (this.#online) this.#runOfflineRequest();
    else this.#abortRequests();
  }

  #abortRequests() {
    for (const request of this.#processingRequests.values()) {
      request.abort();
    }
  }

  async checkToken(fabriqStore: any) {
    this.#fabriqStore = fabriqStore;
    if (this.#token) {
      try {
        await this.#checkTokenAndStart().finally(() => {
          this.#fabriqStore.endRequest();
        });
      } catch (e) {
        await AuthClient.reLogin()
          .then((token) => {
            this.#fabriqStore.setToken(token);
            this.setToken(token);
          })
          .catch((e) => {
            console.warn(e);
            this.#fabriqStore.logout();
            this.#fabriqStore.endRequest();
          });
      }
    }
  }

  #checkTokenAndStart(): Promise<void> {
    if (!this.#token) return Promise.resolve();
    const limit = subMinutes(new Date(), ApiFactory.#FORCE_REFRESH_LIMIT);
    if (isBefore(this.#token.expiresAt, limit)) {
      return this.refreshToken();
    }
    return Promise.resolve();
  }

  resetAttempts() {
    this.#attempt = 0;
  }

  async refreshToken(): Promise<void> {
    if (ApiFactory.#refreshPromise) return ApiFactory.#refreshPromise;

    ApiFactory.#refreshPromise = new Promise(async (resolve, reject) => {
      if (!this.#token) return reject();
      switch (this.#token.type) {
        case AuthType.Future:
          try {
            const token = await AuthClient.futureAuthRefreshToken();
            if (!token) throw new Error("Refresh token error");
            this.#token = token;
            this.#fabriqStore.setToken(this.#token);
            ApiFactory.#refreshPromise = null;
            resolve();
          } catch (e) {
            await this.#fabriqStore.logout();
            ApiFactory.#refreshPromise = null;
            reject("Refresh token error");
          }
          break;
        case AuthType.Native:
        case AuthType.External:
        case AuthType.Migrated:
        default:
          await localLogger.log(`Deprecated AuthType ${this.#token.type}`);
          reject("AuthType not managed yet");
      }
    });
  }

  buildServiceUrl(serviceUrl: string) {
    if (!this.#subdomain) return null;
    return `${this.#subdomain}${serviceUrl}`;
  }

  applySubDomainToBaseUrl(subdomain: string, serviceUrl: string) {
    this.#subdomain = subdomain;
    this.#baseUrl = this.buildServiceUrl(serviceUrl) || this.#baseUrl;
  }

  #getUrl(url: string): string {
    return `${this.#baseUrl}${url}`;
  }

  #getOfflineMobileRequestHeader(requestUuid: string): HttpHeaders {
    return {
      "x-mobile-request-uuid": requestUuid,
    };
  }

  getHeaders(headers?: HttpHeaders): HttpHeaders {
    if (!this.#token) return headers || {};
    //
    const version = `${this.#fabriqStore.version}${
      this.#fabriqStore.buildVersion ? `-${this.#fabriqStore.buildVersion}` : ""
    }`;
    return {
      ...(headers || {}),
      Authorization:
        this.#token.type === AuthType.Future
          ? `BearerFuture ${this.#token.accessToken}`
          : `Bearer ${this.#token.accessToken}`,
      "x-mobile-app-version": version,
    };
  }

  async getFreshToken() {
    await this.#checkTokenAndStart();
    return this.#token;
  }

  setToken(token: Token | null) {
    this.#token = token;
  }

  runOfflineRequests() {
    while (this.#online && this.#offlineRequests.length) {
      this.#runOfflineRequest();
    }
  }

  fetch(url: string, options?: any) {
    const requestInit: RequestInit = {
      method: "GET",
      ...(options || {}),
      headers: {
        ...this.getHeaders(options?.headers),
        "Content-Type": "application/json",
      },
    };
    return fetch(url, requestInit).then((res) => res.json());
  }

  #runOfflineRequest() {
    const request = this.#offlineRequests.shift();
    if (!request) return;
    return request();
  }

  #addOffline(method: RequestMethod, url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const executor = async () => {
        try {
          if (method === RequestMethod.Post) {
            const result = await this.post(url, params);
            return resolve(result);
          }
          if (method === RequestMethod.Patch) {
            const result = await this.patch(url, params);
            return resolve(result);
          }
          if (method === RequestMethod.Delete) {
            const result = await this.delete(url, params);
            return resolve(result);
          }
          const result = await this.get(url, params);
          resolve(result);
        } catch (e) {
          reject(e);
        }
      };
      this.#offlineRequests.push(executor);
    });
  }

  sendFile(url: string, params: any, request?: { requestUuid: string }) {
    const offlineHeaders = request?.requestUuid
      ? this.#getOfflineMobileRequestHeader(request.requestUuid)
      : {};

    return axios
      .post(this.#getUrl(url), params, {
        headers: this.getHeaders(offlineHeaders),
      })
      .then((r: any) => r.data);
  }

  getRequestOptions(url: string) {
    const requestOptions: HttpOptions = {
      url,
      method: "GET",
      headers: this.getHeaders(),
    };
    return requestOptions;
  }

  async get(url: string, params?: any, notBased = false): Promise<any> {
    console.log("GET Request for", url);
    if (!this.#online) {
      return this.#addOffline(RequestMethod.Get, url, params);
    }
    await this.#checkTokenAndStart();
    const requestOptions: HttpOptions = {
      url: notBased ? url : this.#getUrl(url),
      method: "GET",
      headers: notBased ? undefined : this.getHeaders(),
      params,
    };
    return this.sendRequest(requestOptions);
  }

  async getAxios(url: string, params?: any, notBased = false): Promise<any> {
    if (!this.#online) {
      return this.#addOffline(RequestMethod.Get, url, params);
    }
    await this.#checkTokenAndStart();
    const requestOptions: HttpOptions = {
      url: notBased ? url : this.#getUrl(url),
      method: "GET",
      headers: notBased ? undefined : this.getHeaders(),
      params,
    };
    return this.sendAxiosRequest(requestOptions);
  }

  async post(
    url: string,
    data?: any,
    capacitor?: boolean,
    request?: { requestUuid: string }
  ): Promise<any> {
    console.log("POST Request for", url);
    if (!this.#online) {
      if (!request) {
        return this.#addOffline(RequestMethod.Post, url, data);
      } else {
        // The request is coming from FabriqIdbStorage
        throw new OfflineException();
      }
    }
    await this.#checkTokenAndStart();
    let headers: HttpHeaders = {
      "Content-Type": ApiFactory.#POST_CONTENT_TYPE,
    };
    if (request?.requestUuid) {
      const offlineHeaders = this.#getOfflineMobileRequestHeader(
        request.requestUuid
      );
      headers = { ...headers, ...offlineHeaders };
    }

    const requestOptions: HttpOptions = {
      url: this.#getUrl(url),
      method: "POST",
      headers: this.getHeaders(headers),
      data,
    };
    if (capacitor) return this.sendRequest(requestOptions);
    return this.sendAxiosRequest(requestOptions);
  }

  async put(
    url: string,
    data?: any,
    request?: { requestUuid: string }
  ): Promise<any> {
    console.log("PUT Request for", url);
    if (!this.#online) {
      if (!request) {
        return this.#addOffline(RequestMethod.Put, url, data);
      } else {
        // The request is coming from FabriqIdbStorage
        throw new OfflineException();
      }
    }
    await this.#checkTokenAndStart();
    let headers: HttpHeaders = {
      "Content-Type": ApiFactory.#POST_CONTENT_TYPE,
    };
    if (request?.requestUuid) {
      const offlineHeaders = this.#getOfflineMobileRequestHeader(
        request.requestUuid
      );
      headers = { ...headers, ...offlineHeaders };
    }
    const requestOptions: HttpOptions = {
      url: this.#getUrl(url),
      method: "PUT",
      headers: this.getHeaders(headers),
      data,
    };
    return this.sendAxiosRequest(requestOptions);
  }

  async patch(
    url: string,
    data?: any,
    request?: { requestUuid: string }
  ): Promise<any> {
    console.log("PATH Request for", url);
    if (!this.#online) {
      if (!request) {
        return this.#addOffline(RequestMethod.Patch, url, data);
      } else {
        // The request is coming from FabriqIdbStorage
        throw new OfflineException();
      }
    }

    await this.#checkTokenAndStart();
    const offlineHeaders = request?.requestUuid
      ? this.#getOfflineMobileRequestHeader(request.requestUuid)
      : {};
    const requestOptions: HttpOptions = {
      url: this.#getUrl(url),
      method: "PATCH",
      headers: this.getHeaders(offlineHeaders),
      data,
    };
    return this.sendAxiosRequest(requestOptions);
  }

  async delete(
    url: string,
    params?: any,
    data?: any,
    request?: { requestUuid: string }
  ): Promise<any> {
    console.log("DELETE Request for", url);
    if (!this.#online) {
      if (!request) {
        return this.#addOffline(RequestMethod.Delete, url, params);
      } else {
        // The request is coming from FabriqIdbStorage
        throw new OfflineException();
      }
    }

    await this.#checkTokenAndStart();
    const offlineHeaders = request?.requestUuid
      ? this.#getOfflineMobileRequestHeader(request.requestUuid)
      : {};
    const requestOptions: HttpOptions = {
      url: this.#getUrl(url),
      method: "DELETE",
      headers: this.getHeaders(offlineHeaders),
      params,
      data,
    };
    return this.sendAxiosRequest(requestOptions);
  }

  async reconnectAttempt(): Promise<any> {
    if (this.#attempt > ApiFactory.#MAX_ATTEMPT) {
      this.#fabriqStore.$reset();
      return Promise.reject("Disconnected");
    }
    if (!this.#reconnectPromise) {
      this.#attempt++;
      this.#reconnectPromise = AuthClient.reLogin()
        .then((token) => {
          this.#fabriqStore.setToken(token);
          this.setToken(token);
        })
        .catch((e) => {
          console.error("RECONNECT ARRRF", e);
        })
        .finally(() => {
          this.#reconnectPromise = null;
        });
    }
    return this.#reconnectPromise;
  }

  async sendRequest(requestOptions: HttpOptions): Promise<any> {
    return Promise.resolve(requestOptions);
  }

  async sendAxiosRequest(requestOptions: any): Promise<any> {
    console.log("SEND Axios REQUEST Request for", requestOptions.url);
    const fabriqStore = this.getFabriqStore();
    fabriqStore.startRequest();
    const identifier = uuidv4();
    try {
      const controller = new AbortController();
      this.#processingRequests.set(identifier, controller);
      const response: any = await axios({
        ...requestOptions,
        signal: controller.signal,
      });
      this.#processingRequests.delete(identifier);
      this.resetAttempts();
      fabriqStore.endRequest();
      return response.data;
    } catch (e: any) {
      console.error("Error in sendAxiosRequest", e);
      fabriqStore.endRequest();
      this.#processingRequests.delete(identifier);
      if (e && e.response && e.response.status === 401) {
        return this.refreshToken()
          .then(() => {
            this.resetAttempts();
            return this.sendAxiosRequest({
              ...requestOptions,
              headers: this.getHeaders(requestOptions.headers),
            });
          })
          .catch((e) => {
            console.warn(e);
            return this.reconnectAttempt()
              .then(() => {
                this.resetAttempts();
                return this.sendAxiosRequest({
                  ...requestOptions,
                  headers: this.getHeaders(requestOptions.headers),
                });
              })
              .catch((e) => {
                console.error("Error", e);
                fabriqStore.logout();
              });
          });
      }
      if (
        e?.response?.status === 500 ||
        e?.response?.status === 403 ||
        e?.response?.status === 409
      )
        throw new Error("500", {
          cause: e,
        });

      throw e;
    }
  }
}
