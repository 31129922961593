export interface FollowedEntities {
  tickets: Array<number>;
  indicators: Array<number>;
  routines: Array<number>;
}

export interface Avatar {
  url?: string;
}

export interface Profile {
  position: string | null;
  avatar: Avatar;
  employer: number;
  full_name: string;
}

export interface RecentSearch {
  count: number;
  createdAt: string;
  query: string;
}

export enum OpenedEntitiesTypes {
  Ticket = "issue",
  Indicator = "indicator",
  Routine = "routine",
  Announcement = "communication",
}
export interface OpenedEntity {
  count: number;
  lastOpenedAt: string;
  ticketId?: number;
  routineId?: number;
  indicatorId?: number;
  objectID?: string;
  title: string;
  timestamp: number;
  type: OpenedEntitiesTypes;
}

export interface UserConfig {
  theme?: string;
  hasDarkMode?: boolean;
  wasOnboarded?: boolean;
  hasPushNotifications?: boolean;
  removePushNotificationsOnAppFocus?: boolean;
  recentSearches?: Array<RecentSearch>;
  openedTickets?: Array<OpenedEntity>;
  openedAnnouncements?: Array<OpenedEntity>;
  openedRoutines?: Array<OpenedEntity>;
  openedIndicators?: Array<OpenedEntity>;
  openedDocuments?: Array<OpenedEntity>;
  favoriteTemplates?: Array<number>;
  favoriteRoutines?: Array<number>;
  favoriteEventTypes?: Array<string>;
  favoriteKpis?: Array<number>;
}

export interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  intercom_user_hash?: string;
  future_user_id?: string;
  future_organization_id?: string;
  favorite_teams?: Array<any>;
  calendar_url?: string;
  config: UserConfig;
  followed_entities?: FollowedEntities;
  in_app_notifications_whitelist?: Array<string>;
  language: string;
  profile: Profile;
  avatar?: Avatar;
}
