import { toastController } from "@ionic/vue";

export const useCallRefreshToastSuccess = (theme?: string) => {
  return async (message: string) => {
    const toast = await toastController.create({
      message,
      duration: 3000,
      cssClass:
        theme === "light"
          ? "toast-refreshed-content-light"
          : "toast-refreshed-content",
    });
    toast.present();
  };
};

export const useCallRefreshToastError = () => {
  return async (message: string) => {
    const toast = await toastController.create({
      message,
      duration: 2000,
      color: "danger",
    });
    toast.present();
  };
};
