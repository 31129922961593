<script lang="ts" setup>
import { toastController } from "@ionic/vue";
import {
  Camera,
  CameraResultType,
  CameraPluginPermissions,
} from "@capacitor/camera";
import { Photo, GalleryPhoto, CameraSource } from "@capacitor/camera";
import { ref } from "vue";
import {
  IonContent,
  IonButtons,
  IonInput,
  IonTitle,
  IonButton,
  IonHeader,
  IonToolbar,
  IonRow,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { ExecutionAnswer, MediaType } from "@/types";
import { globalI18n } from "@/main";
import { v4 } from "uuid";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { localLogger } from "@/utils/localLogger";

const { t } = useI18n();

const url = ref("");
const isLinkUrlValid = ref(true);

const linkUrlReg =
  /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;

interface Props {
  answer?: ExecutionAnswer;
  mixpanelStr: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (
    event: "done",
    payload:
      | {
          media_type: MediaType.File;
          name?: string;
          _file: {
            url: string;
            path: string;
          };
        }
      | {
          media_type: MediaType.Url;
          url: string;
        }
  ): void;
  (event: "cancel"): void;
}>();

async function checkPermission(type: "camera" | "pictures") {
  const permission = await Camera.checkPermissions();
  if (permission.camera !== "granted" || permission.photos !== "granted") {
    const types: CameraPluginPermissions = {
      permissions: ["camera", "photos"],
    };
    const permissions = await Camera.requestPermissions(types);
    if (
      (type === "camera" && permissions.camera === "denied") ||
      (type === "pictures" && permissions.photos === "denied")
    ) {
      const toast = await toastController.create({
        message: globalI18n.t(
          `common.permission.${
            type === "camera" ? "cameraDenied" : "picturesDenied"
          }`
        ),
        duration: 2000,
        color: "danger",
      });
      toast.present();
    }
  }
}

const takePicture = async () => {
  try {
    await checkPermission("camera");
    const file = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
    });
    emitFile(file);
    mixpanelTracker.track(props.mixpanelStr, {
      action: "take_picture",
    });
  } catch (e) {
    localLogger.log(`[PIC ERROR]: ${e}`);
    console.error(e);
  }
};

const selectPictures = async () => {
  try {
    await checkPermission("pictures");
    const { photos } = await Camera.pickImages({
      quality: 90,
    });
    photos.forEach(async (photo) => emitFile(photo));
    mixpanelTracker.track(props.mixpanelStr, {
      action: "select_pictures",
      count: photos.length,
    });
  } catch (e) {
    localLogger.log(`[PIC ERROR]: ${e}`);
    console.error(e);
  }
};

const emitFile = (file: Photo | GalleryPhoto) => {
  const { webPath: url, path } = file;
  const name = file.path || v4();
  if (!url) return;
  if (!path) return;
  emit("done", {
    name: name.replace(/^.*\/([^\/]+)$/, "$1"),
    media_type: MediaType.File,
    _file: { url, path },
  });
};

const addUrl = () => {
  if (!linkUrlReg.test(url.value)) return emit("cancel");
  emit("done", { media_type: MediaType.Url, url: url.value });
};

const handleFileUrlInput = (e: InputEvent) => {
  const { value } = e.target as HTMLInputElement;
  url.value = value;
  if (value.length > 0) {
    isLinkUrlValid.value = linkUrlReg.test(url.value);
  }
};
</script>
<template>
  <ion-header mode="ios">
    <ion-toolbar class="transparent-toolbar edit-modal-toolbar">
      <ion-title>{{ t(`tickets.newFile`) }}</ion-title>
      <ion-buttons slot="end">
        <ion-button
          context="close"
          fill="clear"
          class="cancel-btn"
          @click="addUrl"
        >
          {{ t("common.done") }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="normal">
    <ion-row class="url-input-container ion-align-items-center">
      <ion-input
        class="filter-input"
        :class="{ 'filter-input-valid': isLinkUrlValid && url.length > 0 }"
        :autofocus="true"
        :placeholder="t(`tickets.placeholders.url`)"
        @input="handleFileUrlInput"
      />
    </ion-row>
    <ion-row
      v-if="!isLinkUrlValid"
      class="ion-align-items-center ion-justify-content-center"
    >
      <p class="url-input-error">
        {{ t(`tickets.placeholders.imgUrlError`) }}
      </p>
    </ion-row>
    <ion-row class="ion-align-items-center ion-justify-content-center">
      {{ t("common.or") }}
    </ion-row>
    <ion-row class="ion-justify-content-center file-button-container">
      <ion-button
        class="expanded add-file-button clickable"
        expand="block"
        @click="takePicture"
      >
        <font-icon name="photo_camera" material />
        {{ t("tickets.addPicture") }}
      </ion-button>
      <ion-button
        class="expanded add-file-button clickable"
        expand="block"
        @click="selectPictures"
      >
        <font-icon name="photo_library" material />
        {{ t("tickets.newFiles") }}
      </ion-button>
    </ion-row>
  </ion-content>
</template>

<style scoped>
.add-file-button {
  border: 1px solid var(--ion-border-color);
  border-radius: 5px;
  color: var(--ion-color-primary);
  --background-activated: none;
}

.add-file-button.expanded {
  --f-border-radius: 5px;
  width: calc(100% - var(--ion-padding) * 4);
}

.add-file-button .font-icon {
  margin-right: var(--ion-padding);
}

.filter-input {
  margin-left: var(--ion-padding);
  margin-right: var(--ion-padding);
}

.filter-input-valid {
  border-color: var(--ion-color-success);
}

.url-input-container {
  margin: var(--ion-padding);
}

.file-button-container {
  margin-top: calc((var(--ion-padding) * 2) + 6px);
  display: flex;
  gap: var(--ion-padding);
}

.file-button-container ::part(native) {
  background-color: transparent;
  --border-color: var(--ion-border-color);
}

.url-input-error {
  font-size: var(--font-size-xs);
  color: var(--ion-color-danger);
}
</style>
