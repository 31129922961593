<script lang="ts" setup>
import { computed, ref } from "vue";
import { IonContent, IonList, IonItem } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import ColoredChip from "@/components/ui/ColoredChip.vue";

import { useModalScroller } from "@/composables/useModalScroller";
import { useTeamsStore } from "@/store/teams";
import { Team } from "@/types";
import mixpanelTracker from "@/utils/mixpanel-tracker";

const { t } = useI18n();
const teamsStore = useTeamsStore();
const teamFilter = ref("");

const emit = defineEmits(["done", "cancel"]);
const props = withDefaults(
  defineProps<{
    teams: number[];
    entityName: "tickets" | "routines";
  }>(),
  {
    entityName: "routines",
  }
);

const teams = computed(() => {
  const filter = teamFilter.value.toLocaleLowerCase();
  const teams: Team[] = [];
  teamsStore.collection.forEach((t: Team) => {
    if (!props.teams.includes(t.id)) return;
    if (!t.name.toLocaleLowerCase().includes(filter)) return;
    teams.push(t);
  });
  teams.sort((a: Team, b: Team) => {
    return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
  });
  return teams;
});
useModalScroller();

function handleSelectTeam(teamId: number) {
  emit("done", teamId);
  mixpanelTracker.track(`select | team | team instance picker modal`, {
    teamId,
  });
}
</script>

<template>
  <ion-content class="normal">
    <div class="ios-header-padding-top" />
    <ion-list ref="list" lines="none" class="scroller">
      <ion-item>
        <div class="hint">{{ t(`${entityName}.team`) }}</div>
      </ion-item>
      <recycle-scroller class="scroller" :items="teams" :item-size="44">
        <template #default="{ item }">
          <ion-item @click="handleSelectTeam(item.id)">
            <colored-chip
              class="clickable"
              :label="item.name"
              :emoji="item?.config?.emoji"
              :icon="item?.config?.icon"
              :color="item?.config?.color"
              :material="true"
            />
          </ion-item>
        </template>
      </recycle-scroller>
    </ion-list>
  </ion-content>
</template>

<style scoped>
.hint {
  font-size: var(--font-size-m);
  margin: calc(var(--ion-padding) * 2) 0;
}
.add-modal-toolbar {
  height: 61px;
}
.modal-title-bar {
  padding: 0;
}
.list-title {
  text-transform: uppercase;
  background-color: var(--ion-background-color);
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  font-size: var(--font-size-xs);
  color: var(--ion-color-primary-shade);
}
.user-item {
  margin-bottom: var(--ion-padding);
}
.selected-values {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--ion-padding) / 2);
}
.field-header {
  width: 100%;
  padding: calc(var(--ion-padding) / 2);
  margin: calc(var(--ion-padding) / 2 * -1);
  font-size: var(--font-size-s);
  border-bottom: 1px solid var(--ion-border-color);
}
.search-icon {
  margin-left: calc(var(--ion-padding) / 2);
  margin-right: calc(var(--ion-padding) * -0.75);
}
ion-button.back-to-team {
  --icon-font-size: 14px;
  font-size: 14px;
  color: var(--color-default);
  margin-top: 6px;
}

ion-button.back-to-team ion-icon {
  font-size: 14px;
}

.indicator-item::part(native) {
  height: 66px;
  border-bottom: 1px solid var(--ion-border-color);
  --inner-border-width: 0;
}
</style>
