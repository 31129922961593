import { IndicatorFrequency } from "@/types/indicator";

export function mapIndicatorFrequencyToIndicatorDateFormat(
  frequency: IndicatorFrequency | "monthly_calendar" | undefined
): "date" | "weeknumber" | "month" | "year" {
  switch (frequency) {
    case "weekly":
      return "weeknumber";
    case "monthly":
    case "monthly_calendar":
      return "month";
    case "yearly":
      return "year";
    default:
      return "date";
  }
}
