import {
  EventPropertyFilter,
  EventTypeDateTimeProperty,
  FabriqEvent,
} from "@/types";
import { createListStore } from "./list";
import { EventsService } from "@/services/events";
import { format } from "date-fns";
import { isoDateToInputDate } from "@/utils/dates";
import { toastController } from "@ionic/vue";
import { globalI18n } from "@/main";
import { AxiosError } from "axios";

export const useEventsStore = createListStore(
  "events",
  EventsService,
  {},
  {
    eventsCount: 0,
  },
  {
    async retrieveEvents(
      eventTypeId: string | null,
      filters: EventPropertyFilter[],
      isComplete: boolean | undefined = undefined,
      page: number,
      asc: boolean,
      sortProperty: string | undefined,
      teamId: number | undefined,
      dateTimeEventPropertyTypesByEventType: Record<
        string,
        EventTypeDateTimeProperty
      >,
      flush = false
    ) {
      this.loading = true;
      const { events, count } = await EventsService.all!({
        eventTypeId,
        filters,
        isComplete,
        page,
        asc,
        sortProperty,
        teamId,
      });

      if (count !== undefined) {
        this.eventsCount = count;
      }

      const mappedEvents = events.map((e: FabriqEvent) => {
        e.properties = e.properties.map((p) => {
          if (
            dateTimeEventPropertyTypesByEventType[e.eventTypeId]?.id ===
            p.eventPropertyTypeId
          ) {
            const { startDate, startTime } = isoDateToInputDate(
              p.value as string
            );
            p.value = format(
              new Date(`${startDate}T${startTime}`),
              "yyyy-MM-dd HH:mm"
            );
          }
          return p;
        });
        return e;
      });
      this.merge(mappedEvents, flush);
      this.loading = false;
    },
  },
  true,
  undefined,
  {
    async onCreatedError(error, options) {
      await options.store.remove(options.entity, true);

      const data = ((error as Error)?.cause as AxiosError)?.response?.data as {
        reason?: string;
      };

      if (data?.reason === "eventTypeIsNotActive") {
        const toast = await toastController.create({
          message: globalI18n.t(
            "events.toast.errors.failedToCreateEventBecauseEventTypeIsNotActive"
          ),
          duration: 2000,
          color: "danger",
        });
        toast.present();
      } else {
        const toast = await toastController.create({
          message: globalI18n.t("events.toast.errors.failedToCreateEvent"),
          duration: 2000,
          color: "danger",
        });
        toast.present();
      }
    },
    async onDeletedError(_error, options) {
      const toast = await toastController.create({
        message: globalI18n.t("events.toast.errors.failedToDeleteEvent"),
        duration: 2000,
        color: "danger",
      });
      toast.present();
      options.store.merge([options.entity]);
    },
  }
);
