import {
  type DateTimeDayFormatter,
  buildDateTimeDayFormatter,
} from "./day_formatter";
import {
  type DateTimeMonthFormatter,
  buildDateTimeMonthFormatter,
} from "./month_formatter";
import {
  type DateTimeWeekFormatter,
  buildDateTimeWeekFormatter,
} from "./week_formatter";
import {
  type DateTimeYearFormatter,
  buildDateTimeYearFormatter,
} from "./year_formatter";
import type { I18n } from "@/composables/useI18n";

export interface DateTimeFormatter {
  year: DateTimeYearFormatter;
  month: DateTimeMonthFormatter;
  week: DateTimeWeekFormatter;
  day: DateTimeDayFormatter;
}

export type CalendarType = "medtronic" | "gregorian" | "mars";

export function buildDateTimeFormatter(
  calendarType: CalendarType,
  i18n: Pick<I18n, "t">,
  locale: string
): DateTimeFormatter {
  return {
    year: buildDateTimeYearFormatter(calendarType, i18n),
    month: buildDateTimeMonthFormatter(calendarType, i18n, locale),
    week: buildDateTimeWeekFormatter(calendarType, i18n, locale),
    day: buildDateTimeDayFormatter(calendarType, i18n, locale),
  };
}
