import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import createI18n from "@/i18n";
import { App as CapacitorApp } from "@capacitor/app";
import { Network } from "@capacitor/network";
import { IonicVue } from "@ionic/vue";
import { createPinia } from "pinia";
import VueVirtualScroller from "vue-virtual-scroller";

import VueViewer from "v-viewer";

//@ts-ignore
import InstantSearch from "vue-instantsearch/vue3/es";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

import "viewerjs/dist/viewer.css";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import "./theme/circular.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/display.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";

import "v-calendar/dist/style.css";

import "@mdi/font/css/materialdesignicons.min.css";

/* Theme variables */
import "./theme/app.css";
import "./theme/fa-brands.css";
import "./theme/fa-regular.css";
import "./theme/fa-solid.css";
import "./theme/field.css";
import "./theme/fontawesome.css";
import "./theme/material-icons-outlined.css";
import "./theme/material-icons.css";
import "./theme/modals.css";
import "./theme/routines.css";
import "./theme/shadows.css";
import "./theme/variables.css";
import "./theme/alert.css";
import "./theme/blocknote.css";

import IndicatorChip from "@/components/business/indicators/IndicatorChip.vue";
import CategoryChip from "@/components/business/tickets/CategoryChip.vue";
import UserAvatarInput from "@/components/business/UserAvatarInput.vue";
import ColoredChip from "@/components/ui/ColoredChip.vue";
import FontIcon from "@/components/ui/FontIcon.vue";
import VCalendar from "v-calendar";
import { ActionType, RecordCategory } from "./classes/PerformanceTracker";
import { UserService } from "./services/user";
import { FUTURE_SUBDOMAIN_KEY, useFabriqStore } from "./store/fabriq";
import { useClientStore } from "./store/client";
import loader from "./utils/loader";
import { log } from "./utils/notifications";
import storage from "./utils/storage";
import tracker from "./utils/tracker";
import config from "./config";
import { localLogger } from "./utils/localLogger";
import { reload } from "./utils/live-updates";

const pinia = createPinia();

// FIXME: Why do we have global components? If not useful, remove them.
const app = createApp(App)
  .component("font-icon", FontIcon)
  .component("user-avatar-input", UserAvatarInput)
  .component("indicator-chip", IndicatorChip)
  .component("category-chip", CategoryChip)
  .component("colored-chip", ColoredChip)

  .use(VCalendar, {})
  .use(VueVirtualScroller)
  .use(IonicVue, { swipeBackEnabled: false })
  .use(VueViewer)
  .use(InstantSearch)
  .use(pinia);

const i18n = createI18n();
export const globalI18n = i18n.global;

app.use(i18n);

app.config.performance = true;
let reloadPromise: Promise<void> | undefined;
if (!localStorage.getItem("appOpening")) {
  reloadPromise = reload();
}
const appInitialization = async (reloadPromise: Promise<void> | undefined) => {
  const store = useFabriqStore();
  store.buildSentry(app);
  app.use(router);
  if (reloadPromise) {
    localStorage.setItem("appOpening", new Date().toISOString().split("T")[1]);
    await reloadPromise;
  } else {
    localStorage.removeItem("appOpening");
  }
  await router.isReady();
  await storage.createDb();
  const status = await Network.getStatus();

  tracker.setFabriqStore(store);
  tracker.setNetwork(status);
  tracker.setIndexedDB(storage.getDb());
  localLogger.setIndexedDB(storage.getDb());
  localLogger.setNetwork(status);

  tracker.begin(
    "init",
    "Application initialization",
    RecordCategory.General,
    ActionType.Process
  );

  store.setOnline(status.connected);
  app.mount("#app");

  try {
    const theme = localStorage.getItem("fabriq-theme");
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      store.setColorScheme("dark");
    } else {
      store.setColorScheme("light");
    }
    store.setTheme(theme || undefined);
  } catch (e) {
    console.error("Get User Theme error", e);
  }
  try {
    const locale = await storage.get("fabriq-locale");
    if (locale) {
      store.setLocale(locale);
    } else if (navigator.language) {
      store.setLocale(navigator.language.substring(0, 2));
    }
  } catch (e) {
    console.error("Get User Theme error", e);
  }
  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (event) => {
      store.setColorScheme(event.matches ? "dark" : "light");
      if (!store.theme) store.setTheme(undefined);
    });

  if (config.env === "local") {
    window.setConfig = async ({ authToken, subdomain }: any) => {
      if (authToken || subdomain) {
        await loader.show();
      }
      if (authToken) {
        const handToken = {
          accessToken: authToken,
          expiresAt: new Date("2030-01-01"),
          refreshToken: "",
          type: "EXTERNAL",
        };
        await storage.clear();
        await store.setToken(handToken);
        const clientStore = useClientStore();
        const [user, client] = await Promise.all([
          UserService.me(),
          UserService.getClient(),
        ]);
        await storage.writeToStorage("user", user);
        await storage.writeToStorage("client", client);
        store.setInitializing(true);
        store.setCredentials(user.email, "fake", "EXTERNAL");
        clientStore.setClient(client);
        store.setUser(user);
      }
      if (subdomain) {
        await storage.set(FUTURE_SUBDOMAIN_KEY, subdomain);
      }
      if (authToken || subdomain) {
        setTimeout(() => window.location.reload(), 1000);
      }
    };
  }

  window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
    try {
      log({ errorMsg, url, lineNumber });
    } catch (e) {
      console.error(e);
    }
    return false;
  };

  CapacitorApp.addListener("appStateChange", async ({ isActive }) => {
    await tracker.track(
      "state",
      `Application is ${isActive ? "active" : "inactive"}`,
      RecordCategory.General
    );
    await localLogger.log(`Application is ${isActive ? "active" : "inactive"}`);
    if (isActive) return;
    await tracker.save();
  });

  tracker.next("init", ActionType.Ui, "begin ui creation");
};

appInitialization(reloadPromise);
