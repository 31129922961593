import { UserMobileStorageEntity } from "@/types/debugging";
import storage from "@/utils/storage";
export async function userMobileDataFromStore(
  entities: UserMobileStorageEntity[]
) {
  const dataToSend: { _entity: UserMobileStorageEntity; data: any }[] = [];
  for (const entity of entities) {
    const data = await storage.getAll(entity, null);
    dataToSend.push({
      _entity: entity,
      data,
    });
  }

  return dataToSend;
}
