import {
  gregorianCalendar,
  marsCalendar,
  medtronicCalendar,
} from "@fabriq/date_time";
import { useClientIndicatorCalendarType } from "./useClientIndicatorCalendarType";

export function useIndicatorCalendar() {
  const calendarType = useClientIndicatorCalendarType();

  switch (calendarType) {
    case "medtronic":
      return medtronicCalendar;
    case "mars":
      return marsCalendar;
    case "gregorian":
    default:
      return gregorianCalendar;
  }
}
