<script lang="ts" setup>
import { computed, ref, nextTick } from "vue";
import {
  useIonRouter,
  IonButtons,
  IonButton,
  onIonViewDidEnter,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { useAutofocus } from "@/composables/useAutofocus";
import { storeToRefs } from "pinia";
import DebounceInput from "@/components/tools/DebounceInput.vue";
import TicketHint from "@/components/business/tickets/TicketHint.vue";
import DocumentHint from "@/components/business/documents/DocumentHint.vue";
import { ticketPageAnimation } from "@/utils/animations";
import AccordionItem from "@/components/ui/AccordionItem.vue";
import LoadingBlock from "@/components/ui/LoadingBlock.vue";
import { useTicketsStore } from "@/store/tickets";
import { SearchClient } from "@/services/clients";
import { OpenedEntity } from "@/types";
import ModalPage from "@/components/ui/ModalPage.vue";
import loader from "@/utils/loader";
import { Keyboard } from "@capacitor/keyboard";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { sortOpenedEntities } from "@/utils/opened-entities";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { useConfigStore } from "@/store/config";
import { useClientStore } from "@/store/client";

const tunnel = (action: string) => {
  tracker.tunnel(
    "search",
    "search page",
    RecordCategory.Search,
    ActionType.Process,
    action
  );
};

const configStore = useConfigStore();
const ticketsStore = useTicketsStore();
const clientStore = useClientStore();
const router = useIonRouter();
const { config } = storeToRefs(configStore);
const { t } = useI18n();
const search = ref("");
const searching = ref(false);
const nbHits = ref(0);

let fromRecent = false;
const launchSearch = (query: string) => {
  if (keyboardIsOpen) return Keyboard.hide();
  fromRecent = true;
  search.value = query;
};

const processing = ref(false);

const openRecentSearch = async (id: number) => {
  processing.value = true;
  tunnel("recent");
  mixpanelTracker.track("open | recently opened | search screen");
  loadAndGotoTicket(id);
};

const openSearchedTicket = async (item: any) => {
  processing.value = true;
  if (!item?.objectID) return loader.hide();
  const ticketId = +item.objectID;
  tunnel("searched");
  mixpanelTracker.track("open | search opened | search screen");
  loadAndGotoTicket(ticketId);
};

const openSearchedDocument = (document: any) => {
  processing.value = true;
  router.navigate(
    `/documents/${document.objectID}`,
    "forward",
    "push",
    ticketPageAnimation
  );
  processing.value = false;
  loader.hide();
};

const loadAndGotoTicket = async (id: number) => {
  let exists = ticketsStore.collection.find((t: any) => t.id === id);
  if (!exists) {
    await loader.show();
    try {
      exists = await ticketsStore.load(id, true);
    } catch (e) {}
    loader.hide();
    processing.value = false;
    if (!exists) return;
  }
  loader.hide();
  gotoTicket(exists.uuid);
};

const gotoTicket = (uuid: string) => {
  if (keyboardIsOpen) Keyboard.hide();
  if (!uuid) return;
  router.navigate(`/tickets/${uuid}`, "forward", "push", ticketPageAnimation);
  processing.value = false;
};

let keyboardIsOpen = false;
onIonViewDidEnter(() => {
  Keyboard.addListener("keyboardWillShow", () => (keyboardIsOpen = true));
  Keyboard.addListener("keyboardWillHide", () => (keyboardIsOpen = false));
  useAutofocus(".debounce-search-input input", 200);
});

const hasKnowledge = computed(() => clientStore.config.hasKnowledge ?? false);

const lastTicketsAndDocuments = computed(() => {
  const noTitle = t("common.noTitle");
  const tickets = (config.value?.openedTickets || []).map((ti: any) => ({
    ...ti,
    title: !ti.title?.length ? noTitle : ti.title,
  }));

  const documents = hasKnowledge.value
    ? (config.value?.openedDocuments || []).map((doc: any) => ({
        ...doc,
        title: !doc.title?.length ? noTitle : doc.title,
      }))
    : [];
  const ticketsAndDocuments = [
    ...tickets.map((t) => ({
      ...t,
      type: "ticket",
      key: `ticket-${t.ticketId}`,
    })),
    ...documents.map((d) => ({
      ...d,
      type: "document",
      key: `document-${d.objectID}`,
    })),
  ];
  ticketsAndDocuments.sort(sortOpenedEntities);

  const lastTicketsAndDocuments: any = [];
  ticketsAndDocuments.slice(0, 5).forEach((o: OpenedEntity) => {
    lastTicketsAndDocuments.push(o);
  });
  return lastTicketsAndDocuments.slice(0, 5);
});

const recentSearches = computed(() => {
  const recentSearches = config.value?.recentSearches || [];
  recentSearches.sort((a: any, b: any) => {
    if (a.count === b.count) return b.createdAt.localeCompare(a.createdAt);
    return b.count - a.count;
  });
  return recentSearches.slice(0, 5);
});

const noResults = {
  results: [
    {
      hits: [],
      nbHits: 0,
      page: 0,
      nbPages: 0,
      hitsPerPage: 20,
      exhaustiveNbHits: true,
      processingTimeMS: 2,
    },
  ],
};

const searchClient = {
  search: async (requests: any) => {
    if (!search.value?.length) return noResults;
    if (fromRecent) {
      tunnel("algolia from recent");
      mixpanelTracker.track("type | recent searches | search screen");
    } else {
      tunnel("algolia search");
      mixpanelTracker.track("type | search bar | search screen");
    }
    fromRecent = false;
    searching.value = true;
    try {
      // FIXME: This modifies the requests so that Algolia only returns
      // tickets that are actually tickets, and not communications.
      // This is because the mobile app doesn't support opening
      // communications yet (when you click on it, it shows as if it was
      // a ticket). A proper fix would be to support opening a communication
      // and removing the modification of the Algolia requests.
      // Related fabriq ticket: https://app.fabriq.tech/?ticket=226386

      const requestsKeepingOnlyRealTickets = requests.map((request: any) => {
        const filters =
          request.indexName === "Ticket_" ? { filters: "type:issue" } : {};
        return {
          ...request,
          params: {
            ...request.params,
            ...filters,
          },
        };
      });
      const response = await SearchClient.post(
        "",
        { requests: requestsKeepingOnlyRealTickets },
        true
      );
      configStore.saveRecentSearch(search.value.trim().toLocaleLowerCase());
      searching.value = false;
      let nb = 0;
      const results = response.results.map((r: any) => {
        nb += r.nbHits;
        const hits = (r.hits || []).map((t: any) => ({
          ...t,
          owner: t.ticket_owner_id,
        }));
        return {
          ...r,
          hits,
        };
      });
      nbHits.value = nb;
      return {
        results,
      };
    } catch (e) {
      console.error(e);
    }
    searching.value = false;
    return noResults;
  },
};

const input = ref();
const clearSearch = () => {
  search.value = "";
  nextTick(() => {
    const htmlInput = input.value.$el.querySelector("input");
    if (!htmlInput) return;
    htmlInput.focus();
  });
};

const closeSearch = () => {
  mixpanelTracker.track("close | search | search screen");
  router.back();
};
</script>

<template>
  <modal-page>
    <template v-slot:toolbar>
      <div class="modal-title-bar">
        <font-icon
          name="search"
          material
          size="1"
          class="search-icon"
          color="var(--ion-color-primary-shade)"
        />
        <debounce-input
          class="debounce-search-input"
          :placeholder="t(`searchVerb`)"
          enterkeyhint="search"
          ref="input"
          clearable
          v-model="search"
          @keypress.enter="$event.target.blur()"
        />
        <font-icon
          name="cancel"
          v-if="search.length"
          @click="clearSearch"
          material
          outlined
          size="1"
          color="var(--ion-color-primary-shade)"
        />
        <ion-buttons slot="end">
          <ion-button
            context="close"
            fill="clear"
            class="modal-button"
            :disabled="processing"
            @click="closeSearch"
          >
            {{ search.length ? t("cancel") : t("close") }}
          </ion-button>
        </ion-buttons>
      </div>
    </template>
    <template v-slot:content>
      <ais-instant-search
        class="ion-content-scroll-host"
        index-name="Ticket_"
        :stalled-search-delay="500"
        :search-client="searchClient"
      >
        <ais-configure
          :hits-per-page.camel="hasKnowledge ? 5 : 10"
          :min-word-sizefor-1-typo.camel="4"
        ></ais-configure>
        <ais-search-box
          style="display: none"
          :show-loading-indicator="true"
          :placeholder="t(`common.search`)"
          v-model="search"
        >
        </ais-search-box>

        <ais-infinite-hits v-show="nbHits && search.length">
          <template v-slot:item="{ item }">
            <ticket-hint :ticket="item" @select="openSearchedTicket(item)" />
          </template>
        </ais-infinite-hits>

        <ais-index v-if="hasKnowledge" indexName="Documents_">
          <ais-infinite-hits v-show="nbHits && search.length">
            <template v-slot:item="{ item }">
              <document-hint
                :document="item"
                @select="openSearchedDocument(item)"
              />
            </template>
          </ais-infinite-hits>
        </ais-index>

        <accordion-item
          class="search-accordion ion-margin-top"
          :label="t('tickets.recent')"
          v-if="!searching && !search.length"
        >
          <template
            v-for="ticketOrDocument of lastTicketsAndDocuments"
            :key="ticketOrDocument.key"
          >
            <ticket-hint
              v-if="ticketOrDocument.type === 'ticket'"
              :ticket="ticketOrDocument"
              @select="openRecentSearch(ticketOrDocument.ticketId)"
            />
            <document-hint
              v-else
              :document="ticketOrDocument"
              @select="openSearchedDocument(ticketOrDocument)"
            />
          </template>
        </accordion-item>
        <accordion-item
          class="search-accordion ion-margin-top"
          :label="t('navbar.recentSearches')"
          v-if="!searching && !search.length"
        >
          <div
            class="recent-search clickable"
            v-for="item of recentSearches"
            :key="item.query"
            @click="launchSearch(item.query)"
          >
            <font-icon
              name="history"
              material
              outlined
              color="var(--f-color-discrete)"
            />
            <div class="recent-search-label">{{ item.query }}</div>
          </div>
        </accordion-item>
        <loading-block v-if="searching" class="loader" />
        <div
          v-if="!searching && search.length && !nbHits"
          class="routines-empty"
        >
          {{ t("navbar.noResults") }}
        </div>
      </ais-instant-search>
    </template>
  </modal-page>
</template>

<style scoped>
.search-icon {
  margin-left: calc(var(--ion-padding) / 2);
  margin-right: calc(var(--ion-padding) * -0.75);
}
.routines-empty.loader {
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}

.recent-search {
  padding: var(--f-padding-m);
  font-size: var(--font-size-m);
  background-color: var(--ion-background-color);
  border-bottom: 1px solid var(--ion-border-color);
  display: flex;
  flex-direction: row;
  gap: var(--ion-padding);
  align-items: center;
}

.recent-search:first-child {
  border-top: 1px solid var(--ion-border-color);
}
</style>
<style>
.search-accordion .accordion-header {
  padding-left: var(--f-padding-m);
}

.ais-InstantSearch {
  height: calc(100% - var(--ion-safe-area-bottom));
  overflow-y: scroll;
  position: relative;
}

.keyboard .ais-InstantSearch {
  height: 100%;
}

.ais-SearchBox {
  flex: 1;
}

.ais-InfiniteHits-loadMore {
  display: block;
  padding: var(--ion-padding);
  margin: var(--ion-padding) auto;
  background-color: var(--ion-color-primary-contrast);
  border-radius: var(--f-border-radius);
  border: 1px solid var(--ion-border-color);
}

.ais-InfiniteHits-list {
  padding: 0;
  margin: 0;
}

.ais-InfiniteHits-item::marker {
  content: "";
  width: 0;
}

.ais-InfiniteHits-loadMore--disabled {
  display: none;
}
</style>
