import { type Field } from "@/types";

export function getZoneIdsForTeams(
  teamIds: number[],
  zones: Field[]
): number[] {
  const zoneMap = new Map<number, Field>();
  zones.forEach((zone) => zoneMap.set(zone.id, zone));

  const resultZones = new Map<number, Field>();

  function findChildren(parentId: number) {
    for (const zone of zones) {
      if (zone.parent === parentId && !resultZones.has(zone.id)) {
        resultZones.set(zone.id, zone);
        findChildren(zone.id); // Recursive call to find children
      }
    }
  }

  // Find initial zones matching the given team IDs
  const initialZones = zones.filter((zone) =>
    zone.teams.some((team) => teamIds.includes(team))
  );

  // Add initial zones and find their children
  for (const zone of initialZones) {
    resultZones.set(zone.id, zone);
    findChildren(zone.id);
  }

  return Array.from(resultZones.values()).map((zone) => zone.id);
}
