import {
  type DateTime,
  gregorianCalendar,
  marsCalendar,
  medtronicCalendar,
} from "@fabriq/date_time";
import type { CalendarType } from "./formatter.js";
import type { I18n } from "@/composables/useI18n.js";
import { format as formatDate } from "date-fns";
import { getDateFnsLocales } from "@/i18n/index.js";

export interface DateTimeDayFormatter {
  /**
   * @example
   * Gregorian: "Feb 1"
   * Medtronic: "Feb 1"
   * Mars: "Feb 1"
   */
  dayOfShortMonth: (dateTime: DateTime) => string;
}
export function buildDateTimeDayFormatter(
  calendarType: CalendarType,
  i18n: Pick<I18n, "t">,
  locale: string
): DateTimeDayFormatter {
  return {
    dayOfShortMonth: {
      medtronic: medtronicDayOfShortMonth,
      gregorian: gregorianDayOfShortMonth,
      mars: marsDayOfShortMonth,
    }[calendarType],
  };
  function gregorianDayOfShortMonth(dateTime: DateTime) {
    const date = gregorianCalendar.toDate(dateTime);
    return formatDate(date, i18n.t("formats.dayOfMonth"), {
      locale: getDateFnsLocales(locale),
    });
  }
  function medtronicDayOfShortMonth(dateTime: DateTime) {
    const date = medtronicCalendar.toDate(dateTime);
    return formatDate(date, i18n.t("formats.dayOfMonth"), {
      locale: getDateFnsLocales(locale),
    });
  }
  function marsDayOfShortMonth(dateTime: DateTime) {
    const date = marsCalendar.toDate(dateTime);
    return formatDate(date, i18n.t("formats.dayOfMonth"), {
      locale: getDateFnsLocales(locale),
    });
  }
}
