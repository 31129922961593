import { ApiClient, PushNotificationsClient } from "./clients";
import { User, Client, UserConfig } from "@/types";
import config from "@/config";
import { UserMobileStorageEntity } from "@/types/debugging";
import { userMobileDataFromStore } from "@/utils/debugging/userMobileDataFromStore";
import { localLogger } from "@/utils/localLogger";

export const UserService = {
  async me(): Promise<User> {
    const data = await ApiClient.get("/api/v1/users/me/");
    return {
      id: data.id,
      future_user_id: data.future_user_id,
      future_organization_id: data.future_organization_id,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      favorite_teams: data.favorite_teams,
      calendar_url: data.calendar_url,
      intercom_user_hash: data.intercom_user_hash,
      config: data.config,
      followed_entities: data.followed_entities,
      in_app_notifications_whitelist: data.in_app_notifications_whitelist,
      language: data.language,
      profile: data.profile,
    };
  },
  async getClient(): Promise<Client> {
    const client = await ApiClient.get(`/api/v1/user/current/client/`);
    return client;
  },
  async saveConfig(config: UserConfig): Promise<User> {
    const user = await ApiClient.put(`/api/v1/users/me/`, { config });
    return user;
  },
  async setPushNotificationToken(
    deviceId: any,
    device: any,
    user: any,
    teams: any,
    clientId: any,
    token: any,
    future: boolean
  ): Promise<void> {
    try {
      const fabriqToken = `Bearer${future ? "Future" : ""} ${token}`;
      const notificationsUrl =
        ApiClient.buildServiceUrl(config.futurePushNotificationsPath) ||
        config.notificationsUrl;

      const data = {
        deviceId,
        device,
      };

      const [newResult, oldResult] = await Promise.allSettled([
        PushNotificationsClient.post("/devices", data),
        fetch(`${notificationsUrl}/devices`, {
          method: "POST",
          body: JSON.stringify({
            ...data,
            user,
            teams,
            clientId,
            token: fabriqToken,
          }),
          redirect: "follow",
          mode: "no-cors",
        }),
      ]);
      if (newResult.status === "rejected") {
        localLogger.log(
          `ERROR setPushNotificationToken NEW SERVICE, ${newResult.reason}`
        );
      }
      if (oldResult.status === "rejected") {
        localLogger.log(
          `ERROR setPushNotificationToken OLD SERVICE, ${oldResult.reason}`
        );
      }
    } catch (e) {
      localLogger.log(`setPushNotificationToken, ${e}`);
    }
  },
  async removePushNotificationToken(deviceToken: any) {
    try {
      const notificationsUrl =
        ApiClient.buildServiceUrl(config.futurePushNotificationsPath) ||
        config.notificationsUrl;
      await Promise.allSettled([
        PushNotificationsClient.delete(`/devices/${deviceToken}`),
        fetch(`${notificationsUrl}/devices/${deviceToken}`, {
          method: "DELETE",
          mode: "no-cors",
        }),
      ]);
    } catch (e) {
      console.error("removePushNotificationToken", e);
    }
  },
  async getDiamondUserMapping(): Promise<
    {
      future_user_id: string;
      user_id: number;
    }[]
  > {
    const users = await ApiClient.get(
      `/api/v1/user/current/client/diamond-user-mapping/`
    );
    return users;
  },
  async sendUserMobileData(): Promise<void> {
    try {
      const data = (await ApiClient.get("/debugging/user-mobile-data/")) as
        | {
            id: number;
            entities: UserMobileStorageEntity[];
          }
        | string;
      if (!data || data === "" || typeof data === "string") {
        return;
      }
      const dataToSend = await userMobileDataFromStore(data.entities);
      await ApiClient.put("/debugging/user-mobile-data/export/", {
        id: data.id,
        data: dataToSend,
      });
    } catch (e) {
      await localLogger.log(`sendUserMobileData, ${e}`);
    }
  },
};
