import { useFabriqStore } from "@/store/fabriq";
import { Browser, OpenOptions } from "@capacitor/browser";
import { localLogger } from "./localLogger";
import { Capacitor } from "@capacitor/core";

export const urlOpener = async (url: string) => {
  const platform = Capacitor.getPlatform();
  const options: OpenOptions = {
    url,
  };
  const nb = +(localStorage.getItem("nb-login") || "0");
  if (nb > 3) {
    localStorage.setItem("nb-login", "0");
    const store = useFabriqStore();
    await store.logout();
    window.location.reload();
  } else {
    localStorage.setItem("nb-login", `${nb + 1}`);
  }
  Browser.addListener("browserFinished", async () => {
    await localLogger.log("Browser page finished");
  });
  Browser.addListener("browserPageLoaded", async () => {
    await localLogger.log(`Browser page loaded`);
  });
  try {
    if (platform === "ios") {
      await Browser.open(options);
    } else {
      window.open(url, "auth");
    }
  } catch (e) {
    const store = useFabriqStore();
    await store.logout();
    await localLogger.log(`Browser open error ${JSON.stringify(e)}`);
    console.error("ERROR OPEN BROWSER", e);
  }
};
