import { DateOption } from "@/types";
import { subBusinessDays } from "date-fns";
import { useIndicatorCalendar } from "./useIndicatorCalendar";
import type {
  DateTime,
  DateTimeCalendar,
  DateTimePrecision,
} from "@fabriq/date_time";

export const useConvertDateOption = () => {
  const indicatorCalendar = useIndicatorCalendar();
  return {
    convertDateOption: buildConvertDateOption(indicatorCalendar),
  };
};

export function buildConvertDateOption(indicatorCalendar: DateTimeCalendar) {
  function toIsoDateStringOfEndOf(
    dateTime: DateTime,
    interval: Extract<DateTimePrecision, "week" | "month" | "year">
  ) {
    const endDateTime = indicatorCalendar.endOfInterval(
      dateTime,
      "day",
      interval
    );
    return indicatorCalendar.toIsoDateString(endDateTime);
  }
  return function convertDateOption(
    date: DateOption,
    referenceDate: Date
  ): string {
    switch (date) {
      case DateOption.DayOfExecution: {
        const currentDay = indicatorCalendar.fromDate(referenceDate, "day");
        return indicatorCalendar.toIsoDateString(currentDay);
      }
      case DateOption.DayBeforeExecution: {
        const currentDay = indicatorCalendar.fromDate(referenceDate, "day");
        const previousDay = indicatorCalendar.add(currentDay, "day", -1);
        return indicatorCalendar.toIsoDateString(previousDay);
      }
      case DateOption.BusinessDayBeforeExecution: {
        const date = subBusinessDays(referenceDate, 1);
        const dateTime = indicatorCalendar.fromDate(date, "day");
        return indicatorCalendar.toIsoDateString(dateTime);
      }
      case DateOption.WeekOfExecution: {
        const currentWeek = indicatorCalendar.fromDate(referenceDate, "week");
        return toIsoDateStringOfEndOf(currentWeek, "week");
      }
      case DateOption.WeekBeforeExecution: {
        const currentWeek = indicatorCalendar.fromDate(referenceDate, "week");
        const previousWeek = indicatorCalendar.add(currentWeek, "week", -1);
        return toIsoDateStringOfEndOf(previousWeek, "week");
      }
      case DateOption.MonthOfExecution: {
        const currentMonth = indicatorCalendar.fromDate(referenceDate, "month");
        return toIsoDateStringOfEndOf(currentMonth, "month");
      }
      case DateOption.MonthBeforeExecution: {
        const currentMonth = indicatorCalendar.fromDate(referenceDate, "month");
        const previousMonth = indicatorCalendar.add(currentMonth, "month", -1);
        return toIsoDateStringOfEndOf(previousMonth, "month");
      }
      case DateOption.YearBeforeExecution: {
        const currentYear = indicatorCalendar.fromDate(referenceDate, "year");
        const previousYear = indicatorCalendar.add(currentYear, "year", -1);
        return toIsoDateStringOfEndOf(previousYear, "year");
      }
      case DateOption.YearOfExecution: {
        const currentYear = indicatorCalendar.fromDate(referenceDate, "year");
        return toIsoDateStringOfEndOf(currentYear, "year");
      }
    }
  };
}
