<template>
  <div>
    <div v-if="edit && !disabled" class="date-input-edition">
      <ion-datetime
        class="datetime-input"
        ref="datetime"
        :value="value"
        presentation="date"
        @ionChange="changeDate"
        :locale="locale"
      />
      <div class="date-input-buttons">
        <ion-button
          size="small"
          expand="block"
          fill="outline"
          @click="changeDate({ detail: { value: yesterday } })"
        >
          {{ t("datetime.yesterday") }}
        </ion-button>
        <ion-button
          size="small"
          expand="block"
          fill="outline"
          @click="changeDate({ detail: { value: today } })"
        >
          {{ t("datetime.today") }}
        </ion-button>
        <ion-button
          size="small"
          expand="block"
          fill="outline"
          @click="changeDate({ detail: { value: monday } })"
        >
          {{ t("datetime.monday") }}
        </ion-button>
      </div>
    </div>
    <div v-else class="date-input" @click="edit = true">
      <div v-if="value && value.length" :class="{ disabled }">
        {{ formatted }}
      </div>
      <div v-else class="placeholder">
        {{ t("routines.placeholders.date") }}
      </div>
      <font-icon
        name="date_range"
        material
        size="1"
        color="var(--ion-color-primary-shade)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "vue";
import { TemplateStep, ExecutionAnswer, IndicatorFrequency } from "@/types";
import { IonDatetime, IonButton } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { useFabriqStore } from "@/store/fabriq";
import { parseISO, format, addDays, subDays } from "date-fns";
import { getNextMonday } from "@/utils/dates";
import { getDateFormat } from "@/utils/indicators";
import { getDateFnsLocales } from "@/i18n";
import { useIndicatorCalendar } from "@/composables/useIndicatorCalendar";

// TODO be careful if we add hours in step date => we'll have to use date-fns

export default defineComponent({
  name: "StepDate",
  components: { IonDatetime, IonButton },
  emits: ["value"],
  props: {
    step: {
      type: Object as PropType<TemplateStep>,
      default: () => ({}),
    },
    answer: {
      type: Object as PropType<ExecutionAnswer>,
      default: () => ({}),
    },
    frequency: {
      type: String as PropType<IndicatorFrequency>,
      default: "daily",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const fabriqStore = useFabriqStore();
    const edit = ref(false);
    const now = new Date();
    const today = format(now, "yyyy-MM-dd");
    const yesterday = format(addDays(now, 1), "yyyy-MM-dd");
    const monday = getNextMonday();
    const { t } = useI18n();
    const indicatorCalendar = useIndicatorCalendar();

    const value = computed(() => {
      if (!props.answer.value) return "";
      return format(parseISO(props.answer.value), "yyyy-MM-dd");
    });

    const formatted = computed(() => {
      let date = new Date(value.value + "T00:00:00");
      if (props.frequency && props.frequency === "weekly") {
        date = subDays(date, 6);
      }
      const dateFormat = getDateFormat(props.frequency, t("formats.date"));

      return format(date, dateFormat, {
        locale: getDateFnsLocales(fabriqStore.locale),
      });
    });

    const changeDate = (ev: CustomEvent) => {
      let value = ev.detail.value;
      const date = new Date(value + "T00:00:00");
      switch (props.frequency) {
        case "weekly":
          value = indicatorCalendar.toIsoDateString(
            indicatorCalendar.endOfInterval(
              indicatorCalendar.fromDate(date, "week"),
              "day",
              "week"
            )
          );
          break;
        case "monthly":
          value = indicatorCalendar.toIsoDateString(
            indicatorCalendar.endOfInterval(
              indicatorCalendar.fromDate(date, "month"),
              "day",
              "month"
            )
          );
          break;
        default:
          break;
      }
      emit("value", value);
      edit.value = false;
    };
    return {
      edit,
      formatted,
      value,
      today,
      yesterday,
      monday,
      locale: fabriqStore.locale,
      changeDate,
      t,
    };
  },
});
</script>

<style scoped>
.disabled {
  color: var(--f-color-disabled);
}
</style>
