import { createListStore } from "./list";
import { TeamsService } from "@/services/teams";
import { Ticket } from "@/types/ticket";
import { Team } from "@/types/team";
import { useFabriqStore } from "@/store/fabriq";

export const useTeamsStore = createListStore(
  "teams",
  TeamsService,
  undefined,
  {
    async loadTeamsFromTeamSharing(ticket: Ticket) {
      const allTeamsIds = this.collection.map((team: Team) => team.id);
      const missingTeams = (ticket.teams || []).filter(
        (id) => !allTeamsIds.includes(id)
      );
      if (!TeamsService.load) return;
      const load = TeamsService.load;
      await Promise.allSettled(
        missingTeams.map((missingTeams) =>
          load(missingTeams).then((teams) => this.merge([teams]))
        )
      );
    },
  },
  undefined,
  true,
  {
    teamsForCurrentUser() {
      const fabriqStore = useFabriqStore();
      const userId = fabriqStore.user?.id;
      if (!userId) return [];
      return this.collection.filter((team: Team) =>
        team.users?.includes(userId)
      );
    },
  }
);
